import "./App.css";
import React, { useMemo, useState } from "react";
import Sidebar from "./components/sidebar";
import Navbar from "./components/navbar";
import Home from "./pages/home";
import Households from "./pages/households";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import useSWR from "swr";
import AppContext from "./context/appContext";
import Household from "./pages/household";
import Vcas from "./pages/vcas";
import Child from "./pages/child";
import Mothers from "./pages/mothers";
import Login from "./components/login";
import Districts from "./pages/districts";
import Mother from "./pages/mother";
import Details from "./components/details";
import Addcomment from "./pages/addcomment";
import Comments from "./pages/comments";
import UserManagement from "./pages/usermanagement";
import RestoreUser from "./pages/deletedUsers";
import AdvancedSearch from "./pages/advancedSearch";
import VcaServices from "./pages/allVCAServices";
import CaregiverServices from "./pages/allCaregiverServices";
import AllHServiceReports from "./components/householdForms/allHServiceReports";
import HouseholdArchivedRegister from "./pages/householdArchivedRegister";
import VcaArchivedRegister from "./pages/vcaArchivedRegister";

function App() {
  
  const token = localStorage.getItem("token");
  const [district, setDistrict] = useState(0);

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  const fetcher = (url) => axios.get(url, options).then((res) => res.data);
  const fetcher2 = (url2) => axios.get(url2, options).then((res2) => res2.data);
  const fetcher3 = (url3) => axios.get(url3, options).then((res3) => res3.data);

  const { data: householdData, error: householdError } = useSWR(
    `${process.env.REACT_APP_SERVER}/household/district/${district}`,
    fetcher
  );
  const { data: childrenData, error: childrenError } = useSWR(
    `${process.env.REACT_APP_SERVER}/child/district/${district}`,
    fetcher2
  );

  const { data: motherData, error: motherError } = useSWR(
    `${process.env.REACT_APP_SERVER}/mother/district/${district}/filtered`,
    fetcher3
  );

  return (
    <>
      <BrowserRouter>
        <AppContext.Provider
          value={{
            householdData: householdData,
            householdError: householdError,
            children: childrenData,
            childrenEror: childrenError,
            token: token,
            motherData: motherData,
            motherError: motherError,
            district: district,
            setDistrict: setDistrict,
            
          }}
        >
          {localStorage.getItem("token") === null ? (
            <Login />
          ) : (
            <React.Fragment>
              <Routes>
                <Route path="/districts" element={<Districts />} />
              </Routes>
              <Sidebar />
              <div className="main-panel">
                <Navbar />
                <div className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="/households" element={<Households />} />
                          <Route path="/mothers" element={<Mothers />} />
                          <Route path="/children" element={<Vcas />} />
                          <Route path="/users" element={<UserManagement />} />
                          <Route path="/deleted-users" element={<RestoreUser />} />
                          <Route path="/advanced-search" element={<AdvancedSearch />} />
                          <Route path="/vca-services" element={<VcaServices />} />
                          <Route path="/caregiver-services" element={<CaregiverServices />} />
                          <Route path="/all-hh-service-reports" element={<AllHServiceReports />} />
                          <Route path="/household-archived-register" element={<HouseholdArchivedRegister />} />
                          <Route path="/vca-archived-register" element={<VcaArchivedRegister />} />
                          
                          <Route
                            path="/addcomment"
                            element={<Addcomment />}
                          />
                          <Route
                            path="/household"
                            element={<Household />}
                          />
                          <Route path="/child/" element={<Child />} />
                          <Route path="/mother/:id/*" element={<Mother />} />
                          <Route
                            path="/details/:json/*"
                            element={<Details />}
                          />
                          <Route path="/comments" element={<Comments />} />
                        </Routes>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </AppContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;