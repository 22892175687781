// Fetch the list of household case plan domains by householdId
// A case plan can have multiple case plan domains hence
//for quality check we pass in the household id and the 
//case plan id to fetch the domains for a case plan

import React, { useEffect, useState, useMemo } from "react";
import { Chip } from "@mui/material";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Papa from "papaparse";
import "jspdf-autotable";
import {
  Table,
  Spin,
  Button,
  Tooltip,
  Breadcrumb,
  Modal,
  Alert,
} from "antd";
import {
  FileExcelFilled,
} from "@ant-design/icons";
import Marquee from "react-fast-marquee";
import VersionBanner from "../../pages/versionBanner";

const HouseholdCasePlans = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedHouseholdCasePlans, setfetchedHouseholdCasePlans] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [vulnerabilitiesData, setVulnerabilitiesData] = useState([]);

  const location = useLocation();
  const { house } = location?.state;

  const token = localStorage.getItem("token");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  //If a field on a record is empty, render
  // 'Not Provided' instead of an empty string
  const renderText = (text, dataIndex) => {
    if (text === null || text === undefined || (typeof text === 'string' && text.trim() === '')) {
      if (dataIndex === 'service_referred') {
        return 'Not Referred';
      } else if (dataIndex === 'institution') {
        return 'Not Specified';
      } else {
        return 'Not Provided';
      }
    } else {
      return text;
    }
  };

  // Columns for the household case plans table
  const columns = [
    {
      title: "Case Plan Date",
      dataIndex: "case_plan_date",
      key: "case_plan_date",
      width: 80,
      render: (text) => renderText(text, 'case_plan_date'),
    },
    {
      title: "Case Plan Status",
      dataIndex: "case_plan_status",
      key: "case_plan_status",
      width: 80,
      render: (text, record) => (
        <Alert banner message={text} />
      ),
    },
    {
      title: "Vulnerabilities",
      dataIndex: "vulnerability",
      key: "vulnerability",
      width: 80,
      render: (text, caseplan) => (
        <Button
          type="primary"
          className="btn btn-info"
          style={{
            padding: "0.1rem 1rem",
            textTransform: "none",
            borderRadius: "8px",
            fontWeight: "500"
          }}
          onClick={() => {
            showModal(caseplan.case_plan_id);
          }}
        >
          View Vulnerabilities
        </Button>
      ),
    },
  ];

  // Columns for the vulnerabilities table
  const vulnerabilitiesColumns = [
    {
      title: "Case Plan Date",
      dataIndex: "case_plan_date",
      key: "case_plan_date",
      width: 300,
      render: (text) => renderText(text, 'case_plan_date'),
    },
    {
      title: "Vulnerability Type",
      dataIndex: "vulnerability_type",
      key: "vulnerability_type",
      width: 300,
      render: (text) => renderText(text, 'vulnerability_type'),
    },
    {
      title: "Vulnerability",
      dataIndex: "vulnerability",
      key: "vulnerability",
      width: 300,
      render: (text) => renderText(text, 'vulnerability'),
    },
    {
      title: "Goal",
      dataIndex: "goal",
      key: "goal",
      width: 400,
      render: (text) => renderText(text, 'goal'),
    },
    {
      title: "Services",
      dataIndex: "services",
      key: "services",
      width: 300,
      render: (text) => renderText(text, 'services'),
    },
    {
      title: "Service Referred",
      dataIndex: "service_referred",
      key: "service_referred",
      width: 300,
      render: (text) => renderText(text, 'service_referred'),
    },
    {
      title: "Institution",
      dataIndex: "institution",
      key: "institution",
      width: 300,
      render: (text) => renderText(text, 'institution'),
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      width: 500,
      render: (text, record_) => {
        const currentDate = new Date();
        const dueDate = new Date(text);
        const isDue = dueDate <= currentDate;
        return (
          <Alert
            type={isDue ? "error" : "info"}
            style={isDue ? { borderColor: '#ff4d4f', borderWidth: '2px' } : {}}
            message={text}
          />
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 300,
      render: (text) => renderText(text, 'status'),
    },
    {
      title: "Vulnerability Comment",
      dataIndex: "vulnerability_comment",
      key: "vulnerability_comment",
      width: 300,
      render: (text) => renderText(text, 'vulnerability_comment'),
    },
  ];


  // Fetch the list of household case plans by householdId
  const fetchAllHouseholdCasePlans = () => {
    const household_id = house.household_id;
    setIsLoading(true);
    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_SERVER}/household/hhcaseplans/${household_id}`,
          options
        )
        .then((response) => {
          // Sort the case plans by case plan date in descending order
          const sortedCasePlans = response.data.sort((a, b) => {
            // Convert dates to a format that Javascript can understand
            const dateA = new Date(a.case_plan_date?.split('-').reverse().join('-'));
            const dateB = new Date(b.case_plan_date?.split('-').reverse().join('-'));
            return dateB - dateA;
          });
          setfetchedHouseholdCasePlans(sortedCasePlans);
          setIsDataReadyForExport(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("axios error: " + error);
          setIsLoading(false);
        });
    }, 3000);
  };

  useEffect(() => {
    fetchAllHouseholdCasePlans();
  }, [token, house.household_id]);

  const showModal = async () => {
    const household_id = house.household_id;

    try {
      const response = await axios
        .get(
          `${process.env.REACT_APP_SERVER}/household/vulnerabilities/${household_id}`,
          options
        )

      // Sort the vulnerabilities by date in descending order
      const sortedVulnerabilities = response.data.sort((a, b) => {
        // Convert dates to a format that Javascript can understand
        const dateA = new Date(a.case_plan_date?.split('-').reverse().join('-'));
        const dateB = new Date(b.case_plan_date?.split('-').reverse().join('-'));
        return dateB - dateA;
      });
      setVulnerabilitiesData(sortedVulnerabilities);
    } catch (error) {
      console.error("axios error: " + error);
    }

    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const displayFetchedHouseholdCasePlans = Array.isArray(fetchedHouseholdCasePlans)
    ? fetchedHouseholdCasePlans
      .map((caseplans) => {
        if (!caseplans) {
          return null;
        }
        const {
          household_id,
          case_plan_id,
          case_plan_date,
          case_plan_status,
          vulnerability_type,
          vulnerability,
          goal,
          services,
          service_referred,
          institution,
          due_date,
          status,
          vulnerability_comment,
        } = caseplans;

        return {
          household_id,
          case_plan_id,
          case_plan_date,
          case_plan_status,
          vulnerability_type,
          vulnerability,
          goal,
          services,
          service_referred,
          institution,
          due_date,
          status,
          vulnerability_comment,
        };
      })
      .filter((item) => item !== null)
    : [];


  //fetch household case plans/vunerabilities by household_id
  const displayFetchedHouseholdCasePlanDomains = Array.isArray(vulnerabilitiesData)
    ? vulnerabilitiesData
      .map((caseplans) => {
        if (!caseplans) {
          return null;
        }
        const {
          household_id,
          case_plan_date,
          case_plan_status,
          vulnerability_type,
          vulnerability,
          goal,
          services,
          service_referred,
          institution,
          due_date,
          status,
          vulnerability_comment,
        } = caseplans;

        return {
          household_id,
          case_plan_date,
          case_plan_status,
          vulnerability_type,
          vulnerability,
          goal,
          services,
          service_referred,
          institution,
          due_date,
          status,
          vulnerability_comment,
        };
      })
      .filter((item) => item !== null)
    : [];

  const navigate = useNavigate();

  const handleAdvancedSearchClick = () => {
    navigate("/advanced-search");
  };

  // Export to CSV
  const exportToCSV = () => {
    const headers = columns.map((column) => column.title);
    const csvData = [headers].concat(
      fetchedHouseholdCasePlans.map((house) => Object.values(house))
    );

    const csvContent = Papa.unparse(csvData);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    // Create the filename including household ID and caregiver name
    const fileName = `household_case_plans_data_${house.household_id}_${house.caregiver_name}.csv`;
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportToCSV = async () => {
    if (fetchedHouseholdCasePlans?.length === 0) {
      await fetchAllHouseholdCasePlans(currentPage);
    }
    exportToCSV();
  };

  return (
    <>
      <VersionBanner />
      <div className="tab-pane" id="caseplans">
        <React.Fragment>
          <div className="card-body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: windowWidth <= 991 ? "column" : "row",
                alignItems: "center",
              }}
            >
              {/*breadcrumb navigation*/}
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Chip
                    label="Household Case Plans"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      fontWeight: "500",
                      marginLeft: "1%",
                    }}
                  ></Chip>
                  &nbsp;&nbsp;&nbsp;
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <h1
                    style={{
                      fontSize: "17px",
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Navigate to:
                  </h1>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/households">
                    <Chip
                      label="Households"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/children">
                    <Chip
                      label="VCA`s"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/mothers">
                    <Chip
                      label="Mothers"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <Tooltip title="Export to CSV">
                    <Button
                      icon={<FileExcelFilled twoToneColor="#28A745" />}
                      disabled={!isDataReadyForExport}
                      size="large"
                      style={{
                        marginRight: 8,
                        background:
                          "linear-gradient(45deg, #28A745 30%, #FF8E53 90%)",
                        color: "white",
                        boxShadow: "0 3px 5px 2px rgba(40, 167, 69, 0.3)",
                      }}
                      onClick={handleExportToCSV}
                    />
                  </Tooltip>
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <Button
                    onClick={handleAdvancedSearchClick}
                    size="large"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      display: "block",
                      margin: "0 auto",
                      fontWeight: "500",
                    }}
                  >
                    Advanced Search
                  </Button>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div
                className="toolbar"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: windowWidth <= 991 ? "column" : "row",
                }}
              >
              </div>
            </div>
          </div>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "10vh",
              }}
            >
              <Spin size={35} color={"#808080"} loading={true} />
              <p>
                <b style={{ fontWeight: "500", textAlign: "center" }}>
                  Please wait while we process your request...
                </b>
              </p>
            </div>
          ) : (
            <Table
              dataSource={displayFetchedHouseholdCasePlans}
              columns={columns}
              scroll={{ y: 500 }}
              pagination={false}
              className="ant-table"
              locale={{
                emptyText: (
                  <b style={{ color: "black" }}>
                    There are currently no Household Case Plans saved for this household. Kindly revisit in the future...
                  </b>
                ),
              }}
            />
          )}

          <Modal
            scroll={{ y: 500 }}
            title={
              <Alert
                banner
                message={
                  <Marquee pauseOnHover gradient={false}>
                    Case Plan Vulnerability Information
                  </Marquee>
                }
              />
            }
            color="primary"
            open={isModalVisible}
            onCancel={handleCancel}
            width={1300}
            footer={null}
          >
            <Table
              dataSource={displayFetchedHouseholdCasePlanDomains}
              columns={vulnerabilitiesColumns}
              scroll={{ x: true }}
              pagination={false}
              className="ant-table"
              locale={{
                emptyText: (
                  <b style={{ color: "black" }}>
                    There are currently no Household Case Plan Vulnerabilities saved for this household. Kindly make a follow-up with the caseworker.
                  </b>
                ),
              }}
            />
          </Modal>
        </React.Fragment>
      </div>
    </>
  );
}

export default HouseholdCasePlans;
