import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Chip, debounce } from "@mui/material";
import "jspdf-autotable";
import {
  Table,
  Spin,
  Button,
  Tooltip,
  Breadcrumb,

} from "antd";
import {
  FileExcelFilled
} from "@ant-design/icons";
import VersionBanner from "../../pages/versionBanner";

const Vcavisits = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchVcaVisits, setfetchedVcaVisits] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);

  const location = useLocation();
  // const { uid } = location?.state;

  const { child } = location?.state;

  //Display active household_id and VCA full names on top of the page
  const { firstname = "", lastname = "" } = child || {};
  const uid = location?.state?.child?.uid || "";

  // const uid = location?.state?.child?.uid || "";

  const token = localStorage.getItem("token");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  //If a field on a record is empty, render
  // 'Not Provided' instead of empty string
  const renderText = (text, dataIndex) => {
    if (text === null || text === undefined || (typeof text === 'string' && text.trim() === '')) {
      if (dataIndex === 'service_referred') {
        return 'Not Referred';
      } else if (dataIndex === 'institution') {
        return 'Not Specified';
      } else {
        return 'Not Provided';
      }
    } else {
      return text;
    }
  };


  const columns = [
    {
      title: "Visit Date",
      dataIndex: "visit_date",
      key: "visit_date",
      width: 200,
      render: (text) => renderText(text, 'visit_date'),
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      width: 200,
      render: (text) => renderText(text, 'age'),
    },
    {
      title: "Is HIV Positive",
      dataIndex: "is_hiv_positive",
      key: "is_hiv_positive",
      width: 200,
      render: (text) => renderText(text, 'is_hiv_positive'),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 200,
      render: (text) => renderText(text, 'phone'),
    },
    {
      title: "Child ART",
      dataIndex: "child_art",
      key: "child_art",
      width: 200,
      render: (text) => renderText(text, 'child_art'),
    },
    {
      title: "Clinical Care",
      dataIndex: "clinical_care",
      key: "clinical_care",
      width: 200,
      render: (text) => renderText(text, 'clinical_care'),
    },
    {
      title: "ART Appointment",
      dataIndex: "art_appointment",
      key: "art_appointment",
      width: 200,
      render: (text) => renderText(text, 'art_appointment'),
    },
    {
      title: "Counselling",
      dataIndex: "counselling",
      key: "counselling",
      width: 200,
      render: (text) => renderText(text, 'counselling'),
    },
    {
      title: "ART Medication",
      dataIndex: "art_medication",
      key: "art_medication",
      width: 200,
      render: (text) => renderText(text, 'art_medication'),
    },
    {
      title: "Child MMD",
      dataIndex: "child_mmd",
      key: "child_mmd",
      width: 200,
      render: (text) => renderText(text, 'child_mmd'),
    },
    {
      title: "Level MMD",
      dataIndex: "level_mmd",
      key: "level_mmd",
      width: 200,
      render: (text) => renderText(text, 'level_mmd'),
    },
    {
      title: "Drug Regimen",
      dataIndex: "drug_regimen",
      key: "drug_regimen",
      width: 200,
      render: (text) => renderText(text, 'drug_regimen'),
    },
    {
      title: "Date ART",
      dataIndex: "date_art",
      key: "date_art",
      width: 200,
      render: (text) => renderText(text, 'date_art'),
    },
    {
      title: "Six Months",
      dataIndex: "six_months",
      key: "six_months",
      width: 200,
      render: (text) => renderText(text, 'six_months'),
    },
    {
      title: "VL Last Result",
      dataIndex: "vl_last_result",
      key: "vl_last_result",
      width: 200,
      render: (text) => renderText(text, 'vl_last_result'),
    },
    {
      title: "Date HIV",
      dataIndex: "date_hiv",
      key: "date_hiv",
      width: 200,
      render: (text) => renderText(text, 'date_hiv'),
    },
    {
      title: "Visit HIV Test",
      dataIndex: "visit_hiv_test",
      key: "visit_hiv_test",
      width: 200,
      render: (text) => renderText(text, 'visit_hiv_test'),
    },
    {
      title: "Referred for Testing",
      dataIndex: "referred_for_testing",
      key: "referred_for_testing",
      width: 200,
      render: (text) => renderText(text, 'referred_for_testing'),
    },
    {
      title: "HIV Risk",
      dataIndex: "hiv_risk",
      key: "hiv_risk",
      width: 200,
      render: (text) => renderText(text, 'hiv_risk'),
    },
    {
      title: "HIV MUAC",
      dataIndex: "hiv_muac",
      key: "hiv_muac",
      width: 200,
      render: (text) => renderText(text, 'hiv_muac'),
    },
    {
      title: "HIV Assessment",
      dataIndex: "hiv_assessment",
      key: "hiv_assessment",
      width: 200,
      render: (text) => renderText(text, 'hiv_assessment'),
    },
    {
      title: "Referred Facility",
      dataIndex: "referred_facility",
      key: "referred_facility",
      width: 200,
      render: (text) => renderText(text, 'referred_facility'),
    },
    {
      title: "EID Test",
      dataIndex: "eid_test",
      key: "eid_test",
      width: 200,
      render: (text) => renderText(text, 'eid_test'),
    },
    {
      title: "Age Appropriate",
      dataIndex: "age_appropriate",
      key: "age_appropriate",
      width: 200,
      render: (text) => renderText(text, 'age_appropriate'),
    },
    {
      title: "Not Appropriate Age",
      dataIndex: "not_appropriate_age",
      key: "not_appropriate_age",
      width: 200,
      render: (text) => renderText(text, 'not_appropriate_age'),
    },
    {
      title: "Child Receiving Breastfeeding",
      dataIndex: "child_receiving_breastfeeding",
      key: "child_receiving_breastfeeding",
      width: 200,
      render: (text) => renderText(text, 'child_receiving_breastfeeding'),
    },
    {
      title: "Not Breastfeeding",
      dataIndex: "not_breastfeeding",
      key: "not_breastfeeding",
      width: 200,
      render: (text) => renderText(text, 'not_breastfeeding'),
    },
    {
      title: "Not Tested",
      dataIndex: "not_tested",
      key: "not_tested",
      width: 200,
      render: (text) => renderText(text, 'not_tested'),
    },
    {
      title: "HIV Infection",
      dataIndex: "hiv_infection",
      key: "hiv_infection",
      width: 200,
      render: (text) => renderText(text, 'hiv_infection'),
    },
    {
      title: "Infection Risk",
      dataIndex: "infection_risk",
      key: "infection_risk",
      width: 200,
      render: (text) => renderText(text, 'infection_risk'),
    },
    {
      title: "Against HIV Risk",
      dataIndex: "against_hiv_risk",
      key: "against_hiv_risk",
      width: 200,
      render: (text) => renderText(text, 'against_hiv_risk'),
    },
    {
      title: "Prevention Support",
      dataIndex: "prevention_support",
      key: "prevention_support",
      width: 200,
      render: (text) => renderText(text, 'prevention_support'),
    },
    {
      title: "Substance Support",
      dataIndex: "substance_support",
      key: "substance_support",
      width: 200,
      render: (text) => renderText(text, 'substance_support'),
    },
    {
      title: "Under Five",
      dataIndex: "under_five",
      key: "under_five",
      width: 200,
      render: (text) => renderText(text, 'under_five'),
    },
    {
      title: "Attending Under Five Clinic",
      dataIndex: "attending_under_five_clinic",
      key: "attending_under_five_clinic",
      width: 200,
      render: (text) => renderText(text, 'attending_under_five_clinic'),
    },
    {
      title: "Appropriate Vaccinations",
      dataIndex: "appropriate_vaccinations",
      key: "appropriate_vaccinations",
      width: 200,
      render: (text) => renderText(text, 'appropriate_vaccinations'),
    },
    {
      title: "All Appropriate Vaccinations",
      dataIndex: "all_appropriate_vaccinations",
      key: "all_appropriate_vaccinations",
      width: 200,
      render: (text) => renderText(text, 'all_appropriate_vaccinations'),
    },
    {
      title: "MUAC Measure",
      dataIndex: "muac_measure",
      key: "muac_measure",
      width: 200,
      render: (text) => renderText(text, 'muac_measure'),
    },
    {
      title: "Nutrition Counselling A",
      dataIndex: "nutrition_counselling_a",
      key: "nutrition_counselling_a",
      width: 200,
      render: (text) => renderText(text, 'nutrition_counselling_a'),
    },
    {
      title: "Heps A",
      dataIndex: "heps_a",
      key: "heps_a",
      width: 200,
      render: (text) => renderText(text, 'heps_a'),
    },
    {
      title: "MUAC Other A",
      dataIndex: "muac_other_a",
      key: "muac_other_a",
      width: 200,
      render: (text) => renderText(text, 'muac_other_a'),
    },
    {
      title: "MUAC Measurement B",
      dataIndex: "muac_measurement_b",
      key: "muac_measurement_b",
      width: 200,
      render: (text) => renderText(text, 'muac_measurement_b'),
    },
    {
      title: "Nutrition Counselling B",
      dataIndex: "nutrition_counselling_b",
      key: "nutrition_counselling_b",
      width: 200,
      render: (text) => renderText(text, 'nutrition_counselling_b'),
    },
    {
      title: "Heps B",
      dataIndex: "heps_b",
      key: "heps_b",
      width: 200,
      render: (text) => renderText(text, 'heps_b'),
    },
    {
      title: "MUAC Other B",
      dataIndex: "muac_other_b",
      key: "muac_other_b",
      width: 200,
      render: (text) => renderText(text, 'muac_other_b'),
    },
    {
      title: "Nutrition Status",
      dataIndex: "nutrition_status",
      key: "nutrition_status",
      width: 200,
      render: (text) => renderText(text, 'nutrition_status'),
    },
    {
      title: "Neglected",
      dataIndex: "neglected",
      key: "neglected",
      width: 200,
      render: (text) => renderText(text, 'neglected'),
    },
    {
      title: "Neglected Child Exploitation",
      dataIndex: "neglected_child_exploitation",
      key: "neglected_child_exploitation",
      width: 200,
      render: (text) => renderText(text, 'neglected_child_exploitation'),
    },
    {
      title: "Neglected Child Relationships",
      dataIndex: "neglected_child_relationships",
      key: "neglected_child_relationships",
      width: 200,
      render: (text) => renderText(text, 'neglected_child_relationships'),
    },
    {
      title: "Child Above 12 A",
      dataIndex: "child_above_12_a",
      key: "child_above_12_a",
      width: 200,
      render: (text) => renderText(text, 'child_above_12_a'),
    },
    {
      title: "Type of Neglect",
      dataIndex: "type_of_neglect",
      key: "type_of_neglect",
      width: 200,
      render: (text) => renderText(text, 'type_of_neglect'),
    },
    {
      title: "Signs of Violence",
      dataIndex: "signs_of_violence",
      key: "signs_of_violence",
      width: 200,
      render: (text) => renderText(text, 'signs_of_violence'),
    },
    {
      title: "Relationships Neglected",
      dataIndex: "relationships_neglected",
      key: "relationships_neglected",
      width: 200,
      render: (text) => renderText(text, 'relationships_neglected'),
    },
    {
      title: "Physical Violence",
      dataIndex: "physical_violence",
      key: "physical_violence",
      width: 200,
      render: (text) => renderText(text, 'physical_violence'),
    },
    {
      title: "Experiencing Neglect",
      dataIndex: "experiencing_neglect",
      key: "experiencing_neglect",
      width: 200,
      render: (text) => renderText(text, 'experiencing_neglect'),
    },
    {
      title: "Type of Neglect Physical",
      dataIndex: "type_of_neglect_physical",
      key: "type_of_neglect_physical",
      width: 200,
      render: (text) => renderText(text, 'type_of_neglect_physical'),
    },
    {
      title: "Sexually Abused",
      dataIndex: "sexually_abused",
      key: "sexually_abused",
      width: 200,
      render: (text) => renderText(text, 'sexually_abused'),
    },
    {
      title: "Post GBV Care",
      dataIndex: "post_gbv_care",
      key: "post_gbv_care",
      width: 200,
      render: (text) => renderText(text, 'post_gbv_care'),
    },
    {
      title: "VSU Legal Support",
      dataIndex: "vsu_legal_support",
      key: "vsu_legal_support",
      width: 200,
      render: (text) => renderText(text, 'vsu_legal_support'),
    },
    {
      title: "Parenting Intervention",
      dataIndex: "parenting_intervention",
      key: "parenting_intervention",
      width: 200,
      render: (text) => renderText(text, 'parenting_intervention'),
    },
    {
      title: "Did the Child Access Clinical Care",
      dataIndex: "did_the_child_access_clinical_care",
      key: "did_the_child_access_clinical_care",
      width: 200,
      render: (text) => renderText(text, 'did_the_child_access_clinical_care'),
    },
    {
      title: "Type of Neglect Sexual",
      dataIndex: "type_of_neglect_sexual",
      key: "type_of_neglect_sexual",
      width: 200,
      render: (text) => renderText(text, 'type_of_neglect_sexual'),
    },
    {
      title: "Child Psychosocial",
      dataIndex: "child_psychosocial",
      key: "child_psychosocial",
      width: 200,
      render: (text) => renderText(text, 'child_psychosocial'),
    },
    {
      title: "Psychosocial Needs",
      dataIndex: "psychosocial_needs",
      key: "psychosocial_needs",
      width: 200,
      render: (text) => renderText(text, 'psychosocial_needs'),
    },
    {
      title: "Psychosocial Services",
      dataIndex: "psychosocial_services",
      key: "psychosocial_services",
      width: 200,
      render: (text) => renderText(text, 'psychosocial_services'),
    },
    {
      title: "Child Other Services",
      dataIndex: "child_other_services",
      key: "child_other_services",
      width: 200,
      render: (text) => renderText(text, 'child_other_services'),
    },
    {
      title: "Referred Services",
      dataIndex: "referred_services",
      key: "referred_services",
      width: 200,
      render: (text) => renderText(text, 'referred_services'),
    },    
    {
      title: "Currently in School",
      dataIndex: "currently_in_school",
      key: "currently_in_school",
      width: 200,
      render: (text) => renderText(text, 'currently_in_school'),
    },
    {
      title: "Not in School",
      dataIndex: "not_in_school",
      key: "not_in_school",
      width: 200,
      render: (text) => renderText(text, 'not_in_school'),
    },
    {
      title: "Child Missed",
      dataIndex: "child_missed",
      key: "child_missed",
      width: 200,
      render: (text) => renderText(text, 'child_missed'),
    },
    {
      title: "Challenges and Barriers",
      dataIndex: "challenges_barriers",
      key: "challenges_barriers",
      width: 200,
      render: (text) => renderText(text, 'challenges_barriers'),
    },
    {
      title: "Child Household",
      dataIndex: "child_household",
      key: "child_household",
      width: 200,
      render: (text) => renderText(text, 'child_household'),
    },
    {
      title: "Child Household Services",
      dataIndex: "child_household_services",
      key: "child_household_services",
      width: 200,
      render: (text) => renderText(text, 'child_household_services'),
    },
    {
      title: "OVC Caregiver",
      dataIndex: "ovc_caregiver",
      key: "ovc_caregiver",
      width: 200,
      render: (text) => renderText(text, 'ovc_caregiver'),
    },
    {
      title: "Verified by School Days",
      dataIndex: "verified_by_school_days",
      key: "verified_by_school_days",
      width: 200,
      render: (text) => renderText(text, 'verified_by_school_days'),
    },
    {
      title: "Verified by School",
      dataIndex: "verified_by_school",
      key: "verified_by_school",
      width: 200,
      render: (text) => renderText(text, 'verified_by_school'),
    },
    {
      title: "Current Calendar",
      dataIndex: "current_calendar",
      key: "current_calendar",
      width: 200,
      render: (text) => renderText(text, 'current_calendar'),
    },
    {
      title: "Did Not Progress",
      dataIndex: "did_not_progress",
      key: "did_not_progress",
      width: 200,
      render: (text) => renderText(text, 'did_not_progress'),
    },
    {
      title: "Progression Child Household",
      dataIndex: "progression_child_household",
      key: "progression_child_household",
      width: 200,
      render: (text) => renderText(text, 'progression_child_household'),
    },
    {
      title: "Progression Child Household Services",
      dataIndex: "progression_child_household_services",
      key: "progression_child_household_services",
      width: 200,
      render: (text) => renderText(text, 'progression_child_household_services'),
    },
    {
      title: "Caseworker Name",
      dataIndex: "caseworker_name",
      key: "caseworker_name",
      width: 200,
      render: (text) => renderText(text, 'caseworker_name'),
    },
    {
      title: "Caseworker Date Signed",
      dataIndex: "caseworker_date_signed",
      key: "caseworker_date_signed",
      width: 200,
      render: (text) => renderText(text, 'caseworker_date_signed'),
    },
    {
      title: "Caseworker Signature",
      dataIndex: "caseworker_signature",
      key: "caseworker_signature",
      width: 200,
      render: (text) => renderText(text, 'caseworker_signature'),
    },
    {
      title: "Manager Name",
      dataIndex: "manager_name",
      key: "manager_name",
      width: 200,
      render: (text) => renderText(text, 'manager_name'),
    },
    {
      title: "Manager Date Signed",
      dataIndex: "manager_date_signed",
      key: "manager_date_signed",
      width: 200,
      render: (text) => renderText(text, 'manager_date_signed'),
    },
    {
      title: "Manager Signature",
      dataIndex: "manager_signature",
      key: "manager_signature",
      width: 200,
      render: (text) => renderText(text, 'manager_signature'),
    },
    {
      title: "School Administration Name",
      dataIndex: "school_administration_name",
      key: "school_administration_name",
      width: 200,
      render: (text) => renderText(text, 'school_administration_name'),
    },
    {
      title: "Telephone Number",
      dataIndex: "telephone_number",
      key: "telephone_number",
      width: 200,
      render: (text) => renderText(text, 'telephone_number'),
    },
    {
      title: "School Administration Date Signed",
      dataIndex: "school_administration_date_signed",
      key: "school_administration_date_signed",
      width: 200,
      render: (text) => renderText(text, 'school_administration_date_signed'),
    },
    {
      title: "School Administration Signature",
      dataIndex: "school_administration_signature",
      key: "school_administration_signature",
      width: 200,
      render: (text) => renderText(text, 'school_administration_signature'),
    },
    {
      title: "Length on ART",
      dataIndex: "length_on_art",
      key: "length_on_art",
      width: 200,
      render: (text) => renderText(text, 'length_on_art'),
    },
    {
      title: "Indicate VL Result",
      dataIndex: "indicate_vl_result",
      key: "indicate_vl_result",
      width: 200,
      render: (text) => renderText(text, 'indicate_vl_result'),
    },
    {
      title: "Date Edited",
      dataIndex: "date_edited",
      key: "date_edited",
      width: 200,
      render: (text) => renderText(text, 'date_edited'),
    },    
  ];

  // Fetch the list of vca visits by unique_id
  const fetchAllVCAVisits = () => {
    const uid = child.uid;
    setIsLoading(true);
    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_SERVER}/child/vcavisits/${uid}`,
          options
        )
        .then((response) => {
          // Sort the services by service date in descending order
          const sortedVcaVisits = response.data.sort((a, b) => new Date(b.visits_date) - new Date(a.visits_date));
          setfetchedVcaVisits(sortedVcaVisits);
          setIsDataReadyForExport(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("axios error: " + error);
          setIsLoading(false);
        });
    }, 2000);
  };


  useEffect(() => {
    fetchAllVCAVisits();
  }, [token, uid.unique_id]);

  const displayFetchedVcaVisits = Array.isArray(fetchVcaVisits)
    ? fetchVcaVisits
      .map((visits) => {
        if (!visits) {
          return null;
        }
        const {
          unique_id,
          relational_id,
          date_edited,
          phone,
          visit_date,
          age,
          is_hiv_positive,
          child_art,
          clinical_care,
          art_appointment,
          counselling,
          art_medication,
          child_mmd,
          level_mmd,
          drug_regimen,
          date_art,
          six_months,
          facility,
          vl_last_result,
          date_hiv,
          visit_hiv_test,
          referred_for_testing,
          hiv_risk,
          hiv_muac,
          hiv_assessment,
          referred_facility,
          eid_test,
          age_appropriate,
          not_appropriate_age,
          child_receiving_breastfeeding,
          not_breastfeeding,
          not_tested,
          hiv_infection,
          infection_risk,
          against_hiv_risk,
          prevention_support,
          substance_support,
          under_five,
          attending_under_five_clinic,
          appropriate_vaccinations,
          all_appropriate_vaccinations,
          muac_measure,
          nutrition_counselling_a,
          heps_a,
          muac_other_a,
          muac_measurement_b,
          nutrition_counselling_b,
          heps_b,
          muac_other_b,
          nutrition_status,
          neglected,
          neglected_child_exploitation,
          neglected_child_relationships,
          child_above_12_a,
          type_of_neglect,
          signs_of_violence,
          relationships_neglected,
          physical_violence,
          experiencing_neglect,
          type_of_neglect_physical,
          sexually_abused,
          post_gbv_care,
          vsu_legal_support,
          parenting_intervention,
          did_the_child_access_clinical_care,
          type_of_neglect_sexual,
          child_psychosocial,
          psychosocial_needs,
          psychosocial_services,
          child_other_services,
          referred_services,
          currently_in_school,
          not_in_school,
          child_missed,
          challenges_barriers,
          child_household,
          child_household_services,
          ovc_caregiver,
          verified_by_school_days,
          verified_by_school,
          current_calendar,
          did_not_progress,
          progression_child_household,
          progression_child_household_services,
          caseworker_name,
          caseworker_date_signed,
          caseworker_signature,
          manager_name,
          manager_date_signed,
          manager_signature,
          school_administration_name,
          telephone_number,
          school_administration_date_signed,
          school_administration_signature,
          length_on_art,
          indicate_vl_result,
          delete_status,

        } = visits;

        return {
          unique_id,
          relational_id,
          date_edited,
          phone,
          visit_date,
          age,
          is_hiv_positive,
          child_art,
          clinical_care,
          art_appointment,
          counselling,
          art_medication,
          child_mmd,
          level_mmd,
          drug_regimen,
          date_art,
          six_months,
          facility,
          vl_last_result,
          date_hiv,
          visit_hiv_test,
          referred_for_testing,
          hiv_risk,
          hiv_muac,
          hiv_assessment,
          referred_facility,
          eid_test,
          age_appropriate,
          not_appropriate_age,
          child_receiving_breastfeeding,
          not_breastfeeding,
          not_tested,
          hiv_infection,
          infection_risk,
          against_hiv_risk,
          prevention_support,
          substance_support,
          under_five,
          attending_under_five_clinic,
          appropriate_vaccinations,
          all_appropriate_vaccinations,
          muac_measure,
          nutrition_counselling_a,
          heps_a,
          muac_other_a,
          muac_measurement_b,
          nutrition_counselling_b,
          heps_b,
          muac_other_b,
          nutrition_status,
          neglected,
          neglected_child_exploitation,
          neglected_child_relationships,
          child_above_12_a,
          type_of_neglect,
          signs_of_violence,
          relationships_neglected,
          physical_violence,
          experiencing_neglect,
          type_of_neglect_physical,
          sexually_abused,
          post_gbv_care,
          vsu_legal_support,
          parenting_intervention,
          did_the_child_access_clinical_care,
          type_of_neglect_sexual,
          child_psychosocial,
          psychosocial_needs,
          psychosocial_services,
          child_other_services,
          referred_services,
          currently_in_school,
          not_in_school,
          child_missed,
          challenges_barriers,
          child_household,
          child_household_services,
          ovc_caregiver,
          verified_by_school_days,
          verified_by_school,
          current_calendar,
          did_not_progress,
          progression_child_household,
          progression_child_household_services,
          caseworker_name,
          caseworker_date_signed,
          caseworker_signature,
          manager_name,
          manager_date_signed,
          manager_signature,
          school_administration_name,
          telephone_number,
          school_administration_date_signed,
          school_administration_signature,
          length_on_art,
          indicate_vl_result,
          delete_status,
          visits, // Add the entire visits object
          // and pass its state to the Screening Household Index page
        };
      })
      .filter((item) => item !== null)
    : [];


  // Sort based on the "VCA Visits Date" in descending order
  fetchVcaVisits.sort((a, b) => {
    const dateA = a?.visits_date?.split("-").reverse().join("-");
    const dateB = b?.visits_date?.split("-").reverse().join("-");
    return new Date(dateB) - new Date(dateA);
  });


  const navigate = useNavigate();

  const handleAdvancedSearchClick = () => {
    navigate("/advanced-search");
  };


  // Export to CSV
  const exportToCSV = () => {
    const headers = columns.map((column) => column.title);

    // Sort by visits_date in descending order
    const sortedVisits = [...fetchVcaVisits].sort(
      (a, b) => new Date(b.visits_date) - new Date(a.visits_date)
    );

    const csvData = [headers].concat(
      sortedVisits.map((child) => {
        const row = Object.values(child);
        row.unshift(child.uid); // Add child ID at the beginning
        return row;
      })
    );

    const csvContent = Papa.unparse(csvData);

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    // Create the filename including child full names
    const fileName = `child_visitation_data_${child.uid}_${child.firstname} ${child.lastname}.csv`;
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // export to PDF
  const exportToPDF = () => {
    const headers = columns.map((column) => column.title);

    // Sort by visits_date in descending order
    const sortedVisits = [...fetchVcaVisits].sort(
      (a, b) => new Date(b.visits_date) - new Date(a.visits_date)
    );

    const body = sortedVisits.map((row) =>
      columns.map((column) =>
        row[column.dataIndex] === null ? "" : row[column.dataIndex]
      )
    );

    const doc = new jsPDF("l", "mm", "a4");
    doc.autoTable({
      head: [headers],
      body: body,
      theme: "striped",
      styles: { fontSize: 8 },
    });

    // Create the filename including child full names
    const fileName = `child_visitation_data_${child.uid}_${child.firstname} ${child.lastname}.pdf`;
    doc.save(fileName);
  };

  const handleExportToCSV = async () => {
    if (fetchVcaVisits?.length === 0) {
      await fetchAllVCAVisits(currentPage);
    }
    exportToCSV();
  };

  const handleExportToPDF = async () => {
    if (fetchVcaVisits?.length === 0) {
      await fetchAllVCAVisits(currentPage);
    }
    exportToPDF();
  };

  return (
    <>
    <VersionBanner />
      <div className="tab-pane" id="visits">
        <React.Fragment>
          <div className="card-body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: windowWidth <= 991 ? "column" : "row",
                alignItems: "center",
              }}
            >
              {/*breadcrumb navigation*/}
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Chip
                    label="VCA Visits"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      color: "white",
                      fontWeight: "500",
                      marginLeft: "1%",
                    }}
                  ></Chip>
                  &nbsp;&nbsp;&nbsp;
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <h1
                    style={{
                      fontSize: "17px",
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Navigate to:
                  </h1>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/households">
                    <Chip
                      label="Households"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/children">
                    <Chip
                      label="VCA`s"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/mothers">
                    <Chip
                      label="Mothers"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <Tooltip title="Export to CSV">
                    <Button
                      icon={<FileExcelFilled twoToneColor="#28A745" />}
                      disabled={!isDataReadyForExport}
                      size="large"
                      style={{
                        marginRight: 8,

                        background:
                          "linear-gradient(45deg, #28A745 30%, #FF8E53 90%)",
                        color: "white",
                        boxShadow: "0 3px 5px 2px rgba(40, 167, 69, 0.3)",
                      }}
                      onClick={handleExportToCSV}
                    />
                  </Tooltip>
                </Breadcrumb.Item>
                <br />
                <br />
                {/* <Breadcrumb.Item>
                  <Tooltip title="Export to PDF">
                    <Button
                      size="large"
                      icon={<FilePdfFilled />}
                      disabled={!isDataReadyForExport}
                      style={{
                        background: "#DC3545",
                        color: "white",
                        boxShadow: "0 3px 5px 2px rgba(220, 53, 69, 0.3)",
                      }}
                      onClick={handleExportToPDF}
                    />
                  </Tooltip>
                </Breadcrumb.Item> */}
                <Breadcrumb.Item>
                  <Button
                    onClick={handleAdvancedSearchClick}
                    size="large"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      display: "block",
                      margin: "0 auto",
                      fontWeight: "500",
                    }}
                  >
                    Advanced Search
                  </Button>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div
                className="toolbar"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: windowWidth <= 991 ? "column" : "row",
                }}
              >
              </div>
            </div>
          </div>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "10vh",
              }}
            >
              <Spin size={35} color={"#808080"} loading={true} />
              <p>
                <b style={{ fontWeight: "500", textAlign: "center" }}>
                  Please wait while we process your request...
                </b>
              </p>
            </div>
          ) : (
            <Table
              dataSource={displayFetchedVcaVisits}
              columns={columns}
              scroll={{ y: 500 }}
              pagination={false}
              className="ant-table"
              locale={{
                emptyText: (
                  <b style={{ color: "black" }}>
                    There are currently no follow-up VCA Visits saved for this VCA. Kindly revisit in future...
                  </b>
                ),
              }}
            />
          )}
        </React.Fragment>
      </div>
    </>
  );
};

export default Vcavisits;
