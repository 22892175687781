import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Spin, Alert, Input, Button, Tooltip, Breadcrumb } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import AppContext from "../context/appContext";
import { Chip, debounce } from "@mui/material";
import {
  FileExcelFilled,
  FilePdfFilled, SearchOutlined
} from "@ant-design/icons";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import VersionBanner from "./versionBanner";

const Mothers = ({ history }) => {

  const token = localStorage.getItem("token");
  const clients = useContext(AppContext);

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [indexMother, setIndexMother] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [globalSearch, setGlobalSearch] = useState("");
  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);
  const [data, setData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [message, setMessage] = useState('');

  const columns = [
    {
      title: "Screened",
      dataIndex: "screened",
      key: "screened",
      width: 100,
      render: (value) => {
        if (value === null || value === undefined) {
          <i className="material-icons">{value === true ? "check" : "close"}</i>
        }
        return (
          <a className="btn btn-link btn-success btn-just-icon like">
            <i className="material-icons">{value === true ? "close" : "check"}</i>
          </a>
        );
      },
    },
    {
      title: "Household ID",
      dataIndex: "household_id",
      key: "household_id",
      width: 200,
    },
    {
      title: "Facility",
      dataIndex: "facility",
      key: "facility",
      width: 200,
    },
    {
      title: "Caseworker Name",
      dataIndex: "caseworker_name",
      key: "caseworker_name",
      width: 200,
    },
    {
      title: "Last edited",
      dataIndex: "date_edited",
      key: "date_edited",
      width: 200,
    },
  ];

  const navigate = useNavigate();

  const handleAdvancedSearchClick = () => {
    navigate("/advanced-search");
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const debouncedHandleSearch = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedHandleSearch(searchInput);
  }, [searchInput, debouncedHandleSearch]);


  const districts = [
    "Kabwe",
    "Kapiri Mposhi",
    "Mkushi",
    "Chibombo",
    "Chisamba",
    "Chikankata",
    "Mumbwa",
    "Sinazongwe",
    "Kalomo",
    "Monze",
    "Namwala",
    "Choma",
    "Mazabuka",
    "Livingstone",
    "Serenje",
    "Kazungula",
  ];

  const fetchAllMothersByDistrict = useCallback(async () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SERVER}/mother/district/${clients.district}/filtered?page=${currentPage}&itemsPerPage=${itemsPerPage}&globalSearch=${globalSearch}`,
        options
      )
      .then((res) => {
        setIndexMother(res.data.data);
        setIsDataReadyForExport(true);
        setItemsPerPage(itemsPerPage);
        setTotalPages(res.data.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage =
          err.response && err.response.data && err.response.data.message
            ? `Error: ${err.response.data.message}. Please try again.`
            : "An error occurred while fetching Mothers Data. Please try again.";
        setError(errorMessage);
      });
  }, [clients.district, currentPage,
    globalSearch, itemsPerPage, options]);

  useEffect(() => {
    if (!firstLoad) {
      fetchAllMothersByDistrict(currentPage);
    } else {
      setFirstLoad(false);
    }
  }, [currentPage, fetchAllMothersByDistrict,
    firstLoad, globalSearch]);

  useEffect(() => {
    setData(indexMother);
  }, [indexMother]);

  const displayMothersData = Array.isArray(indexMother)
    ? indexMother.map((mothers) => {
      const {
        _id,
        screened,
        facility,
        household_id,
        caregiver_name,
        caseworker_name,
        phone,
        date_edited,
      } = mothers;

      return {
        key: _id,
        screened,
        facility,
        household_id,
        caregiver_name,
        caseworker_name,
        phone,
        date_edited,
      };
    }).filter((item) => item !== null)
    : [];

  //resize window for tablet and mobile responsiveness
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // Export to CSV
  const exportToCSV = () => {
    const headers = columns.map((column) => column.title);
    const csvData = [headers].concat(
      indexMother.map((house) => Object.values(house))
    );

    const csvContent = Papa.unparse(csvData);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "mothers_data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //export to PDF
  const exportToPDF = () => {
    const headers = columns.map((column) => column.title);
    const body = displayMothersData.map((row) =>
      columns.map((column) => (row[column.dataIndex] === null ? ""
        : row[column.dataIndex]))
    );

    const doc = new jsPDF("l", "mm", "a4");
    doc.autoTable({
      head: [headers],
      body: body,
      theme: "striped",
      styles: { fontSize: 8 },
    });
    doc.save("mothers_data.pdf");
  };


  const handleExportToCSV = async () => {
    if (indexMother?.length === 0) {
      await fetchAllMothersByDistrict(currentPage);
    }
    exportToCSV();
  };

  const handleExportToPDF = async () => {
    if (indexMother?.length === 0) {
      await fetchAllMothersByDistrict(currentPage);
    }
    exportToPDF();
  };

  return (
    <>
      <VersionBanner /> 
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header-info">
                  <div className="card-icon">
                    <i className="material-icons">person</i>
                  </div>
                  <h4 className="card-title" style={{ fontWeight: "500" }}>
                    {districts[clients.district - 1]} Index Mothers Screened</h4>
                </div>
                <div className="card-body responsive-card-body">
                  <div style={{
                    display: 'flex', justifyContent: 'space-between',
                    flexDirection: windowWidth <= 991 ? 'column' : 'row',
                    alignItems: 'center'
                  }}>
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <Chip
                          label="Index Mothers Page"
                          variant="outlined"
                          color="primary"
                          style={{
                            backgroundColor: '#e0e0e0',
                            color: 'black', fontWeight: "500", marginLeft: "1%"
                          }}
                        >
                        </Chip>
                        &nbsp;&nbsp;&nbsp;
                      </Breadcrumb.Item>
                      <br /><br />
                      <Breadcrumb.Item>
                        <h1 style={{ fontSize: '17px', color: 'black', fontWeight: "500" }}> Navigate to:</h1>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/households">
                          <Chip
                            label="Households"
                            variant="outlined"
                            color="primary"
                            clickable
                            style={{
                              backgroundColor: '#e0e0e0',
                              color: 'black', fontWeight: "500"
                            }}
                          >
                          </Chip>
                        </Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/children">
                          <Chip
                            label="VCA`s"
                            variant="outlined"
                            color="primary"
                            clickable
                            style={{
                              backgroundColor: '#e0e0e0',
                              color: 'black', fontWeight: "500"
                            }}
                          >
                          </Chip>
                        </Link>
                      </Breadcrumb.Item>
                      <br /><br />
                      <Breadcrumb.Item>
                        <Tooltip title="Export to CSV">
                          <Button
                            icon={<FileExcelFilled twoToneColor="#28A745" />}
                            disabled={!isDataReadyForExport}
                            size="large"
                            style={{
                              marginRight: 8,

                              background: 'linear-gradient(45deg, #28A745 30%, #FF8E53 90%)',
                              color: 'white',
                              boxShadow: '0 3px 5px 2px rgba(40, 167, 69, 0.3)',

                            }}
                            onClick={handleExportToCSV}
                          />
                        </Tooltip>

                      </Breadcrumb.Item>
                      <br /><br />
                      <Breadcrumb.Item>
                        <Tooltip title="Export to PDF">
                          <Button
                            size="large"
                            icon={<FilePdfFilled />}
                            disabled={!isDataReadyForExport}
                            style={{
                              background: '#DC3545',
                              color: 'white',
                              boxShadow: '0 3px 5px 2px rgba(220, 53, 69, 0.3)',
                            }}
                            onClick={handleExportToPDF}
                          />
                        </Tooltip>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Button
                          onClick={handleAdvancedSearchClick}
                          size="large"
                          style={{
                            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                            color: 'white',
                            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, 0.3)',
                            display: 'block',
                            margin: '0 auto',
                            fontWeight: "500",
                          }}
                        >
                          Advanced Search
                        </Button>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="toolbar"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: windowWidth <= 991 ? 'column' : 'row',
                      }}>
                      <Input
                        placeholder="You can search here..."
                        value={searchInput}
                        onChange={handleSearchChange}
                        prefix={<span><SearchOutlined style={{ color: "#11B8CC" }} /></span>}
                        style={{
                          marginLeft: windowWidth <= 991 ? 0 : 150,
                          marginTop: windowWidth <= 991 ? 20 : 0,
                          width: windowWidth <= 991 ? '100%' : 300
                        }}
                        size="large"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="toolbar"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                    <div>
                    </div>
                  </div>
                  <div className="toolbar">
                    <div className="toolbar">
                      {searchInput && (
                        <>
                          <span style={{ color: "black", marginRight: 8, fontWeight: "500" }}>

                            <b>You Searched For:</b></span>
                          <Chip label={searchInput} style={{ color: "black", fontWeight:"500" }} />
                        </>
                      )}
                    </div>
                    <br />
                    <div className="toolbar"
                      style={{
                        display: 'flex',
                        justifyContent:
                          'flex-end'
                      }}>
                    </div>
                    {loading ? (
                      <div style={{
                        display: 'flex', justifyContent: 'center',
                        alignItems: 'center', flexDirection: 'column', height:
                          '100%'
                      }}>
                        <Spin />

                        <p><b style={{ fontWeight: "500" }}>Please wait while we process your request...</b></p>

                      </div>
                    ) : error ? (
                      <Alert message={error} type="error" />
                    ) : (
                      <Table
                        columns={columns}
                        dataSource={displayMothersData}
                        scroll={{ x: true }}
                        pageSizeOptions={[10, 20, 50, 100]}
                        pagination={{
                          pageSize: itemsPerPage,
                          showQuickJumper: true,
                          showSizeChanger: true,
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} 
                            Total Mothers Screened`,
                          onShowSizeChange: (current, size) => setItemsPerPage(size),
                          total: totalPages * itemsPerPage,
                          current: currentPage,
                          onChange: (page) => setCurrentPage(page),
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(Mothers);
