import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import { MultiSelect } from "react-multi-select-component";
import AddUserModal from "../components/addUserModal";
import EditUserModal from "../components/editUserModal";
import {
  Table, Spin, Alert, Input, Button, Tooltip,
  Breadcrumb, Select, Modal, Radio, Popover, Row, Col, Typography, message
} from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { Chip } from "@mui/material";
import {
  FileExcelTwoTone,
  SearchOutlined, EditOutlined, DeleteOutlined, FilePdfOutlined
} from "@ant-design/icons";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import VersionBanner from "./versionBanner";

const UserManagement = () => {

  const token = localStorage.getItem("token");
  const isAdmin = parseInt(localStorage.getItem("isAdmin"), 10);
  const currentUserIsAdmin = parseInt(localStorage.getItem("isAdmin"), 10);

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(0);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(0);
  const [deletedId, setDeletedId] = useState(0);
  const [editId, setEditId] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState(null);
  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [roleFilter, setRoleFilter] = useState("");
  const [userIdCheck, setUserIdCheck] = useState('');
  const [idFieldDisabled, setIdFieldDisabled] = useState(false);
  const [updateUsersList, setUpdateUsersList] = useState(false);

  const navigate = useNavigate();

  const goToRestoreUsers = () => {
    navigate('/deleted-users');
  };

  //Enable multiple districts select on the add new user modal
  const districtsRoles = [
    { label: "Kabwe", value: 1 },
    { label: "Kapiri Mposhi", value: 2 },
    { label: "Mkushi", value: 3 },
    { label: "Chibombo", value: 4 },
    { label: "Chisamba", value: 5 },
    { label: "Chikankata", value: 6 },
    { label: "Mumbwa", value: 7 },
    { label: "Sinazongwe", value: 8 },
    { label: "Kalomo", value: 9 },
    { label: "Monze", value: 10 },
    { label: "Namwala", value: 11 },
    { label: "Choma", value: 12 },
    { label: "Mazabuka", value: 13 },
    { label: "Livingstone", value: 14 },
    { label: "Serenje", value: 15 },
    { label: "Kazungula", value: 16 },
  ];

  const isEmptyOrWhitespace = (str) => !str || str.trim().length === 0;
  const { Text } = Typography;
  const getColumnWidth = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      return 400;
    } else if (screenWidth >= 992) {
      return 500;
    } else if (screenWidth >= 768) {
      return 500;
    } else {
      return 'auto';
    }
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          User Assigned District(s)
        </div>
      ),
      dataIndex: "districts",
      key: "districts",
      width: getColumnWidth(),
      render: (districts) => {

        const formattedDistricts = districts?.replace(/\[|\]/g, "");
        const districtLabels = formattedDistricts?.replace(/(\d+)/g, (match) => {
          const parsedValue = parseInt(match);

          const districtRole = districtsRoles?.find(
            (role) => role?.value === parsedValue
          );
          return districtRole ? districtRole?.label : match;
        });

        if (isEmptyOrWhitespace(districtLabels)) {
          return <Text style={{ fontWeight: "500", fontSize: "14px" }}>
            No district assigned</Text>;
        }

        const visibleItems = 4;
        const visibleLabels = districtLabels?.split(", ").slice(0, visibleItems);
        const hasMoreItems = districtLabels?.split(", ").length > visibleItems;

        return (
          <>
            <Row gutter={[8, 8]} style={{ marginBottom: '8px' }}>
              {visibleLabels?.map((label, index) => (
                <Col key={index}>
                  <Text
                    style={{
                      fontFamily: "Times New Roman",
                      fontSize: "14px",
                      fontWeight: "600"
                    }}
                  >
                    {label}
                  </Text>
                </Col>
              ))}
            </Row>
            {hasMoreItems && (
              <Popover
                content={
                  <div>
                    {districtLabels.split(", ")?.slice(visibleItems)?.map((label, index) => (
                      <div key={index}>{label}</div>
                    ))}
                  </div>
                }
                title="Full District List"
              >
                <a style={{ marginLeft: "8px" }}>View full list</a>
              </Popover>
            )}
          </>
        );
      },
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      title: "User Role",
      dataIndex: "isAdmin",
      key: "isAdmin",
      render: (isAdmin, _, currentUserIsAdmin) => {
        if (currentUserIsAdmin === 2 && isAdmin === 1) {
          return "Not Available";
        } else if (isAdmin === 1) {
          return "Super Administrator";
        } else if (isAdmin === 2) {
          return "Administrator";
        } else if (isAdmin === 0) {
          return "Data Quality Assurance User";
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                setEditId(record?.id);
                showEditUserModal();
              }}
              style={{ marginRight: 10 }}
            />
          </Tooltip>
          <Tooltip>
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setDeletedId(record?.id);
                setFirstName(record?.first_name);
                setLastName(record?.last_name);
                setVisible(true);
              }}
            />
            <Modal
              title="Delete User"
              open={visible}
              onOk={handleDelete}
              okButtonProps={{ danger: true }}
              onCancel={() => setVisible(false)}
              type="primary"
              danger
            >
              <p>Are you sure you want to delete {firstName} {lastName} from the users list? Click 'OK' to confirm.</p>
            </Modal>
          </Tooltip>
        </div>
      ),
    }
  ];

  //enable filter by gender on the table
  const { Option } = Select;

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleRoleFilterChange = (value) => {
    setRoleFilter(value);
  };

  const resetForm = () => {
    setFormData({
      id: "",
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      password_confirm: "",
      phone: "",
      role: [],
      isAdmin: 0,
    });
    setSelected([]); // Reset the selected districts
    setValue(""); // Reset isAdmin radio button
  };

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const showEditUserModal = () => {
    setUpdateUserModal(true);
  };

  const hideEditUserModal = () => {
    setUpdateUserModal(false);
  };

  const getUsersList = async (searchQuery, roleFilter) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/user/get-users?search=${encodeURIComponent(searchQuery)}&isAdmin=${encodeURIComponent(roleFilter)}`,
        options
      );
      setLoading(false);

      // Filter out Super Administrators if the current user is an Administrator
      const filteredUsers = currentUserIsAdmin === 2
        ? response?.data?.filter(user => user?.isAdmin !== 1)
        : response?.data;

      setUsers(filteredUsers);
      setIsDataReadyForExport(true);
    } catch (err) {
      setLoading(false);
      const errorMessage =
        err.response && err.response.data && err.response.data.message
          ? `Error: ${err.response.data.message}. Please try again.`
          : "An error occurred while fetching Data Quality Assurance Platform (DQA) Users. Please try again.";
      setError(errorMessage);
    }
  };

  useEffect(() => {
    getUsersList(searchInput, roleFilter);
  }, [searchInput, roleFilter, updateUsersList]);

  const displayUserData = Array.isArray(users)
    ? users
      .map((user) => {
        if (!user) {
          return null;
        }
        const {
          id,
          first_name,
          last_name,
          email,
          phone,
          role,
          addedBy,
          isAdmin,
        } = user;

        return {
          key: id,
          first_name,
          last_name,
          email,
          phone,
          districts: role,
          addedBy,
          isAdmin,
          id,
        };
      })
      .filter((item) => item !== null)
    : [];


  //Add new user in database operations
  //Form data for adding new user
  const [formData, setFormData] = useState({
    id: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    password_confirm: "",
    phone: "",
    role: [],
    isAdmin: 0,
  });
  const onChange = (event) => {
    setValue(event.target.value);
  };

  //handle change event for input fields when adding new user
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === 'id') {
      checkIfIdExists(value);
    }
  };

  const validateEmail = (email) => {
    //validate email format
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  //validate password strength
  const isPasswordStrong = (password) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);

    return (
      password?.length >= minLength &&
      hasUppercase &&
      hasLowercase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const checkIfIdExists = async (id) => {
    // Validate ID format
    const idFormat = /^[0-9]+$/;
    if (!idFormat.test(String(id))) {
      setUserIdCheck('Invalid ID format. ID should be a digit.');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER}/user/check-id/${id}`, options);

      if (response.data.exists) {
        setUserIdCheck('ID already exists. Please use a different one.');
      } else {
        // Clear the userIdCheck message when there's no error
        setUserIdCheck('');
      }
    } catch (error) {
      console.error(error);
      setUserIdCheck('Error checking ID availability... Please try again.');
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    // Call the checkIfIdExists function and wait for it to complete
    await checkIfIdExists(formData.id);

    // Check if there's an ID error
    if (userIdCheck) {
      message.warning(userIdCheck);
      return;
    }

    if (!validateEmail(formData.email)) {
      message.warning("Invalid email address format");
      return;
    }

    if (!isPasswordStrong(formData.password)) {
      message.warning({
        content: (
          <React.Fragment>
            <p>You cannot leave the password field empty. <br />Also ensure the password is:
              <br /> 8 characters long, 1+ uppercase, lowercase,
              <br />number, special symbol.</p>
            <p>Example: Password@21131</p>
          </React.Fragment>
        ),
      });
      return;
    }

    if (formData?.password === formData?.password_confirm) {
      setLoading(1);

      const newDistricts = selected?.map((district) => district?.value);
      formData.role = newDistricts;
      formData.isAdmin = value;

      // Add a check for userIdCheck before creating the user
      if (!userIdCheck) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER}/user/signup`,
            { ...formData, addedBy: localStorage.getItem("username") }
          );
          setLoading(0);
          navigate("/users", { replace: true });
          message.success({
            content: 'User Created and Added Successfully.',
            className: 'centered-message',
            duration: 3,
          });
          const usersResponse = await axios.get(
            `${process.env.REACT_APP_SERVER}/user/get-users`,
            options
          );
          setUsers(usersResponse.data);
          resetForm();
          hideModal();
        }
        catch (err) {
          setLoading(0);
          const errorMessage =
            err.response && err.response.data && err.response.data.message
              ? `Error: ${err.response.data.message}. Please try again.`
              : "An error occurred while adding new user. Please try again.";

          message.error({
            content: errorMessage,
            className: 'centered-message',
            duration: 3,
          });
        }
      }
    } else {
      message.warning("Passwords do not match");
    }
  };

  //Edit user in database operations
  //Avoid using the same state as the add new user form
  //to avoid overwriting the add new user form data
  const [editFormData, setEditFormData] = useState({
    id: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    password_confirm: "",
    phone: "",
    role: [],
    isAdmin: 0,

  });


  const handleEditChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "radio" && name === "isAdmin") {
      setEditFormData((prevState) => ({
        ...prevState,
        [name]: parseInt(value, 10),
      }));
      setValue(parseInt(value, 10));
    } else if (type === "checkbox" && name === "isAdmin") {
      setEditFormData((prevState) => ({
        ...prevState,
        [name]: checked ? 1 : 0,
      }));
    } else {
      setEditFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (editId) {
      const userToEdit = users && users?.find((user) => user?.id === editId);
      setInitialValues(userToEdit);
    }
  }, [editId, users]);


  const handleEditUser = async (event) => {
    event.preventDefault();

    if (!validateEmail(editFormData.email)) {
      message.warning("Invalid email address format");
      return;
    }

    if (editFormData.password === editFormData.password_confirm) {
      setLoading(1);

      const newDistricts = selected?.map((district) => district?.value);
      editFormData.role = newDistricts;

      try {
        const response = await axios.put(
          `${process.env.REACT_APP_SERVER}/user/edit-user/${editId}`,
          { ...editFormData, addedBy: localStorage.getItem("username") },
          options
        );
        setLoading(0);
        navigate("/users", { replace: true });
        message.success(`User Edited Successfully.`);
        const usersResponse = await axios.get(
          `${process.env.REACT_APP_SERVER}/user/get-users`,
          options
        );
        setUsers(usersResponse.data);
        setUpdateUsersList(!updateUsersList);
        hideEditUserModal();
      } catch (error) {
        console.error("Error while editing user:", error);
        setLoading(0);
      }
    } else {
      message.warning("Passwords do not match");
    }
  };

  const setInitialValues = (user) => {
    let roleArray = [];

    try {
      roleArray = JSON?.parse(user?.role);
    } catch (error) {
      roleArray = [];
    }

    const selectedDistricts = Array?.isArray(roleArray)
      ? roleArray?.map((district) => {
        const districtRole = districtsRoles?.find(
          (role) => role && role?.value === parseInt(district)
        );
        return {
          value: district,
          label: districtRole ? districtRole?.label : district,
        };
      })
      : [];

    setSelected(selectedDistricts);

    setEditFormData((prevState) => ({
      ...prevState,
      id: user?.id,
      firstname: user?.first_name,
      lastname: user?.last_name,
      email: user?.email,
      phone: user?.phone,
      role: selectedDistricts?.map((district) => district?.value),
      isAdmin: parseInt(user?.isAdmin, 10),
    }));

    setValue(parseInt(user?.isAdmin, 10));
  };

  useEffect(() => {
    if (editId) {
      const userToEdit = users && users?.find((user) => users && user?.id === editId);
      setInitialValues(userToEdit);
    }
  }, [editId, users]);

  //delete user from database operations
  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER}/user/delete-user/${deletedId}`,
        options
      );
      setLoading(0);
      setVisible(false);
      navigate("/users", { replace: true });
      const updatedUsers = users && users?.filter((user) => users && user?.id !== deletedId);
      setUsers(updatedUsers);
      message.success(`User ${firstName} ${lastName} Deleted Successfully.`);
      navigate("/users");
    } catch (error) {
      console.error(error);
      setLoading(0);
    }
  };

  const onDeleteClicked = (id) => {
    setVisible(true);
    setDeletedId(id);
  };

  const onEditClicked = (id) => {
    showEditUserModal();
    setEditId(id);
  };


  /*objects label and value are not valid react children,
 to render the districts on the user management table 
component use an array instead*/

  const districts = [
    "Kabwe",
    "Kapiri Mposhi",
    "Mkushi",
    "Chibombo",
    "Chisamba",
    "Chikankata",
    "Mumbwa",
    "Sinazongwe",
    "Kalomo",
    "Monze",
    "Namwala",
    "Choma",
    "Mazabuka",
    "Livingstone",
    "Serenje",
    "Kazungula",
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const exportToCSV = () => {
    const headers = columns.map((column) => column.title);
    const csvData = [headers].concat(
      displayUserData.map((row) =>
        columns.map((column) => (row[column.dataIndex]
          === null ? "" : row[column.dataIndex]))
      )
    );
    const csvContent = Papa.unparse(csvData);
    const blob = new Blob([csvContent], {
      type:
        "text/csv;charset=utf-8;"
    });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "DQA_USERS_LIST.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToPDF = () => {
    const headers = columns.map((column) => column.title);
    const body = displayUserData.map((row) =>
      columns.map((column) => (row[column.dataIndex] === null ? ""
        : row[column.dataIndex]))
    );

    const doc = new jsPDF("l", "mm", "a4");
    doc.autoTable({
      head: [headers],
      body: body,
      theme: "striped",
      styles: { fontSize: 8 },
    });
    doc.save("DQA_USERS_LIST.pdf");
  };

  const handleExportToCSV = async () => {
    if (users?.length === 0) {
      await getUsersList();
    }
    exportToCSV();
  };

  const handleExportToPDF = async () => {
    if (users?.length === 0) {
      await getUsersList();
    }
    exportToPDF();
  };

  return (
    <>
      <VersionBanner /> 
      <AddUserModal
        resetForm={resetForm}
        district={districts}
        isAdmin={isAdmin}
        idFieldDisabled={idFieldDisabled}
        checkIfIdExists={checkIfIdExists}
        handleSubmit={handleSubmit}
        showModal={showModal}
        Modal={Modal}
        hideModal={hideModal}
        formData={formData}
        handleChange={handleChange}
        MultiSelect={MultiSelect}
        districtsRoles={districtsRoles}
        selected={selected}
        setSelected={setSelected}
        value={value}
        Radio={Radio}
        BounceLoader={BounceLoader}
        onChange={onChange}
        loading={loading}
        open={open}
      />

      <EditUserModal
        isAdmin={isAdmin}
        handleEditUser={handleEditUser}
        handleEditChange={handleEditChange}
        users={users}
        editFormData={editFormData}
        Modal={Modal}
        MultiSelect={MultiSelect}
        districtsRoles={districtsRoles}
        selected={selected}
        setSelected={setSelected}
        value={value}
        Button={Button}
        Radio={Radio}
        BounceLoader={BounceLoader}
        loading={loading}
        updateUserModal={updateUserModal}
        hideEditUserModal={() => setUpdateUserModal(false)}
        editId={editId}
      />
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header-info">
                  <div className="card-icon">
                    <i className="material-icons">person</i>
                  </div>
                  <h4 className="card-title" style={{ fontWeight: "500" }}>
                    User Management Portal</h4>
                </div>
                <div className="card-body">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: windowWidth <= 991 ? 'column' : 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <Chip
                          label="Advanced Search Page"
                          variant="outlined"
                          color="primary"
                          style={{
                            backgroundColor: '#e0e0e0',
                            color: 'black', fontWeight: "500", marginLeft: "10%"
                          }}
                        >
                        </Chip>
                        &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <h1 style={{ fontSize: '17px', color: 'black', fontWeight: "500" }}> Navigate to:</h1>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/households">
                          <Chip
                            label="Households"
                            variant="outlined"
                            color="primary"
                            clickable
                            style={{
                              backgroundColor: '#e0e0e0',
                              color: 'black', fontWeight: "500"
                            }}
                          >
                          </Chip>
                        </Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/children">
                          <Chip
                            label="VCA`s"
                            variant="outlined"
                            color="primary"
                            clickable
                            style={{
                              backgroundColor: '#e0e0e0',
                              color: 'black', fontWeight: "500"
                            }}
                          >
                          </Chip>
                        </Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/mothers">
                          <Chip
                            label="Mothers"
                            variant="outlined"
                            color="primary"
                            clickable
                            style={{
                              backgroundColor: '#e0e0e0',
                              color: 'black', fontWeight: "500"
                            }}
                          >
                          </Chip>
                        </Link>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="text-right">
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <br /><br /><br />
                        <Button
                          type="primary"
                          onClick={showModal}
                          style={{
                            borderRadius: "30px",
                            marginLeft: "15px",
                          }}
                        >
                          Add New User
                        </Button>
                        <span style={{ marginLeft: "10px" }}>|</span>

                        <Button
                          type="primary"
                          danger
                          style={{ marginLeft: '10px' }}
                          title="Recycle Bin"
                          onClick={goToRestoreUsers}
                        >
                          <p style={{ textAlign: "center" }}>Recylce Bin</p>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: windowWidth <= 991 ? 'column' : 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Breadcrumb>
                        <Breadcrumb.Item>
                          <h1 style={{ fontSize: '17px', color: 'black', fontWeight: "500" }}>Filter users by:</h1>
                          <Select value={roleFilter} onChange={handleRoleFilterChange} style={{ width: 220 }}>
                            <Option value="">All</Option>
                            {isAdmin === 1 && (
                              <Option value="1">Super Administrators</Option>
                            )}
                            <Option value="2">Administrators</Option>
                            <Option value="0">Data Quality Assurance Users</Option>
                          </Select>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <Tooltip title="Export to CSV">
                            <Button
                              icon={<FileExcelTwoTone twoToneColor="#28A745" />}
                              disabled={!isDataReadyForExport}
                              size="large"
                              style={{
                                marginRight: 8,
                                background: 'linear-gradient(45deg, #28A745 30%, #FF8E53 90%)',
                                color: 'white',
                                boxShadow: '0 3px 5px 2px rgba(40, 167, 69, 0.3)',
                              }}
                              onClick={handleExportToCSV}
                            />
                          </Tooltip>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <Tooltip title="Export to PDF">
                            <Button
                              size="large"
                              icon={<FilePdfOutlined />}
                              disabled={!isDataReadyForExport}
                              style={{
                                background: '#DC3545',
                                color: 'white',
                                boxShadow: '0 3px 5px 2px rgba(220, 53, 69, 0.3)',
                              }}
                              onClick={handleExportToPDF}
                            />
                          </Tooltip>
                        </Breadcrumb.Item>
                      </Breadcrumb>
                      <div
                        className="toolbar"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: windowWidth <= 991 ? 'column' : 'row',
                        }}
                      >
                        <Input
                          placeholder="You can search here..."
                          value={searchInput}
                          onChange={handleSearchChange}
                          prefix={<span><SearchOutlined style={{ color: '#11B8CC' }} /></span>}
                          style={{
                            marginLeft: windowWidth <= 991 ? 0 : 150,
                            marginTop: windowWidth <= 991 ? 20 : 0,
                            width: windowWidth <= 991 ? '100%' : 300,
                          }}
                          size="large"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="toolbar" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div></div>
                    </div>
                    <div className="toolbar">
                      <div className="toolbar">
                        {searchInput && (
                          <>
                            <span style={{ color: "black", marginRight: 8, fontWeight: "500" }}>
                              <b>You Searched For:</b></span>
                            <Chip label={searchInput} style={{ color: 'grey' }} />
                          </>
                        )}
                      </div>
                      <br />
                      <div className="toolbar" style={{ display: 'flex', justifyContent: 'flex-end' }}></div>
                      {loading ? (
                        <div style={{
                          display: 'flex', justifyContent: 'center',
                          alignItems: 'center', flexDirection: 'column', height:
                            '100%'
                        }}>
                          <Spin />
                          <p><b style={{ fontWeight: "500" }}>Please wait while we process your request...</b></p>
                        </div>
                      ) : error ? (
                        <Alert message={error} type="error" />
                      ) : (
                        <Table columns={columns} dataSource={displayUserData} scroll={{ x: 10 }} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default UserManagement;