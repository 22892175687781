import { useEffect } from "react";
const EditUserModal = ({
  handleEditChange,
  isAdmin,
  editFormData,
  Modal,
  MultiSelect,
  districtsRoles,
  selected,
  setSelected,
  handleEditUser,
  value,
  Radio,
  BounceLoader,
  loading,
  updateUserModal,
  hideEditUserModal,
}) => {

  return (
    <Modal open={updateUserModal} onCancel={hideEditUserModal} footer={null}>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <form onSubmit={handleEditUser}>
                <div className="card">
                  <div className=" card-header-info">
                    <div className="card-icon">
                      <p>Edit Current User Form</p>
                    </div>
                  </div>
                  <div className="card-body ">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="firstname"
                        value={editFormData.firstname}
                        onChange={handleEditChange} // Update this line
                        required="true"
                      />

                    </div>
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="lastname"
                        value={editFormData.lastname}
                        onChange={handleEditChange}
                        required="true"
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        value={editFormData.email}
                        onChange={handleEditChange}
                        required="true"
                      />
                    </div>
                    <div class="form-group">
                      <label>Enter Password</label>
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        value={editFormData.password}
                        onChange={handleEditChange}
                        required="true"
                      />
                    </div>
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input
                        className="form-control"
                        type="password"
                        name="password_confirm"
                        value={editFormData.password_confirm}
                        onChange={handleEditChange}
                        required="true"
                      />
                    </div>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        className="form-control"
                        type="text"
                        name="phone"
                        value={editFormData.phone}
                        onChange={handleEditChange}
                        required="true"
                      />
                    </div>
                    <div className="form-group">
                      <label>Select Districts</label>
                      <MultiSelect
                        options={districtsRoles}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Selected"
                      />
                    </div>
                    <div className="form-group">
                      <label>Select User Type</label>
                      <br />
                      <Radio.Group
                        onChange={handleEditChange}
                        value={editFormData.isAdmin}
                        name="isAdmin"
                      >
                        {isAdmin === 1 && (
                          <Radio value={1}>
                            <p style={{ color: "gray" }}>Super Administrator</p>
                          </Radio>
                        )}
                        <Radio value={2}>Administrator</Radio>
                        <Radio value={0}>District Users</Radio>
                      </Radio.Group>

                    </div>
                  </div>
                  <div className="card-footer text-right">
                    <div className="form-check mr-auto"></div>
                    {loading ? (
                      <div style={{ float: "right" }}>
                        <BounceLoader
                          size={35}
                          color={"#808080"}
                          loading={true}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <button
                      type="submit"
                      onClick={handleEditUser}
                      className="btn btn-info"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};


export default EditUserModal;