import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import VersionBanner from "../../pages/versionBanner";
import { Card, Descriptions } from "antd";
import Title from 'antd/es/typography/Title';

const Screening = () => {

  const location = useLocation();
  const house = location.state?.house;

  const capitalizeFirst = (str) => {
    if (typeof str !== 'string' || str.length === 0) {
      return str;
    }
    const firstLetter = str.charAt(0).toUpperCase();
    const restOfString = str.slice(1);
    return firstLetter + restOfString;
  };

  const displayBoolean = (value) => {
    if (value === 0 || value === false || value === 'false' || value === '0') {
      return 'False';
    } else if (value === 1 || value === true || value === 'true' || value === '1') {
      return 'Yes';
    }
    return '';
  };

  const renderValue = (value) => {
    if (value === null || value === undefined) {
      return 'Not Provided';
    }
    return value;
  };

  const caregiverInfo = [
    {
      label: 'Screened',
      value: renderValue(displayBoolean(house?.screened)),
    },
    {
      label: 'Case status',
      value: renderValue(capitalizeFirst(house?.case_status)),
    },
    {
      label: 'Household Id',
      value: renderValue(capitalizeFirst(house?.household_id)),
    },
    {
      label: 'Caregiver Name',
      value: `${capitalizeFirst(house?.caregiver_name)}`,
    },
    {
      label: 'Caseworker Name',
      value: `${capitalizeFirst(house?.caseworker_name)}`,
    },
    {
      label: 'Caregiver Phone Number',
      value: renderValue(capitalizeFirst(house?.caregiver_phone)),
    },
    {
      label: 'Caseworker Phone Number',
      value: renderValue(capitalizeFirst(house?.caseworker_phone)),
    },
    {
      label: 'Date Enrolled',
      value: renderValue(moment(house?.date_enrolled).format('DD/MM/YYYY')),
    },
    {
      label: 'Caregiver Gender',
      value: renderValue(capitalizeFirst(house?.caregiver_sex)),
    },
    {
      label: 'Date Referred',
      value: renderValue(capitalizeFirst(house?.date_referred)),
    },
    {
      label: 'Marital Status',
      value: renderValue(capitalizeFirst(house?.marital_status)),
    },
    {
      label: 'Is on HIV Treatment?',
      value: renderValue((house?.is_on_hiv_treatment)),
    },
    {
      label: 'Caregiver HIV Status',
      value: renderValue(capitalizeFirst(house?.caregiver_hiv_status)),
    },
    {
      label: 'Caregiver ART Number',
      value: renderValue(house?.caregiver_art_number),
    },
    {
      label: 'Caregiver Birthdate',
      value: renderValue((house?.caregiver_birthdate)),
    },
    {
      label: 'Education',
      value: renderValue(capitalizeFirst(house?.education)),
    }
  ];

  const householdInfo = [
    {
      label: 'Number of beds',
      value: renderValue(house?.beds),
    },
    {
      label: 'Health Facility',
      value: renderValue(capitalizeFirst(house?.facility)),
    },
    {
      label: 'Malaria ITNs',
      value: renderValue(house?.malaria_itns),
    },
    {
      label: 'Number of pregnant women',
      value: renderValue((house?.pregnant_woment))
    },
    {
      label: 'Partner',
      value: renderValue(capitalizeFirst(house?.partner)),
    },
    {
      label: 'Relation',
      value: renderValue(capitalizeFirst(house?.relation)),
    },
    {
      label: 'Child MMD',
      value: renderValue(house?.child_mmd),
    },
    {
      label: 'Unique ID',
      value: renderValue(house?.unique_id),
    },
    {
      label: 'Acceptance',
      value: renderValue((house?.acceptance)),
    },
    {
      label: 'Family Source of Income',
      value: renderValue(capitalizeFirst(house?.family_source_of_income)),
    },
    {
      label: 'Household Income',
      value: renderValue(house?.household_income),
    },
    {
      label: 'ART Number',
      value: renderValue(house?.caregiver_art_number),
    },
    {
      label: 'Entry type',
      value: renderValue(capitalizeFirst(house?.entry_type)),
    },
    {
      label: 'Date edited',
      value: renderValue((house?.date_edited)),
    },
    {
      label: 'Household Id',
      value: renderValue(house?.household_id),
    },
    {
      label: 'VL check box',
      value: renderValue(capitalizeFirst(house?.vl_check_box)),
    },
    {
      label: 'ART check box',
      value: renderValue(capitalizeFirst(house?.art_check_box)),
    },
    {
      label: 'Client result',
      value: renderValue(capitalizeFirst(house?.client_result)),
    }
  ];

  const indexVcaInfo = [
    {
      label: 'Date enrolled',
      value: renderValue(moment(house?.date_enrolled).format('YYYY/MM/DD')),
    },
    {
      label: 'Date referred',
      value: renderValue((house?.date_referred)),
    },
    {
      label: 'Date screened',
      value: renderValue((house?.date_screened0)),
    },
    {
      label: 'Takes drugs to prevent other diseases',
      value: renderValue((house?.takes_drugs_to_prevent_other_diseases)),
    },
    {
      label: 'District',
      value: renderValue(capitalizeFirst(house?.district)),
    },
    {
      label: 'Date HIV known',
      value: renderValue((house?.date_hiv_known)),
    },
    {
      label: 'Relationship',
      value: renderValue(capitalizeFirst(house?.relationship)),
    },
    {
      label: 'Emergency Name',
      value: renderValue(capitalizeFirst(house?.emergency_name)),
    },
    {
      label: 'Marital Status',
      value: renderValue(capitalizeFirst(house?.marital_status)),
    },
    {
      label: 'Pregnant Women',
      value: renderValue((house?.pregnant_woment))
    },
    {
      label: 'Screening date',
      value: renderValue((house?.screening_date)),
    },
    {
      label: 'Approved Family',
      value: renderValue((house?.approved_family)),
    },
    {
      label: 'Index check box',
      value: renderValue(capitalizeFirst(house?.index_check_box)),
    },
    {
      label: 'Is HIV positive',
      value: renderValue((house?.is_hiv_positive)),
    },
    {
      label: 'Date started ART',
      value: renderValue((house?.date_started_art)),
    },
    {
      label: 'Consent check box',
      value: renderValue(capitalizeFirst(house?.consent_check_box)),
    },
    {
      label: 'Date edited check',
      value: renderValue((house?.date_edited_check)),
    },
    {
      label: 'Screening Location',
      value: renderValue(capitalizeFirst(house?.screening_location)),
    },
    {
      label: 'Active on Treatment',
      value: renderValue((house?.active_on_treatment)),
    },
    {
      label: 'Biological children',
      value: renderValue((house?.biological_children)),
    },
    {
      label: 'Caregiver education',
      value: renderValue(capitalizeFirst(house?.caregiver_education)),
    },
    {
      label: 'Is on HIV treatment',
      value: renderValue((house?.is_on_hiv_treatment)),
    },
    {
      label: 'Violence six months',
      value: renderValue((house?.violence_six_months)),
    },
    {
      label: 'Adolescent birthdate',
      value: renderValue((house?.adolescent_birthdate)),
    },
    {
      label: 'Caregiver ART number',
      value: renderValue(house?.caregiver_art_number),
    },
    {
      label: 'Caregiver birthdate',
      value: renderValue((house?.caregiver_birthdate)),
    },
    {
      label: 'Caregiver HIV status',
      value: renderValue(capitalizeFirst(house?.caregiver_hiv_status)),
    },
    {
      label: 'TPT client initiated',
      value: renderValue((house?.tpt_client_initiated)),
    },
    {
      label: 'TPT client eligibility',
      value: renderValue((house?.tpt_client_eligibility)),
    },
    {
      label: 'Date offered enrollment',
      value: renderValue((house?.date_offered_enrollment)),
    },
    {
      label: 'Screening location home',
      value: renderValue((house?.screening_location_home)),
    },
    {
      label: 'Viral load results on file',
      value: renderValue((house?.viral_load_results_on_file)),
    },
    {
      label: 'Children violence six months',
      value: renderValue((house?.children_violence_six_months)),
    },
    {
      label: ' Is the child caregiver an FSW?',
      value: renderValue((house?.is_the_child_caregiver_an_fsw)),
    },
    {
      label: 'Child ever experienced sexual violence',
      value: renderValue((house?.child_ever_experienced_sexual_violence)),
    },
    {
      label: 'Is biological mother of child living with HIV',
      value: renderValue((house?.is_biological_child_of_mother_living_with_hiv)),
    },
  ];


  return (
    <>
      <VersionBanner />
      <div style={{ padding: '20px' }}>
        <Title level={3}>Caregiver Primary Information</Title>
        <br />

        <Card
          bordered={true}
          title="Caregiver Details"
          style={{
            marginBottom: '20px',
            background: '#E6F7FF',
            border: '1px solid #91D5FF',
          }}
        >
          <Descriptions>
            {caregiverInfo &&
              caregiverInfo.map((item) => (
                <Descriptions.Item
                  label={item.label}
                  key={item.label}
                  style={{ color: '#1890FF' }}
                >
                  {item.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>

        <Card
          bordered={true}
          title="Household Details"
          style={{
            marginBottom: '20px',
            background: '#FFFBE6',
            border: '1px solid #FFE58F',
          }}
        >
          <Descriptions>
            {householdInfo &&
              householdInfo.map((item) => (
                <Descriptions.Item
                  label={item.label}
                  key={item.label}
                  style={{ color: '#FFC53D' }}
                >
                  {item.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>

        <Card
          bordered={true}
          title="Index VCA Details"
          style={{
            marginBottom: '20px',
            background: '#F6FFED',
            border: '1px solid #B7EB8F',
          }}
        >
          <Descriptions>
            {indexVcaInfo &&
              indexVcaInfo.map((item) => (
                <Descriptions.Item
                  label={item.label}
                  key={item.label}
                  style={{ color: '#52C41A' }}
                >
                  {item.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>
      </div>
    </>
  );
};

export default Screening;
