import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Table, Spin, Button } from 'antd';
import VersionBanner from "../../pages/versionBanner";

const Members = ({ household_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [people, setPeople] = useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      axios
        .post(
          `${process.env.REACT_APP_SERVER}/household/member`,
          {
            household_id,
          },
          options
        )
        .then((response) => {
          setPeople(response.data.data);
          setIsLoading(false);
        })
        .catch((error) => console.error("axios error: " + error));
    }, 3000);
  }, [household_id]);

  const capitalizeFirst = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const handleClick = (child) => {
    navigate("/child", { state: { child } });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: 'Gender',
      dataIndex: 'vca_gender',
      key: 'vca_gender',
      render: vca_gender => capitalizeFirst(vca_gender),
    },
    {
      title: 'D.O.B',
      dataIndex: 'birthdate',
      key: 'birthdate',
      render: birthdate => birthdate ? moment(birthdate).format('YYYY-MM-DD') : '',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, person) => (
        <Button
          type="primary"
          className="btn btn-info"
          style={{
            padding: "0.1rem 1rem",
            textTransform: "none",
            borderRadius: "8px",
            fontWeight: "500"
          }}
          onClick={() => handleClick(person)}
        >
          View Child
        </Button>
      ),
    },
  ];

  return (
    <>
      <VersionBanner />
      <div className="tab-pane" id="members">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "10vh",
            }}
          >
            <Spin size={35} color={"#808080"} loading={true} />
            <p>
              <b style={{ fontWeight: "500", textAlign: "center" }}>
                Please wait while we process your request...
              </b>
            </p>
          </div>
        ) : people?.length > 0 ? (
          <Table dataSource={people} columns={columns} rowKey="uid" />
        ) : (
          <div>There are currently no Family Members saved for this Household. Kindly revisit in future...</div>
        )}
      </div>
    </>
  );
};

export default Members;
