import Screening from "../components/householdForms/screening";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useContext, useState, useEffect, useMemo } from "react";
import AppContext from "../context/appContext";
import Members from "../components/householdForms/members";
import HouseholdVisits from "../components/householdForms/householdVisits";
import HouseholdCasePlans from "../components/householdForms/householdCasePlans";
import Caregiverassessment from "../components/householdForms/caregiverAssessment";
import GraduationInfo from "../components/householdForms/graduation";
import ServiceForms from "../components/householdForms/allHServiceReports";
import VersionBanner from "./versionBanner";

const Household = () => {

  const location = useLocation();
  const { house } = location.state;

  const navigate = useNavigate();

  //Display active household_id and caregiver_name on top of the page
  const { caregiver_name } = house;
  const household_id = location?.state?.house?.household_id;
  const { caseworker_name } = house;
  const { caseworker_phone } = house;
  const case_status = location?.state?.house?.case_status || "";
  const reason = location?.state?.house?.reason || "";
  const de_registration_date = location?.state?.house?.de_registration_date || "";

  const clients = useContext(AppContext);

  const households = clients?.householdData?.data;

  const house_id = households?.filter((h) => h?.household_id === household_id);

  const hhid = house_id && house_id[0]?.json?.attributes?.household_id;

  const token = localStorage.getItem("token");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  const handleFlagButtonClick = (e) => {
    e.preventDefault();
    navigate("/addcomment", {
      state: {
        valuesObject: {
          formname: "",
          hhid: house?.household_id ? house?.household_id : "Not Available",
          vcaid: house?.json?.attributes?.unique_id
            ? house?.json?.attributes?.unique_id
            : "Not Available",
          casephone: house?.phone ? house?.phone : "Not Available",
          casename: house?.caseworker_name
            ? house?.caseworker_name
            : "Not Available",
          facility: house?.facility ? house?.facility : "Not Available",
        },
      },
    });
  };

  return (
    <>
      <VersionBanner />
      <div className="col-lg col-md">
        <div className="row">

          <div className="col-3">
            <div className="card" style={{ backgroundColor: "#E6F7FF", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
              <div className="card-header">
                <div className="card-text">
                  <h5 style={{ fontWeight: "bold" }} className="card-title">
                    {household_id}
                  </h5>
                  <h6 className="card-category" style={{ color: "black" }}>Household ID : {caregiver_name}</h6>
                  <h6 className="card-category" style={{ color: "black" }}>VCA ID : {caseworker_name}</h6>
                  <h6 className="card-category" style={{ color: "black" }}>Age : {caseworker_phone}</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card" style={{ backgroundColor: "#A9DFBF", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
              <div className="card-header">
                <div className="card-text">
                  <h5 style={{ fontWeight: "bold" }} className="card-title">
                    Graduation Information
                  </h5>
                  <h6 className="card-category" style={{ color: "black" }}>Case status: {case_status || "N/A"}</h6>
                  <h6 className="card-category" style={{ color: "black" }}>Reason: {reason || "N/A"}</h6>
                  <h6 className="card-category" style={{ color: "black" }}>De-registration Date: {de_registration_date || "N/A"}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header card-header-tabs card-header-info">
            <div className="nav-tabs-navigation">
              <div className="nav-tabs-wrapper">
                <span className="nav-tabs-title">Forms:</span>
                <ul className="nav nav-tabs" data-tabs="tabs">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      href="#screening"
                      data-toggle="tab"
                    >
                      <i className="material-icons">houses</i> Household Screening
                      <div className="ripple-container" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#members" data-toggle="tab">
                      <i className="material-icons">people</i> Family Members
                      <div className="ripple-container" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#visits" data-toggle="tab">
                      <i className="material-icons">arrow_right</i> Visits
                      <div className="ripple-container" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#caseplans" data-toggle="tab">
                      <i className="material-icons">article</i> Case Plans
                      <div className="ripple-container" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#hivassessment"
                      data-toggle="tab"
                    >
                      <i className="material-icons">healing</i> Household Assessment
                      <div className="ripple-container" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#graduation" data-toggle="tab">
                      <i className="material-icons">school</i> Graduation
                      <div className="ripple-container" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#services" data-toggle="tab">
                      <i className="material-icons">local_hospital</i> All
                      Services
                      <div className="ripple-container" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#flag"
                      onClick={handleFlagButtonClick}
                    >
                      <i className="material-icons">flag</i> Flag this record
                      <div className="ripple-container" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="tab-content">
              <div className="tab-pane active" id="screening">
                <Screening house={house} />
              </div>
              <Members household_id={house.household_id} />
              <HouseholdVisits />
              <HouseholdCasePlans />
              <Caregiverassessment />
              <GraduationInfo />
              <ServiceForms />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Household;
