import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { Table, Spin, Alert, 
  Input, Button, Tooltip, Breadcrumb,
   Select } from "antd";
import {
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../context/appContext";
import { Chip, debounce } from "@mui/material";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import VersionBanner from "./versionBanner";

const VcaServices = () => {
  const clients = useContext(AppContext);
  const token = localStorage.getItem("token");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [fetchServices, setServices] = useState([]);
  const [genderFilter, setGenderFilter] = useState(""); 

  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [exportCSVInProgress, setCSVExportInProgress] = useState(false);
  const [exportPDFInProgress, setPDFExportInProgress] = useState(false);

  //If a field on a record is empty, render
  // 'Not Provided' instead of empty string
  const renderText = (text) => {
    return text === null ? "Not Provided" : text;
  };

  const columns = [
    {
      title: "VCA ID",
      dataIndex: "vcaid",
      key: "vcaid",
      render: renderText,
      width: 200,
    },
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
      render: renderText,
      width: 200,
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
      render: renderText,
      width: 200,
    },
    {
      title: "VCA Gender",
      dataIndex: "vca_gender",
      key: "vca_gender",
      render: renderText,
      width: 200,
    },
    {
      title: "Facility",
      dataIndex: "facility",
      key: "facility",
      render: renderText,
      width: 200,
    },
    {
      title: "Service Date",
      dataIndex: "service_date",
      key: "service_date",
      render: renderText,
      width: 200,
    },
    {
      title: "Date Last VL",
      dataIndex: "date_last_vl",
      key: "date_last_vl",
      render: renderText,
      width: 200,
    },
    {
      title: "HIV Status",
      dataIndex: "is_hiv_positive",
      key: "is_hiv_positive",
      render: renderText,
      width: 200,
    },
    {
      title: "VL Last Result",
      dataIndex: "vl_last_result",
      key: "vl_last_result",
      render: renderText,
      width: 200,
    },
    {
      title: "HIV Services",
      dataIndex: "hiv_services",
      key: "hiv_services",
      width: 250,
      render: renderText,
    },
    {
      title: "Other HIV Services",
      dataIndex: "other_hiv_services",
      key: "other_hiv_services",
      width: 250,
      render: renderText,
    },
    {
      title: "Health Services",
      dataIndex: "health_services",
      key: "health_services",
      width: 250,
      render: renderText,
    },
    {
      title: "Other Health Services",
      dataIndex: "other_health_services",
      key: "other_health_services",
      width: 250,
      render: renderText,
    },
    {
      title: "Schooled Services",
      dataIndex: "schooled_services",
      key: "schooled_services",
      width: 250,
      render: renderText,
    },
    {
      title: "Other Schooled Services",
      dataIndex: "other_schooled_services",
      key: "other_schooled_services",
      width: 250,
      render: renderText,
    },
    {
      title: "Safe Services",
      dataIndex: "safe_services",
      key: "safe_services",
      width: 250,
      render: renderText,
    },
    {
      title: "Other Safe Services",
      dataIndex: "other_safe_services",
      key: "other_safe_services",
      width: 250,
      render: renderText,
    },
    {
      title: "Stable Services",
      dataIndex: "stable_services",
      key: "stable_services",
      width: 250,
      render: renderText,
    },
    {
      title: "Other Stable Services",
      dataIndex: "other_stable_services",
      key: "other_stable_services",
      width: 250,
      render: renderText,
    },
  ];

  const navigate = useNavigate();

  const handleClick = (service) => {
    navigate("/child", { state: { service } });
  };

  const handleAdvancedSearchClick = () => {
    navigate("/advanced-search");
  };

    //enable filter by gender on the table
    const { Option } = Select; 
    
    const handleSearchChange = (e) => {
      setSearchInput(e.target.value);
    };
  
    const handleGenderFilterChange = (value) => {
      setGenderFilter(value);
    };
  
  // debounce search input
  const debouncedHandleSearch = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedHandleSearch(searchInput);
  }, [searchInput, debouncedHandleSearch]);

  const districts = [
    "Kabwe",
    "Kapiri Mposhi",
    "Mkushi",
    "Chibombo",
    "Chisamba",
    "Chikankata",
    "Mumbwa",
    "Sinazongwe",
    "Kalomo",
    "Monze",
    "Namwala",
    "Choma",
    "Mazabuka",
    "Livingstone",
    "Serenje",
    "Kazungula",
  ];

  const fetchAllServices = useCallback(async () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SERVER}/child/district/vcaservices/${
          clients.district
        }?page=${currentPage}&itemsPerPage=${itemsPerPage}&globalSearch=${encodeURIComponent(globalSearch)}&gender=${genderFilter}`, // Add gender filter here`,
        options
      )
      .then((res) => {
        setServices(res.data.data);
        setIsDataReadyForExport(true);
        setItemsPerPage(itemsPerPage);
        setTotalPages(res.data.totalPages);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage =
          err.response && err.response.data && err.response.data.message
            ? `Error: ${err.response.data.message}. Please try again.`
            : "An error occurred while fetching vca services data. Please try again.";
        setError(errorMessage);
      });
  }, [clients.district, currentPage, globalSearch, genderFilter,
    itemsPerPage, options]);

  useEffect(() => {
    if (!firstLoad) {
      fetchAllServices(currentPage);
    } else {
      setFirstLoad(false);
    }
  }, [currentPage, fetchAllServices, firstLoad, globalSearch]);

  const displayServices = Array.isArray(fetchServices)
    ? fetchServices
        .map((service) => {
          if (!service) {
            return null;
          }
          const {
            vcaid,
            firstname,
            lastname,
            date_last_vl,
            vl_last_result,
            service_date,
            is_hiv_positive,
            facility,
            district,
            hiv_services,
            vca_gender,
            other_hiv_services,
            health_services,
            other_health_services,
            schooled_services,
            other_schooled_services,
            safe_services,
            other_safe_services,
            stable_services,
            other_stable_services,
          } = service;

          const health_services_str =
            typeof health_services === "string"
              ? health_services.replace(/[\[\]"]+/g, "")
              : "";

          return {
            vcaid,
            firstname,
            lastname,
            date_last_vl,
            vl_last_result,
            service_date: moment(service_date).format("YYYY-MM-DD"),
            is_hiv_positive,
            facility,
            district,
            vca_gender,
            other_hiv_services,
            hiv_services,
            health_services: health_services_str,
            other_health_services,
            schooled_services,
            other_schooled_services,
            safe_services,
            other_safe_services,
            stable_services,
            other_stable_services,
            service, // Add the entire service object and
            // pass its state to the Screening VCA Household Index page
          };
        })
        .filter((item) => item !== null)
    : [];

  // handle window resize for tablet and mobile responsiveness
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const fetchAllDataForExport = async () => {
    let allData = [];
    let page = 1;
    let totalPages = 1;

    while (page <= totalPages) {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER}/child/district/vcaservices/${
          clients.district
        }?page=${currentPage}&itemsPerPage=${itemsPerPage}&globalSearch=${encodeURIComponent(globalSearch)}&gender=${genderFilter}`,
        options
      );
      allData = allData.concat(res.data.data);
      totalPages = res.data.totalPages;
      page++;
    }

    return allData;
  };

  // Export to CSV
  const exportToCSV = (data) => {
    const headers = ["Data"];
    const csvData = [headers].concat(
      data.map((house) => [JSON.stringify(house)])
    );

    const csvContent = Papa.unparse(csvData);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "all_vca_services_data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // export to PDF
  const exportToPDF = (data) => {
    const headers = ["Data"];
    const body = data.map((row) => [JSON.stringify(row)]);

    const doc = new jsPDF("l", "mm", "a4");
    doc.autoTable({
      head: [headers],
      body: body,
      theme: "striped",
      styles: { fontSize: 8 },
    });
    doc.save("all_vca_services_data.pdf");
  };

  const handleExportToCSV = async () => {
    setCSVExportInProgress(true);
    const allData = await fetchAllDataForExport();
    exportToCSV(allData);
    setCSVExportInProgress(false);
  };

  const handleExportToPDF = async () => {
    setPDFExportInProgress(true);
    const allData = await fetchAllDataForExport();
    exportToPDF(allData);
    setPDFExportInProgress(false);
  };

  return (
    <>
      <VersionBanner /> 
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header-info">
                  <div className="card-icon">
                    <i className="material-icons">local_hospital</i>
                  </div>
                  <h4 className="card-title" style={{ fontWeight: "500" }}>
                    {districts[clients.district - 1]} District All VCA Services
                  </h4>
                </div>
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: windowWidth <= 991 ? "column" : "row",
                      alignItems: "center",
                    }}
                  >
                    {/*breadcrumb navigation*/}
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <Chip
                          label="Households Page"
                          variant="outlined"
                          color="primary"
                          style={{
                            backgroundColor: "#e0e0e0",
                            color: "black",
                            fontWeight: "500",
                            marginLeft: "1%",
                          }}
                        ></Chip>
                        &nbsp;&nbsp;&nbsp;
                      </Breadcrumb.Item>
                      <br />
                      <br />
                      <Breadcrumb.Item>
                        <h1
                          style={{
                            fontSize: "17px",
                            color: "black",
                            fontWeight: "500",
                          }}
                        >
                          {" "}
                          Navigate to:
                        </h1>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/children">
                          <Chip
                            label="VCA`s"
                            variant="outlined"
                            color="primary"
                            clickable
                            style={{
                              backgroundColor: "#e0e0e0",
                              color: "black",
                              fontWeight: "500",
                            }}
                          ></Chip>
                        </Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/mothers">
                          <Chip
                            label="Mothers"
                            variant="outlined"
                            color="primary"
                            clickable
                            style={{
                              backgroundColor: "#e0e0e0",
                              color: "black",
                              fontWeight: "500",
                            }}
                          ></Chip>
                        </Link>
                      </Breadcrumb.Item>
                      <br />
                      <br />
                      <Breadcrumb.Item>
                        <Tooltip title="Export to CSV">
                          <Button
                            disabled={!isDataReadyForExport || exportCSVInProgress}
                            size="large"
                            style={{
                              marginRight: 8,
                              background:
                                "linear-gradient(45deg, #28A745 30%, #FF8E53 90%)",
                              color: "white",
                              boxShadow: "0 3px 5px 2px rgba(40, 167, 69, 0.3)",
                              fontWeight: "500",
                            }}
                            onClick={handleExportToCSV}
                          >
                            {(exportCSVInProgress) && (
                              <Spin indicator={<SyncOutlined spin />} style={{ marginRight: 8, color: "white" }} />
                            )}
                            {exportCSVInProgress ? "Preparing download..." : "Export to CSV"}
                          </Button>
                        </Tooltip>
                      </Breadcrumb.Item>
                    
                      <Breadcrumb.Item>
                        <Button
                          onClick={handleAdvancedSearchClick}
                          size="large"
                          style={{
                            background:
                              "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                            color: "white",
                            boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                            display: "block",
                            margin: "0 auto",
                            fontWeight: "500",
                          }}
                        >
                          Advanced Search
                        </Button>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                    <div
                      className="toolbar"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: windowWidth <= 991 ? "column" : "row",
                      }}
                    >
                      <Input
                        placeholder="You can search here..."
                        value={searchInput}
                        onChange={handleSearchChange}
                        prefix={
                          <span>
                            <SearchOutlined style={{ color: "#11B8CC" }} />
                          </span>
                        }
                        style={{
                          marginLeft: windowWidth <= 991 ? 0 : 150,
                          marginTop: windowWidth <= 991 ? 20 : 0,
                          width: windowWidth <= 991 ? "100%" : 300,
                        }}
                        size="large"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="toolbar"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div></div>
                  </div>
                  <div className="toolbar">
                    <div className="toolbar">
                      {searchInput && (
                        <>
                           <span style={{ color: "black", marginRight: 8, fontWeight: "500" }}>
                          <b>You Searched For:</b>
                          </span>
                          <Chip label={searchInput} style={{ color: "black", fontWeight:"500" }} />
                        </>
                      )}
                    </div>
                    <br />
                    <div
                      className="toolbar"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    ></div>
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <Spin />
                        <p>
                          <b style={{ fontWeight: "500" }}>
                            Please wait while we process your request...
                          </b>
                        </p>
                      </div>
                    ) : error ? (
                      <Alert message={error} type="error" />
                    ) : (
                      <Table
                        columns={columns}
                        dataSource={displayServices}
                        scroll={{ x: true }}
                        pageSizeOptions={[10, 20, 50, 100]}
                        pagination={{
                          pageSize: itemsPerPage,
                          showQuickJumper: true,
                          showSizeChanger: true,
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} Total Services`,
                          onShowSizeChange: (current, size) =>
                            setItemsPerPage(size), // Change this line
                          total: totalPages * itemsPerPage,
                          current: currentPage,
                          onChange: (page) => setCurrentPage(page),
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(VcaServices);
