import React, { useEffect, useMemo, useState } from "react";
import {
    Table, Spin, Alert, Input, Button, Tooltip,
    Breadcrumb, Select, Popover, message, Modal
} from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { Chip } from "@mui/material";
import {
    SearchOutlined, DeleteOutlined, UserAddOutlined,
} from "@ant-design/icons";
import "jspdf-autotable";
import VersionBanner from "./versionBanner";

const DeletedUsers = () => {

    const token = localStorage.getItem("token");
    const isAdmin = parseInt(localStorage.getItem("isAdmin"), 10);

    const options = useMemo(
        () => ({
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }),
        [token]
    );

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(0);
    const [error, setError] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [roleFilter, setRoleFilter] = useState("");
    const [visible, setVisible] = useState(false);
    const [deletingUserId, setDeletingUserId] = useState(null);

    //Enable multiple districts select on the add new user modal
    const districtsRoles = [
        { label: "Kabwe", value: 1 },
        { label: "Kapiri Mposhi", value: 2 },
        { label: "Mkushi", value: 3 },
        { label: "Chibombo", value: 4 },
        { label: "Chisamba", value: 5 },
        { label: "Chikankata", value: 6 },
        { label: "Mumbwa", value: 7 },
        { label: "Sinazongwe", value: 8 },
        { label: "Kalomo", value: 9 },
        { label: "Monze", value: 10 },
        { label: "Namwala", value: 11 },
        { label: "Choma", value: 12 },
        { label: "Mazabuka", value: 13 },
        { label: "Livingstone", value: 14 },
    ];

    const columns = [
        {
            title: "First Name",
            dataIndex: "first_name",
            key: "first_name",
        },
        {
            title: "Last Name",
            dataIndex: "last_name",
            key: "last_name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "District (s)",
            dataIndex: "districts",
            key: "districts",
            render: (districts, record) => {
                const arr = record?.districts?.split(",")?.map(Number);
                const districtLabels = arr?.map((x) => {
                    return districtsRoles?.find(district => district?.value === x)?.label;
                }).filter(label => label); // Filter out any undefined or null labels

                const visibleItems = 4;
                const hiddenItems = districtLabels?.slice(visibleItems);

                return (
                    <div>
                        {districtLabels?.slice(0, visibleItems)?.join(', ')}
                        {hiddenItems?.length > 0 && (
                            <Popover
                                content={
                                    <div>
                                        {hiddenItems?.map((item, index) => (
                                            <div key={index}>{item}</div>
                                        ))}
                                    </div>
                                }
                                title="Hidden Districts"
                                trigger="click"
                            >
                                <Button type="link" size="small">View Full List...</Button>
                            </Popover>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Added By",
            dataIndex: "addedBy",
            key: "addedBy",
        },
        {
            title: "User Role",
            dataIndex: "isAdmin",
            key: "isAdmin",
            render: (isAdmin) => {
                if (isAdmin === 1) {
                    return "Super Administrator";
                } else if (isAdmin === 2) {
                    return "Administrator";
                } else if (isAdmin === 0) {
                    return "Data Quality Assurance User";
                } else {
                    return "Unknown";
                }
            },
        },
        {
            title: "Deleted By",
            dataIndex: "deleted_by_is_admin",
            key: "deleted_by_is_admin",
            render: (deleted_by_is_admin) => {
                if (deleted_by_is_admin === 1) {
                    return "Super Administrator";
                } else if (deleted_by_is_admin === 2) {
                    return "Administrator";
                } else if (deleted_by_is_admin === 0) {
                    return "Data Quality Assurance User";
                } else {
                    return "Unknown";
                }
            },
        },
        {
            title: "Restore User",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip>
                        <Button type="primary" primary style={{ marginLeft: "10px" }}
                            title="Restore User"
                            onClick={() => {
                                restoreUser(record.key);
                            }}
                        >
                            <UserAddOutlined />
                        </Button>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip>
                        <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => {
                                setVisible(true);
                                setDeletingUserId(record.key);
                            }}
                        />
                        <Modal
                            title="Warning!"
                            open={visible}
                            onOk={() => permanentlyDeleteUser(deletingUserId)}
                            okButtonProps={{ danger: true }}
                            onCancel={() => setVisible(false)}
                            type="primary"
                            danger
                        >
                            <p>
                                This action is irreversible. Are you sure you want to permanently delete this user? Click 'OK' to proceed.
                            </p>
                        </Modal>
                    </Tooltip>
                </div>
            ),
        }
    ];

    //enable filter by gender on the table
    const { Option } = Select;

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleRoleFilterChange = (value) => {
        setRoleFilter(value);
    };

    const getDeletedUsersList = async (searchQuery, roleFilter) => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER}/user/deleted-users?search=${searchQuery}&isAdmin=${roleFilter}&deleted_by_is_admin=${isAdmin}`,
                options
            );
            setLoading(false);
            setUsers(response.data);
        } catch (err) {
            setLoading(false);
            const errorMessage =
                err.response && err.response.data && err.response.data.message
                    ? `Error: ${err.response.data.message}. Please try again.`
                    : "An error occurred while fetching Data Quality Assurance Platform (DQA) Deleted Users. Please try again.";
            setError(errorMessage);
        }
    };

    useEffect(() => {
        getDeletedUsersList(searchInput, roleFilter);
    }, [searchInput, roleFilter])


    // Restore a deleted user
    const restoreUser = async (userId) => {
        setLoading(true);
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_SERVER}/user/restore/${userId}`,
                {},
                options
            );
            setLoading(false);
            message.success(`User Restored Successfully.`);
            // Refresh the deleted users list after successful restoration
            getDeletedUsersList(searchInput, roleFilter);
        } catch (err) {
            setLoading(false);
            const errorMessage =
                err.response && err.response.data && err.response.data.message
                    ? `Error: ${err.response.data.message}. Please try again.`
                    : "An error occurred while restoring the user. Please try again.";
            setError(errorMessage);
        }
    };


    // Permanently delete a user
    const permanentlyDeleteUser = async (userId) => {
        setLoading(true);
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER}/user/permanently-delete/${userId}`,
                options
            );
            setLoading(false);
            message.success(`User permanently deleted.`);
            // Refresh the deleted users list after successful deletion
            getDeletedUsersList(searchInput, roleFilter);

            setVisible(false);
        } catch (err) {
            setLoading(false);
            const errorMessage =
                err.response && err.response.data && err.response.data.message
                    ? `Error: ${err.response.data.message}. Please try again.`
                    : "An error occurred while permanently deleting the user. Please try again.";
            setError(errorMessage);
        }
    };


    const displayDeletedUserData = Array.isArray(users)
        ? users
            .map((_user_) => {
                if (!_user_) {
                    return null;
                }
                const {
                    id,
                    first_name,
                    last_name,
                    email,
                    phone,
                    role,
                    addedBy,
                    isAdmin,
                    deleted_by_is_admin,
                } = _user_;

                return {
                    key: id,
                    id,
                    first_name,
                    last_name,
                    email,
                    phone,
                    districts: role, // Use 'role' for districts
                    addedBy, // Use 'added_by' as it is provided in the response data
                    isAdmin,
                    deleted_by_is_admin,
                };
            })
            .filter((_item_) => _item_ !== null)
        : [];

    /*objects label and value are not valid react children,
   to render the districts on the user management table 
  component use an array instead*/

    const districts = [
        "Kabwe",
        "Kapiri Mposhi",
        "Mkushi",
        "Chibombo",
        "Chisamba",
        "Chikankata",
        "Mumbwa",
        "Sinazongwe",
        "Kalomo",
        "Monze",
        "Namwala",
        "Choma",
        "Mazabuka",
        "Livingstone",
    ];

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <>
            <VersionBanner />
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header-danger">
                                    <div className="card-icon">
                                        <i className="material-icons">person</i>
                                    </div>
                                    <h4 className="card-title" style={{ fontWeight: "500" }}>
                                        Recycle Bin
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: windowWidth <= 991 ? 'column' : 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Breadcrumb>
                                            <Breadcrumb.Item>
                                                <Chip
                                                    label="Recycle Bin"
                                                    variant="outlined"
                                                    color="primary"
                                                    style={{
                                                        backgroundColor: '#e0e0e0',
                                                        color: 'black', fontWeight: "500", marginLeft: "1%"
                                                    }}
                                                >
                                                </Chip>
                                                &nbsp;&nbsp;&nbsp;
                                            </Breadcrumb.Item>
                                            <br /><br />
                                            <Breadcrumb.Item>
                                                <h1 style={{ fontSize: '17px', color: 'black', fontWeight: "500" }}> Navigate to:</h1>
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item>
                                                <Link to="/users">
                                                    <Chip
                                                        label="Users Management Portal"
                                                        variant="outlined"
                                                        color="primary"
                                                        clickable
                                                        style={{
                                                            backgroundColor: '#e0e0e0',
                                                            color: 'black', fontWeight: "500"
                                                        }}
                                                    >
                                                    </Chip>
                                                </Link>
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item>
                                                <Link to="/households">
                                                    <Chip
                                                        label="Households"
                                                        variant="outlined"
                                                        color="primary"
                                                        clickable
                                                        style={{
                                                            backgroundColor: '#e0e0e0',
                                                            color: 'black', fontWeight: "500"
                                                        }}
                                                    >
                                                    </Chip>
                                                </Link>
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item>
                                                <Link to="/children">
                                                    <Chip
                                                        label="VCA`s"
                                                        variant="outlined"
                                                        color="primary"
                                                        clickable
                                                        style={{
                                                            backgroundColor: '#e0e0e0',
                                                            color: 'black', fontWeight: "500"
                                                        }}
                                                    >
                                                    </Chip>
                                                </Link>
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item>
                                                <Link to="/mothers">
                                                    <Chip
                                                        label="Mothers"
                                                        variant="outlined"
                                                        color="primary"
                                                        clickable
                                                        style={{
                                                            backgroundColor: '#e0e0e0',
                                                            color: 'black', fontWeight: "500"
                                                        }}
                                                    >
                                                    </Chip>
                                                </Link>
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </div>
                                    <div className="card-body">
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                flexDirection: windowWidth <= 991 ? 'column' : 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Breadcrumb>
                                                <Breadcrumb.Item>
                                                    <h1 style={{ fontSize: '17px', color: 'black', fontWeight: "500" }}>Filter by:</h1>
                                                    <Select value={roleFilter} onChange={handleRoleFilterChange} style={{ width: 220 }}>
                                                        <Option value="">All</Option>
                                                        {isAdmin === 1 && (
                                                            <Option value="1">Super Administrators</Option>
                                                        )}
                                                        <Option value="2">Administrators</Option>
                                                        <Option value="0">Data Quality Assurance Users</Option>
                                                    </Select>
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                            <div
                                                className="toolbar"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    flexDirection: windowWidth <= 991 ? 'column' : 'row',
                                                }}
                                            >
                                                <Input
                                                    placeholder="You can search here..."
                                                    value={searchInput}
                                                    onChange={handleSearchChange}
                                                    prefix={<span><SearchOutlined style={{ color: '#11B8CC' }} /></span>}
                                                    style={{
                                                        marginLeft: windowWidth <= 991 ? 0 : 150,
                                                        marginTop: windowWidth <= 991 ? 20 : 0,
                                                        width: windowWidth <= 991 ? '100%' : 300,
                                                    }}
                                                    size="large"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="toolbar" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div></div>
                                        </div>
                                        <div className="toolbar">
                                            <div className="toolbar">
                                                {searchInput && (
                                                    <>
                                                        <span style={{ color: "black", marginRight: 8, fontWeight: "500" }}>
                                                            <b>You Searched For:</b></span>
                                                        <Chip label={searchInput} style={{ color: 'grey' }} />
                                                    </>
                                                )}
                                            </div>
                                            <br />
                                            <div className="toolbar" style={{ display: 'flex', justifyContent: 'flex-end' }}></div>
                                            {loading ? (
                                                <div style={{
                                                    display: 'flex', justifyContent: 'center',
                                                    alignItems: 'center', flexDirection: 'column', height:
                                                        '100%'
                                                }}>
                                                    <Spin />
                                                    <p>Please wait while we process your request...</p>
                                                </div>
                                            ) : error ? (
                                                <Alert message={error} type="error" />
                                            ) : (
                                                <Table columns={columns} dataSource={displayDeletedUserData} scroll={{ x: 0.1 }} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DeletedUsers;