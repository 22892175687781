import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import swal from "sweetalert";
import VersionBanner from "./versionBanner";

const Addcomment = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const valuesObject = location?.state?.valuesObject || {};

  const {vcaid, hhid, casephone, casename, facility } = valuesObject;

  const [comment, setComment] = useState();
  const [forms, setForms] = useState([]);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const [myhhid, setHhid] = useState();
  const [myvcaid, setVcaid] = useState();
  const [mycasename, setCasename] = useState();
  const [mycasephone, setCasephone] = useState();
  const [myfacility, setFacility] = useState();

  useEffect(() => {
    setHhid(hhid);
    setVcaid(vcaid);
    setCasephone(casephone);
    setCasename(casename);
    setFacility(facility);
  }, []);

  const handleClick = () => {
    navigate(-1);
  };

  const token = localStorage.getItem("token");
  const user = localStorage.getItem("username");
  const user_email = localStorage.getItem("email");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_SERVER}/user/add-flag`,
        {
          formname: forms,
          hhid: myhhid,
          vcaid: myvcaid,
          casename: mycasename,
          casephone: mycasephone,
          facility: myfacility,
          comment: comment,
          user: user,
          user_email: user_email,
        },
        options
      )
      .then((res) => {
        if (res.data.status === "success") {
          swal({
            text: "Comment Submitted",
            icon: "success",
          }).then(() => {
            navigate("/comments");
          });
        }
      })
      .catch((err) => {
        console.log(err);
        swal({
          text: "An Error occurred. Please contact support",
          icon: "warning",
        });
      });
  };

  
  return (
    <>
    <VersionBanner />
    <div className="col-md-7">
      <div className="card ">
      <div className="card-header-info">
                  <div className="card-icon">
                    <i className="material-icons">flag</i>
                  </div>
                  <h4 className="card-title">
                  Feedback</h4>
                </div>
        <div className="card-body ">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <h3 className="card-title">Forms</h3>
              <textarea
              placeholder="Add Form Names here..."
              type="text"
              className="form-control"
              onChange={(e) => setForms(e.target.value)}
              value={forms}
              required
            />
            </div>
            <div className="form-group">
              <label className="label">Household ID</label>
              <input
                type="text"
                className="form-control"
                value={valuesObject.hhid}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="examplePass" className="label">
                VCA ID
              </label>
              <input
                type="text"
                className="form-control"
                value={vcaid}
                disabled
              />
            </div>
            <div className="form-group">
              <label className="label">Caseworker Phone</label>
              <input
                type="text"
                className="form-control"
                value={valuesObject.casephone}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="examplePass" className="label">
                Caseworker Name
              </label>
              <input
                type="text"
                className="form-control"
                value={valuesObject.casename}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="examplePass" className="label">
                Facility
              </label>
              <input
                type="text"
                className="form-control"
                value={valuesObject.facility}
                disabled
              />
            </div>
            <textarea
              placeholder="Add comment here..."
              type="text"
              className="form-control"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              required
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "15px",
              }}
            >
              <button type="submit" className="btn btn-fill btn-success">
                Submit
              </button>
              <button
                type="button"
                onClick={handleBackButtonClick}
                className="btn btn-fill btn-success"
              >
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  );
};

export default Addcomment;