import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Chip, debounce } from "@mui/material";
import {
  Table,
  Spin,
  Button,
  Tooltip,
  Breadcrumb,
} from "antd";
import {
  FileExcelFilled,
} from "@ant-design/icons";
import VersionBanner from "../../pages/versionBanner";

const HouseholdVisits = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedHouseholdVisits, setfetchedHouseholdVisits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const location = useLocation();
  const { house } = location?.state;

  const token = localStorage.getItem("token");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  //If a field on a record is empty, render
  // 'Not Provided' instead of empty string
  const renderText = (text) => {
    return text === null || text === undefined ||
     text === "" ? "Not Provided" : text;
  };

  const columns = [
    {
      title: "Visit Date",
      dataIndex: "visit_date",
      key: "visit_date",
      width: 300,
       render: (text) => renderText(text, 'visit_date'),
    },
    {
      title: "Caregiver HIV Status",
      dataIndex: "caregiver_hiv_status",
      key: "caregiver_hiv_status",
      width: 300,
       render: (text) => renderText(text, 'caregiver_hiv_status'),
    },
    {
      title: "Caregiver ART",
      dataIndex: "caregiver_art",
      key: "caregiver_art",
      width: 300,
       render: (text) => renderText(text, 'caregiver_art'),
    },
    {
      title: "Clinical Care",
      dataIndex: "clinical_care",
      key: "clinical_care",
      width: 300,
       render: (text) => renderText(text, 'clinical_care'),
    },
    {
      title: "Date ART",
      dataIndex: "date_art",
      key: "date_art",
      width: 300,
       render: (text) => renderText(text, 'date_art'),
    },
    {
      title: "ART Appointment",
      dataIndex: "art_appointment",
      key: "art_appointment",
      width: 300,
       render: (text) => renderText(text, 'art_appointment'),
    },
    {
      title: "Counselling",
      dataIndex: "counselling",
      key: "counselling",
      width: 300,
       render: (text) => renderText(text, 'counselling'),
    },
    {
      title: "ART Medication",
      dataIndex: "art_medication",
      key: "art_medication",
      width: 300,
       render: (text) => renderText(text, 'art_medication'),
    },
    {
      title: "MMD",
      dataIndex: "mmd",
      key: "mmd",
      width: 300,
       render: (text) => renderText(text, ''),
    },
    {
      title: "MMD Months",
      dataIndex: "mmd_months",
      key: "mmd_months",
      width: 300,
       render: (text) => renderText(text, 'mmd_months'),
    },
    {
      title: "Six Months",
      dataIndex: "six_months",
      key: "six_months",
      width: 300,
       render: (text) => renderText(text, 'six_months'),
    },
    {
      title: "VL Last Result",
      dataIndex: "vl_last_result",
      key: "vl_last_result",
      width: 300,
       render: (text) => renderText(text, 'vl_last_result'),
    },
    {
      title: "Date HIV",
      dataIndex: "date_hiv",
      key: "date_hiv",
      width: 300,
       render: (text) => renderText(text, 'date_hiv'),
    },
    {
      title: "Visit HIV Test",
      dataIndex: "visit_hiv_test",
      key: "visit_hiv_test",
      width: 300,
       render: (text) => renderText(text, 'visit_hiv_test'),
    },
    {
      title: "Referred for Testing",
      dataIndex: "referred_for_testing",
      key: "referred_for_testing",
      width: 300,
       render: (text) => renderText(text, 'referred_for_testing'),
    },
    {
      title: "HIV Risk",
      dataIndex: "hiv_risk",
      key: "hiv_risk",
      width: 300,
       render: (text) => renderText(text, 'hiv_risk'),
    },
    {
      title: "HIV Assessment",
      dataIndex: "hiv_assessment",
      key: "hiv_assessment",
      width: 300,
       render: (text) => renderText('text, hiv_assessment'),
    },
    {
      title: "Prevention Support",
      dataIndex: "prevention_support",
      key: "prevention_support",
      width: 300,
       render: (text) => renderText(text, 'prevention_support'),
    },
    {
      title: "Referred Facility",
      dataIndex: "referred_facility",
      key: "referred_facility",
      width: 300,
       render: (text) => renderText(text, 'referred_facility'),
    },
    {
      title: "Number of Children",
      dataIndex: "number_children",
      key: "number_children",
      width: 300,
       render: (text) => renderText(text, 'number_children'),
    },
    {
      title: "HIV Awareness Status",
      dataIndex: "hiv_awareness_status",
      key: "hiv_awareness_status",
      width: 300,
       render: (text) => renderText(text, 'hiv_awareness_status'),
    },
    {
      title: "Caregiver Not Aware",
      dataIndex: "caregiver_not_aware",
      key: "caregiver_not_aware",
      width: 300,
       render: (text) => renderText(text, 'caregiver_not_aware'),
    },
    {
      title: "HIV Test Referral",
      dataIndex: "hiv_test_referral",
      key: "hiv_test_referral",
      width: 300,
       render: (text) => renderText(text, 'hiv_test_referral'),
    },
    {
      title: "School Fees",
      dataIndex: "school_fees",
      key: "school_fees",
      width: 300,
       render: (text) => renderText(text, 'school_fees'),
    },
    {
      title: "Unpaid School Fees",
      dataIndex: "unpaid_school_fees",
      key: "unpaid_school_fees",
      width: 300,
       render: (text) => renderText(text, 'unpaid_school_fees'),
    },
    {
      title: "Linked Economic",
      dataIndex: "linked_economic",
      key: "linked_economic",
      width: 300,
       render: (text) => renderText(text, 'linked_economic'),
    },
    {
      title: "Referred MCDSS",
      dataIndex: "referred_mcdss",
      key: "referred_mcdss",
      width: 300,
       render: (text) => renderText(text, 'referred_mcdss'),
    },
    {
      title: "Income Source",
      dataIndex: "income_source",
      key: "income_source",
      width: 300,
       render: (text) => renderText(text, 'income_source'),
    },
    {
      title: "List Source",
      dataIndex: "list_source",
      key: "list_source",
      width: 300,
       render: (text) => renderText(text, 'list_source'),
    },
    {
      title: "Bills Associated",
      dataIndex: "bills_associated",
      key: "bills_associated",
      width: 300,
       render: (text) => renderText(text, 'bills_associated'),
    },
    {
      title: "Barriers Challenges",
      dataIndex: "barriers_challenges",
      key: "barriers_challenges",
      width: 300,
       render: (text) => renderText(text, 'barriers_challenges'),
    },
    {
      title: "Economic Strengthening",
      dataIndex: "economic_strengthening",
      key: "economic_strengthening",
      width: 300,
       render: (text) => renderText(text, 'economic_strengthening'),
    },
    {
      title: "Social Cash",
      dataIndex: "social_cash",
      key: "social_cash",
      width: 300,
       render: (text) => renderText(text, 'social_cash'),
    },
    {
      title: "Income Source Medical",
      dataIndex: "income_source_medical",
      key: "income_source_medical",
      width: 300,
       render: (text) => renderText(text, 'income_source_medical'),
    },
    {
      title: "List Source Medical",
      dataIndex: "list_source_medical",
      key: "list_source_medical",
      width: 300,
       render: (text) => renderText(text, 'list_source_medical'),
    },
    {
      title: "Case Worker",
      dataIndex: "case_worker",
      key: "case_worker",
      width: 300,
       render: (text) => renderText(text, 'case_worker'),
    },
    {
      title: "Caseworker Date Signed",
      dataIndex: "caseworker_date_signed",
      key: "caseworker_date_signed",
      width: 300,
       render: (text) => renderText(text, 'caseworker_date_signed'),
    },
    {
      title: "Case Manager",
      dataIndex: "case_manager",
      key: "case_manager",
      width: 300,
       render: (text) => renderText(text, 'case_manager'),
    },
    {
      title: "Manager Date Signed",
      dataIndex: "manager_date_signed",
      key: "manager_date_signed",
      width: 300,
       render: (text) => renderText(text, 'manager_date_signed'),
    },
    {
      title: "School Administration Name",
      dataIndex: "school_administration_name",
      key: "school_administration_name",
      width: 300,
       render: (text) => renderText(text, 'school_administration_name'),
    },
    {
      title: "Telephone",
      dataIndex: "telephone",
      key: "telephone",
      width: 300,
       render: (text) => renderText(text, 'telephone'),
    },
    {
      title: "School Administration Date Signed",
      dataIndex: "school_administration_date_signed",
      key: "school_administration_date_signed",
      width: 300,
       render: (text) => renderText(text, 'school_administration_date_signed'),
    },
  ];
  

  // Fetch the list of household visits by householdId
  const fetchAllHouseholdVisitation = () => {
    const household_id = house.household_id;
    setIsLoading(true);
    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_SERVER}/household/hhvisitation/${household_id}`,
          options
        )
        .then((response) => {
          // Sort the visits by service date in descending order
          const sortedVisits = response.data.sort((a, b) => {
            // Convert dates to a format that Javascript can understand
            const dateA = new Date(a.visit_date?.split('-').reverse().join('-'));
            const dateB = new Date(b.visit_date?.split('-').reverse().join('-'));
            return dateB - dateA;
          });
          setfetchedHouseholdVisits(sortedVisits);
          setIsDataReadyForExport(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("axios error: " + error);
          setIsLoading(false);
        });
    }, 3000);
  };
  

  useEffect(() => {
    fetchAllHouseholdVisitation();
  }, [token, house.household_id]);

  const displayfetchedHouseholdVisits = Array.isArray(fetchedHouseholdVisits)
    ? fetchedHouseholdVisits
        .map((visits) => {
          if (!visits) {
            return null;
          }
          const {
            household_id, 
            visit_date, 
            caregiver_hiv_status, 
            caregiver_art, 
            clinical_care, 
            date_art, 
            art_appointment, 
            counselling, 
            art_medication, 
            mmd, mmd_months, 
            six_months, facility, 
            vl_last_result, 
            date_hiv, 
            visit_hiv_test, 
            referred_for_testing, 
            hiv_risk, 
            hiv_assessment, 
            prevention_support, 
            referred_facility, 
            number_children, 
            hiv_awareness_status, 
            caregiver_not_aware, 
            hiv_test_referral, 
            school_fees, 
            unpaid_school_fees, 
            linked_economic, 
            referred_mcdss, 
            income_source, 
            list_source, 
            bills_associated, 
            barriers_challenges, 
            economic_strengthening, 
            social_cash, 
            income_source_medical, 
            list_source_medical, 
            case_worker, 
            caseworker_date_signed, 
            case_manager, 
            manager_date_signed, 
            school_administration_name, 
            telephone, 
            school_administration_date_signed, 
            delete_status
          } = visits;

          // const health_services_str =
          //   typeof health_services === "string"
          //     ? health_services.replace(/[\[\]"]+/g, "")
          //     : "";

          return {
            // health_services: health_services_str, // updated
            household_id, 
            visit_date, 
            caregiver_hiv_status, 
            caregiver_art, 
            clinical_care, 
            date_art, 
            art_appointment, 
            counselling, 
            art_medication, 
            mmd, mmd_months, 
            six_months, facility, 
            vl_last_result, 
            date_hiv, 
            visit_hiv_test, 
            referred_for_testing, 
            hiv_risk, 
            hiv_assessment, 
            prevention_support, 
            referred_facility, 
            number_children, 
            hiv_awareness_status, 
            caregiver_not_aware, 
            hiv_test_referral, 
            school_fees, 
            unpaid_school_fees, 
            linked_economic, 
            referred_mcdss, 
            income_source, 
            list_source, 
            bills_associated, 
            barriers_challenges, 
            economic_strengthening, 
            social_cash, 
            income_source_medical, 
            list_source_medical, 
            case_worker, 
            caseworker_date_signed, 
            case_manager, 
            manager_date_signed, 
            school_administration_name, 
            telephone, 
            school_administration_date_signed, 
            delete_status
             // Add the entire visits object
            // and pass its state to the Screening Household Index page
          };
        })
        .filter((item) => item !== null)
    : [];


 // Sort the services based on the "Service Date" in descending order
 fetchedHouseholdVisits.sort((a, b) => {
  const dateA = a?.service_date?.split("-").reverse().join("-");
  const dateB = b?.service_date?.split("-").reverse().join("-");
  return new Date(dateB) - new Date(dateA);
});


  const navigate = useNavigate();

  const handleAdvancedSearchClick = () => {
    navigate("/advanced-search");
  };

  // Export to CSV
  const exportToCSV = () => {
    const headers = columns.map((column) => column.title);
    const csvData = [headers].concat(
      fetchedHouseholdVisits.map((house) => Object.values(house))
    );

    const csvContent = Papa.unparse(csvData);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    // Create the filename including household ID and caregiver name
    const fileName = `household_visits_data_${house.household_id}_${house.caregiver_name}.csv`;
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // export to PDF
  const exportToPDF = () => {
    const headers = columns.map((column) => column.title);
    const body = fetchedHouseholdVisits.map((row) =>
      columns.map((column) =>
        row[column.dataIndex] === null ? "" : row[column.dataIndex]
      )
    );

    const doc = new jsPDF("l", "mm", "a4");
    doc.autoTable({
      head: [headers],
      body: body,
      theme: "striped",
      styles: { fontSize: 8 },
    });
      // Create the filename including household ID and caregiver name
      const fileName = `household_visits_data_${house.household_id}_${house.caregiver_name}.pdf`;
      doc.save(fileName);
  };

  const handleExportToCSV = async () => {
    if (fetchedHouseholdVisits?.length === 0) {
      await fetchAllHouseholdVisitation(currentPage);
    }
    exportToCSV();
  };

  // TO - DO, figure out how to export to
  // PDF for an entire household record
  const handleExportToPDF = async () => {
    if (fetchedHouseholdVisits?.length === 0) {
      await fetchAllHouseholdVisitation(currentPage);
    }
    exportToPDF();
  };


  return (
    <>
      <VersionBanner />
          <div className="tab-pane" id="visits">
            <React.Fragment>
            <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: windowWidth <= 991 ? "column" : "row",
                      alignItems: "center",
                    }}
                  >
                    {/*breadcrumb navigation*/}
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <Chip
                          label="Household Visits"
                          style={{
                            background:
                            "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                          color: "white",
                          boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                            color: "white",
                            fontWeight: "500",
                            marginLeft: "1%",
                          }}
                        ></Chip>
                        &nbsp;&nbsp;&nbsp;
                      </Breadcrumb.Item>
                      <br />
                      <br />
                      <Breadcrumb.Item>
                        <h1
                          style={{
                            fontSize: "17px",
                            color: "black",
                            fontWeight: "500",
                          }}
                        >
                          {" "}
                          Navigate to:
                        </h1>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/households">
                          <Chip
                            label="Households"
                            variant="outlined"
                            color="primary"
                            clickable
                            style={{
                              backgroundColor: "#e0e0e0",
                              color: "black",
                              fontWeight: "500",
                            }}
                          ></Chip>
                        </Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/children">
                          <Chip
                            label="VCA`s"
                            variant="outlined"
                            color="primary"
                            clickable
                            style={{
                              backgroundColor: "#e0e0e0",
                              color: "black",
                              fontWeight: "500",
                            }}
                          ></Chip>
                        </Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/mothers">
                          <Chip
                            label="Mothers"
                            variant="outlined"
                            color="primary"
                            clickable
                            style={{
                              backgroundColor: "#e0e0e0",
                              color: "black",
                              fontWeight: "500",
                            }}
                          ></Chip>
                        </Link>
                      </Breadcrumb.Item>
                      <br />
                      <br />
                      <Breadcrumb.Item>
                        <Tooltip title="Export to CSV">
                          <Button
                            icon={<FileExcelFilled twoToneColor="#28A745" />}
                            disabled={!isDataReadyForExport}
                            size="large"
                            style={{
                              marginRight: 8,

                              background:
                                "linear-gradient(45deg, #28A745 30%, #FF8E53 90%)",
                              color: "white",
                              boxShadow: "0 3px 5px 2px rgba(40, 167, 69, 0.3)",
                            }}
                            onClick={handleExportToCSV}
                          />
                        </Tooltip>
                      </Breadcrumb.Item>
                      <br />
                      <br />
                      {/* <Breadcrumb.Item>
                        <Tooltip title="Export to PDF">
                          <Button
                            size="large"
                            icon={<FilePdfFilled />}
                            disabled={!isDataReadyForExport}
                            style={{
                              background: "#DC3545",
                              color: "white",
                              boxShadow: "0 3px 5px 2px rgba(220, 53, 69, 0.3)",
                            }}
                            onClick={handleExportToPDF}
                          />
                        </Tooltip>
                      </Breadcrumb.Item> */}
                      <Breadcrumb.Item>
                        <Button
                          onClick={handleAdvancedSearchClick}
                          size="large"
                          style={{
                            background:
                              "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                            color: "white",
                            boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                            display: "block",
                            margin: "0 auto",
                            fontWeight: "500",
                          }}
                        >
                          Advanced Search
                        </Button>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                    <div
                      className="toolbar"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: windowWidth <= 991 ? "column" : "row",
                      }}
                    >
                    </div>
                  </div>
                </div>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "10vh",
                  }}
                >
                  <Spin size={35} color={"#808080"} loading={true} />
                  <p>
                    <b style={{ fontWeight: "500", textAlign: "center" }}>
                      Please wait while we process your request...
                    </b>
                  </p>
                </div>
              ) : (
                <Table
                  dataSource={displayfetchedHouseholdVisits}
                  columns={columns}
                  scroll={{  y: 500 }}
                  pagination={false}
                  className="ant-table"
                  locale={{
                    emptyText: (
                      <b style={{ color: "black" }}>
                       There are currently no follow-up Household Visits for this Household. Kindly revisit in future...
                      </b>
                    ),
                  }}
                />
              )}
            </React.Fragment>
          </div>
      </>
  );
};

export default HouseholdVisits;
