import React, { useContext, useEffect, useState, useMemo } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import AppContext from "../context/appContext";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import useSWR from "swr";
import { Breadcrumb } from "antd";
import { Chip } from "@mui/material";
import VersionBanner from "./versionBanner";

const Home = () => {
  const clients = useContext(AppContext);

  const token = localStorage.getItem("token");

  const [householdCount, setHouseholdCount] = useState(null);
  const [motherCount, setMotherCount] = useState(null);
  const [vcasCount, setVcasCount] = useState(null);
  const [caseworkerCount, setCaseworkerCount] = useState(null);
  const [cursorStyle, setCursorStyle] = useState({});
  const [loading, setLoading] = useState(true);

  const handleMouseEnter = () => {
    setCursorStyle({
      cursor: "pointer",
      boxShadow: "255, 255, 255, 255",
    });
  };

  const handleMouseLeave = () => {
    setCursorStyle({});
  };

  const role = clients.district - 1;
  const navigate = useNavigate();
  const districts = [
    "Kabwe",
    "Kapiri Mposhi",
    "Mkushi",
    "Chibombo",
    "Chisamba",
    "Chikankata",
    "Mumbwa",
    "Sinazongwe",
    "Kalomo",
    "Monze",
    "Namwala",
    "Choma",
    "Mazabuka",
    "Livingstone",
    "Serenje",
    "Kazungula",
  ];

  useEffect(() => {
    if (clients.district === 0) {
      navigate("/districts");
    }
  }, []);

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  const handleCardClick = (value) => {
    navigate(`/${value}`);
  };

  const fetcher = (url) =>
    axios.get(url, options).then((res) => {
      setHouseholdCount(res.data.total)
      setLoading(false);
    });

  useSWR(
    `${process.env.REACT_APP_SERVER}/household/district/count/${
      districts[clients.district - 1]
    }`,
    fetcher
  );

  const fetcher2 = (url2) =>
    axios.get(url2, options).then((res2) => {
      setVcasCount(res2.data.total);
      setLoading(false);
    });

  useSWR(
    `${process.env.REACT_APP_SERVER}/child/district/count/${
      districts[clients.district - 1]
    }`,
    fetcher2
  );

  const fetcher3 = (url3) =>
    axios.get(url3, options).then((res3) => {
      setMotherCount(res3.data.total);
      setLoading(false);
    });

  useSWR(
    `${process.env.REACT_APP_SERVER}/mother/district/count/${
      districts[clients.district - 1]
    }`,
    fetcher3
  );

  const fetcher4 = (url4) =>
    axios.get(url4, options).then((res4) => {
      setCaseworkerCount(res4.data.total);
      setLoading(false);
    });

  useSWR(
    `${process.env.REACT_APP_SERVER}/child/caseworker/count/${clients.district}`,
    fetcher4
  );

  return (
    <>
      <VersionBanner />
      <div className="col">
        <div className="card">
          <div className="card-header">
            <div>
              {role >= 0 && role < districts.length ? (
                <h3 style={{ fontWeight: "initial" }}>
                  {districts[role]} District
                </h3>
              ) : (
                <BounceLoader size={35} color={"#808080"} loading={true} />
              )}
            </div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <b
                  style={{
                    fontSize: "17px",
                    color: "black",
                    fontWeight: "500",
                  }}
                >
                  Home
                </b>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <h1
                  style={{
                    fontSize: "17px",
                    color: "black",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  Navigate to:
                </h1>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/districts">
                  <Chip
                    label="Districts"
                    variant="outlined"
                    color="primary"
                    clickable
                    style={{
                      backgroundColor: "#e0e0e0",
                      color: "black",
                      fontWeight: "500",
                    }}
                  ></Chip>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/households">
                  <Chip
                    label="Households"
                    variant="outlined"
                    color="primary"
                    clickable
                    style={{
                      backgroundColor: "#e0e0e0",
                      color: "black",
                      fontWeight: "500",
                    }}
                  ></Chip>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/children">
                  <Chip
                    label="VCA`s"
                    variant="outlined"
                    color="primary"
                    clickable
                    style={{
                      backgroundColor: "#e0e0e0",
                      color: "black",
                      fontWeight: "500",
                    }}
                  ></Chip>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/mothers">
                  <Chip
                    label="Mothers"
                    variant="outlined"
                    color="primary"
                    clickable
                    style={{
                      backgroundColor: "#e0e0e0",
                      color: "black",
                      fontWeight: "500",
                    }}
                  ></Chip>
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
            <br />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="card card-stats">
            <div className="card-header-info">
              <div className="card-icon" data-header-animation="true">
                <i className="material-icons">people</i>
              </div>
              <h4 className="card-title" style={{ fontWeight: "500" }}>
                No. of Caseworkers
              </h4>
              <h3 className="card-title">
                {caseworkerCount !== null ? (
                  <div>{caseworkerCount}</div>
                ) : (
                  <div style={{ float: "right" }}>
                    <BounceLoader size={35} color={"#808080"} loading={true} />
                  </div>
                )}
              </h3>
            </div>

            <div className="card-footer">
              <div className="stats">
                <i className="material-icons">date_range</i> Last 24 Hours
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-6">
          <div
            className="card card-stats"
            onClick={() => handleCardClick("households")}
            style={{
              boxShadow: "(255, 255, 255, 255)",
              ...cursorStyle,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="card-header-info">
              <div className="card-icon" data-header-animation="true">
                <i className="material-icons">house</i>
              </div>
              <h4 className="card-title" style={{ fontWeight: "500" }}>
                Households
              </h4>
              <h3 className="card-title">
                {householdCount !== null ? (
                  <div>{householdCount}</div>
                ) : (
                  <div style={{ float: "right" }}>
                    <BounceLoader size={35} color={"#808080"} loading={true} />
                  </div>
                )}
              </h3>
            </div>
            <div className="card-footer">
              <div className="stats">
                <i className="material-icons">date_range</i> Last 24 Hours
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-6">
          <div
            className="card card-stats"
            onClick={() => handleCardClick("children")}
            style={{
              boxShadow: "(255, 255, 255, 255)",
              ...cursorStyle,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="card-header-info">
              <div className="card-icon" data-header-animation="true">
                <i className="material-icons">people</i>
              </div>
              <h4 className="card-title" style={{ fontWeight: "500" }}>
                VCA Register
              </h4>
              <h3 className="card-title">
                {vcasCount !== null ? (
                  <div>{vcasCount}</div>
                ) : (
                  <div style={{ float: "right" }}>
                    <BounceLoader size={35} color={"#808080"} loading={true} />
                  </div>
                )}
              </h3>
            </div>
            <div className="card-footer">
              <div className="stats">
                <i className="material-icons">date_range</i> Last 24 Hours
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-6">
          <div
            className="card card-stats"
            onClick={() => handleCardClick("mothers")}
            style={{
              boxShadow: "(255, 255, 255, 255)",
              ...cursorStyle,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="card-header-info">
              <div className="card-icon" data-header-animation="true">
                <i className="material-icons">woman</i>
              </div>
              <h4 className="card-title" style={{ fontWeight: "500" }}>
                {" "}
                Index Mothers
              </h4>
              <h3 className="card-title">
              {householdCount !== null ? (
                  <div>{householdCount}</div>
                ) : (
                  <div style={{ float: "right" }}>
                    <BounceLoader size={35} color={"#808080"} loading={true} />
                  </div>
                )}
              </h3>
            </div>
            <div className="card-footer">
              <div className="stats">
                <i className="material-icons">date_range</i> Last 24 Hours
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Home);