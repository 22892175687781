import { Link, useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const active = location.pathname;

  const navigate = useNavigate();

  const unsetToken = () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  const username = localStorage.getItem("username");
  const isAdmin = parseInt(localStorage.getItem("isAdmin"), 10);

  const handleNavigate = () => {
    window.open("https://dashboard.ecap.bluecodeltd.com/login/", "_blank");
  };

  return (
    <>
      {active === "/districts" ? (
        <></>
      ) : (
        <div
          className="sidebar"
          data-color="green"
          data-background-color="black"
        >
          <div className="sidebar-wrapper">
            <div className="user">
              <div className="photo">
                <i className="material-icons" style={{ color: "white" }}>
                  person
                </i>
              </div>
              <div className="user-info">
                <a
                  data-toggle="collapse"
                  href="#collapseExample"
                  className="username"
                >
                  <span>{username}</span>
                </a>
              </div>
            </div>
            <ul className="nav">
              <li className={active === "/" ? "nav-item active" : "nav-item"}>
                <Link className="nav-link" to="/">
                  <i className="material-icons">home</i>
                  <p> Home </p>
                </Link>
              </li>
              <li
                className={
                  active === "/districts" ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/districts">
                  <i className="material-icons">dashboard</i>
                  <p> Districts </p>
                </Link>
              </li>

              <li
                className={
                  active === "/households" ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/households">
                  <i className="material-icons">house</i>
                  <p> Household Register </p>
                </Link>
              </li>

              <li
                className={
                  active === "/children" ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/children">
                  <i className="material-icons">people</i>
                  <p> VCA Register </p>
                </Link>
              </li>

              <li
                className={
                  active === "/mothers" ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/mothers">
                  <i className="material-icons">woman</i>
                  <p> Index Mother Register </p>
                </Link>
              </li>
              <li
                className={
                  active === "/vca-services" ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/vca-services">
                  <i className="material-icons">local_hospital</i>
                  <p> VCA Services </p>
                </Link>
              </li>
              <li
                className={
                  active === "/caregiver-services"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/caregiver-services">
                  <i className="material-icons">local_hospital</i>
                  <p> Caregiver Services </p>
                </Link>
              </li>

              <li
                className={
                  active === "/comments" ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/comments">
                  <i className="material-icons">flag</i>
                  <p> Flags </p>
                </Link>
              </li>
              {isAdmin !== 0 && (
                <li
                  className={
                    active === "/userManagement"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/users">
                    <i className="material-icons">person</i>
                    <p>User Management</p>
                  </Link>
                </li>
              )}

              <li className="nav-item">
                <Link className="nav-link" to="/household-archived-register">
                  <i className="material-icons">archive</i>
                  <p>Household Archived Register</p>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/vca-archived-register">
                  <i className="material-icons">archive</i>
                  <p>VCA Archived Register</p>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" onClick={handleNavigate}>
                  <i className="material-icons">timeline</i>
                  <p>Charts</p>
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to={"/"} onClick={unsetToken}>
                  <i className="material-icons">logout</i>
                  <p> Log Out </p>
                </Link>
              </li>
              <br />
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
