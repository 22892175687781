import { useNavigate, useLocation } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Menu } from 'antd';

import { Link } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const active = location.pathname;

  // route to previous page
  const showPrevious = () => {
    navigate(-1);
  };

  const unsetToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    navigate("/");
    window.location.reload();
  };

  
  const menu = (
    <Menu>
      <Menu.Divider />
      <Menu.Item key="1" onClick={unsetToken}>
      <b style={{fontWeight:"500"}}>Log out</b>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {active === "/districts" ? (
        <></>
      ) : (
        <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top">
          <div className="container-fluid">
            <div className="navbar-wrapper">
              <div>
              <Button
                  size="large"
                  onTouchMove={2}
                  style={{
                    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                    color: 'white',
                    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, 0.3)',
                    display: 'block',
                    marginLeft: '30%',
                  }}
                  onClick={showPrevious}
                  id="minimizeSidebar"
                  className="btn btn-just-icon btn-white btn-fab btn-rectangle"
                  icon={<span className="material-icons">chevron_left</span>}
                >
                </Button>
              </div>
              &nbsp;&nbsp;&nbsp;<img src="https://monalogy.sirv.com/ecap-II-logo.png" width="451" height="30" alt=""></img>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="navbar-toggler-icon icon-bar" />
              <span className="navbar-toggler-icon icon-bar" />
              <span className="navbar-toggler-icon icon-bar" />
            </button>
              <div className="collapse navbar-collapse justify-content-end">
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <a className="ant-dropdown-link" href="javascript:;">
                      <Avatar icon={<UserOutlined />} style={{ backgroundColor: "#11b8cc" }} />
                      </a>
                    </Dropdown>
                  </li>
                </ul>
              </div>

          </div>
        </nav>
      )}
    </>
  );
};

export default Navbar;
