import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../context/appContext";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
    Spin, Alert, Input, Button, Tooltip, Table, Select, Breadcrumb
} from "antd";
import { Chip, debounce } from "@mui/material";
import {
    SearchOutlined, FileExcelFilled,
    FilePdfFilled, InfoCircleOutlined
} from "@ant-design/icons";
import VersionBanner from "./versionBanner";

//Enable option selection filter on the dropdowns
const { Option } = Select;

const AdvancedSearch = () => {

    const clients = useContext(AppContext);
    const token = localStorage.getItem("token");

    const options = useMemo(
        () => ({
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }),
        [token]
    );

    const navigate = useNavigate();
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [fetchedHouseholds, setFetchedHouseholds] = useState([]);
    const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);
    const [globalSearch, setGlobalSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [totalRecords, setTotalRecords] = useState(0);
    const [visibleRecordsMessage, setVisibleRecordsMessage] = useState('');


    const [filters, setFilters] = useState([
        { key: "cwlhiv", value: "" },
        { key: "agyw", value: "" },
        { key: "hei", value: "" },
        { key: "csv", value: "" },
        { key: "cfsw", value: "" },
        { key: "calhiv", value: "" },
        { key: "service", value: "" },
        { key: "facility", value: "" },
        { key: "hei", value: "" },
        { key: "child_mmd", value: "" },
        { key: "partner", value: "" },
        { key: "screened", value: "" },
        { key: "monthlyexpenses", value: "" },
        { key: "marital_status", value: "" },
        { key: "beds", value: "" },
        { key: "school", value: "" },
        { key: "malaria_itns", value: "" },
        { key: "violence_six_months", value: "" },
        { key: "is_on_hiv_treatment", value: "" },
        { key: "caregiver_hiv_status", value: "" },
        { key: "education", value: "" },
        { key: "relation", value: "" },
        { key: "acceptance", value: "" },
        { key: "art_number", value: "" },
        { key: "entry_type", value: "" },
        { key: "case_status", value: "" },
        { key: "vl_check_box", value: "" },
        { key: "art_check_box", value: "" },
        { key: "caregiver_sex", value: "" },
        { key: "client_result", value: "" },
        { key: "approved_family", value: "" },
        { key: "client_screened", value: "" },
        { key: "index_check_box", value: "" },
        { key: "consent_check_box", value: "" },
        { key: "active_on_treatment", value: "" },
        { key: "biological_children", value: "" },
        { key: "tpt_client_initiated", value: "" },
        { key: "tpt_client_eligibility", value: "" },
        { key: "viral_load_results_on_file", value: "" },
        { key: "children_violence_six_months", value: "" },
        { key: "is_the_child_caregiver_an_fsw", value: "" },
        { key: "child_ever_experienced_sexual_violence", value: "" },
        { key: "is_biological_mother_of_child_living_with_hiv", value: "" },
    ]);


    //If a field on a record is empty, render
    // 'Not Provided' instead of empty string
    const renderText = (text, dataIndex) => {
        if (text === null || text === undefined || (typeof text === 'string' && text.trim() === '')) {
            if (dataIndex === 'service_referred') {
                return 'Not Referred';
            } else if (dataIndex === 'institution') {
                return 'Not Specified';
            } else {
                return 'Not Provided';
            }
        } else {
            return text;
        }
    };

    const renderSubPopulations = (text) => {
        if (text === "0") {
            return "No";
        } else if (text === "true") {
            return "Yes";
        } else {
            return text;
        }
    };


    const columns = [
        {
            title: "Client Screened",
            dataIndex: "client_screened",
            key: "client_screened",
            render: renderText,
        },
        {
            title: 'Household ID',
            dataIndex: 'household_id',
            key: 'household_id',
            render: renderText,
        },
        {
            title: 'Caseworker Name',
            dataIndex: 'caseworker_name',
            key: 'caseworker_name',
            render: renderText,
        },
        {
            title: 'Caregiver Name',
            dataIndex: 'caregiver_name',
            key: 'caregiver_name',
            render: renderText,
        },
        {
            title: 'Province',
            dataIndex: 'province',
            key: 'province',
            render: renderText,
        },
        {
            title: 'Facility',
            dataIndex: 'facility',
            key: 'facility',
            render: renderText,
        },
        {
            title: 'Contact Number',
            dataIndex: 'contact_number',
            key: 'contact_number',
            render: renderText,
        },
        {
            title: 'Caseworker Phone',
            dataIndex: 'caseworker_phone',
            key: 'caseworker_phone',
            render: renderText,
        },
        {
            title: "Caregiver Birthdate",
            dataIndex: "caregiver_birthdate",
            key: "caregiver_birthdate",
            render: renderText,
        },
        {
            title: 'Screening Location',
            dataIndex: 'screening_location',
            key: 'screening_location',
            render: renderText,
        },
        {
            title: 'Home Address',
            dataIndex: 'homeaddress',
            key: 'homeaddress',
            render: renderText,
        },
        {
            title: 'HEI',
            dataIndex: 'hei',
            key: 'hei',
            render: renderSubPopulations,
        },
        {
            title: 'CALHIV',
            dataIndex: 'calhiv',
            key: 'calhiv',
            render: renderSubPopulations,
        },

        {
            title: 'CWLHIV',
            dataIndex: 'cwlhiv',
            key: 'cwlhiv',
            render: renderSubPopulations,
        },
        {
            title: 'AGYW',
            dataIndex: 'agyw',
            key: 'agyw',
            render: renderSubPopulations,
        },
        {
            title: 'CSV',
            dataIndex: 'csv',
            key: 'csv',
            render: renderSubPopulations,
        },
        {
            title: 'CFSW',
            dataIndex: 'cfsw',
            key: 'cfsw',
            render: renderSubPopulations,
        },
        {
            title: 'Child (MMD)',
            dataIndex: 'child_mmd',
            key: 'child_mmd',
            render: renderText,
        },
        {
            title: 'Partner',
            dataIndex: 'partner',
            key: 'partner',
            render: renderText,
        },
        {
            title: 'Screened',
            dataIndex: 'screened',
            key: 'screened',
            render: renderText,
        },
        {
            title: 'Date Enrolled',
            dataIndex: 'date_enrolled',
            key: 'date_enrolled',
            render: renderText,
        },
        {
            title: 'Monthly Expenses',
            dataIndex: 'monthlyexpenses',
            key: 'monthlyexpenses',
            render: renderText,
        },
        {
            title: 'Marital Status',
            dataIndex: 'marital_status',
            key: 'marital_status',
            render: renderText,
        },
        {
            title: 'Beds',
            dataIndex: 'beds',
            key: 'beds',
            render: renderText,
        },
        {
            title: 'School',
            dataIndex: 'school',
            key: 'school',
            render: renderText,
        },
        {
            title: 'Malaria ITNs',
            dataIndex: 'malaria_itns',
            key: 'malaria_itns',
            render: renderText,
        },
        {
            title: 'Emergency Name',
            dataIndex: 'emergency_name',
            key: 'emergency_name',
            render: renderText,
        },
        {
            title: 'Violence (Past 6 Months)',
            dataIndex: 'violence_six_months',
            key: 'violence_six_months',
            render: renderText,
        },
        {
            title: 'Family Source of Income',
            dataIndex: 'fam_source_income',
            key: 'fam_source_income',
            render: renderText,
        },
        {
            title: "Date Edited",
            dataIndex: "date_edited",
            key: "date_edited",
            render: renderText,
        },
        {
            title: "Date Referred",
            dataIndex: "date_referred",
            key: "date_referred",
            render: renderText,
        },
        {
            title: "Is on HIV Treatment",
            dataIndex: "is_on_hiv_treatment",
            key: "is_on_hiv_treatment",
            render: renderText,
        },
        {
            title: "Caregiver (HIV) Status",
            dataIndex: "caregiver_hiv_status",
            key: "caregiver_hiv_status",
            render: renderText,
        },
        {
            title: "Caregiver (ART) Number",
            dataIndex: "caregiver_art_number",
            key: "caregiver_art_number",
            render: renderText,
        },
        {
            title: "Education",
            dataIndex: "education",
            key: "education",
            render: renderText,
        },
        {
            title: "Number of Pregnant Women",
            dataIndex: "number_of_pregnant_women",
            key: "number_of_pregnant_women",
            render: renderText,
        },
        {
            title: "Relation",
            dataIndex: "relation",
            key: "relation",
            render: renderText,
        },
        {
            title: "Unique ID",
            dataIndex: "unique_id",
            key: "unique_id",
            render: renderText,
        },
        {
            title: "Acceptance",
            dataIndex: "acceptance",
            key: "acceptance",
            render: renderText,
        },
        {
            title: "(ART) Number",
            dataIndex: "art_number",
            key: "art_number",
            render: renderText,
        },
        {
            title: "Entry Type",
            dataIndex: "entry_type",
            key: "entry_type",
            render: renderText,
        },
        {
            title: "Case Status",
            dataIndex: "case_status",
            key: "case_status",
            render: renderText,
        },
        {
            title: "(VL) Check Box",
            dataIndex: "vl_check_box",
            key: "vl_check_box",
            render: renderText,
        },
        {
            title: "(ART) Check Box",
            dataIndex: "art_check_box",
            key: "art_check_box",
            render: renderText,
        },
        {
            title: "Caregiver Sex",
            dataIndex: "caregiver_sex",
            key: "caregiver_sex",
            render: renderText,
        },
        {
            title: "Client Result",
            dataIndex: "client_result",
            key: "client_result",
            render: renderText,
        },
        {
            title: "Date Screened",
            dataIndex: "date_screened",
            key: "date_screened",
            render: renderText,
        },
        {
            title: "Takes Drugs to Prevent Other Diseases",
            dataIndex: "takes_drugs_to_prevent_other_diseases",
            key: "takes_drugs_to_prevent_other_diseases",
            render: renderText,
        },
        {
            title: "Date HIV Known",
            dataIndex: "date_hiv_known",
            key: "date_hiv_known",
            render: renderText,
        },
        {
            title: "Relationship",
            dataIndex: "relationship",
            key: "relationship",
            render: renderText,
        },
        {
            title: "Pregnant Women",
            dataIndex: "pregnant_woment",
            key: "pregnant_woment",
            render: renderText,
        },
        {
            title: "Screening Date",
            dataIndex: "screening_date",
            key: "screening_date",
            render: renderText,
        },
        {
            title: "Approved Family",
            dataIndex: "approved_family",
            key: "approved_family",
            render: renderText,
        },
        {
            title: "Caregiver Phone",
            dataIndex: "caseworker_phone",
            key: "caseworker_phone",
            render: renderText,
        },
        {
            title: "Enrolled Date",
            dataIndex: "date_enrolled",
            key: "date_enrolled",
            render: renderText,
        },
        {
            title: "Index Check Box",
            dataIndex: "index_check_box",
            key: "index_check_box",
            render: renderText,
        },
        {
            title: "Date Started (ART)",
            dataIndex: "date_started_art",
            key: "date_started_art",
            render: renderText,
        },
        {
            title: "Consent Check Box",
            dataIndex: "consent_check_box",
            key: "consent_check_box",
            render: renderText,
        },
        {
            title: "Date Edited Check",
            dataIndex: "date_edited_check",
            key: "date_edited_check",
            render: renderText,
        },
        {
            title: "Active on Treatment",
            dataIndex: "active_on_treatment",
            key: "active_on_treatment",
            render: renderText,
        },
        {
            title: "Biological Child",
            dataIndex: "biological_children",
            key: "biological_children",
            render: renderText,
        },
        {
            title: "Adolescent Birthdate",
            dataIndex: "adolescent_birthdate",
            key: "adolescent_birthdate",
            render: renderText,
        },
        {
            title: "(TPT) Client Initiated",
            dataIndex: "tpt_client_initiated",
            key: "tpt_client_initiated",
            render: renderText,
        },
        {
            title: "(TPT) Client Eligibility",
            dataIndex: "tpt_client_eligibility",
            key: "tpt_client_eligibility",
            render: renderText,
        },
        {
            title: "Date Offered Enrollment",
            dataIndex: "date_offered_enrollment",
            key: "date_offered_enrollment",
            render: renderText,
        },
        {
            title: "Screening Location Home",
            dataIndex: "screening_location_home",
            key: "screening_location_home",
            render: renderText,
        },
        {
            title: "Viral Load Results on File",
            dataIndex: "viral_load_results_on_file",
            key: "viral_load_results_on_file",
            render: renderText,
        },
        {
            title: "Children Violence Six Months",
            dataIndex: "children_violence_six_months",
            key: "children_violence_six_months",
            render: renderText,
        },
        {
            title: "Is the Child`s Caregiver an (FSW)",
            dataIndex: "is_the_child_caregiver_an_fsw",
            key: "is_the_child_caregiver_an_fsw",
            render: renderText,
        },
        {
            title: "Child Ever Experienced Sexual Violence",
            dataIndex: "child_ever_experienced_sexual_violence",
            key: "child_ever_experienced_sexual_violence",
            render: renderText,
        },
        {
            title: "Is Biological Mother of the Child Living with HIV",
            dataIndex: "is_biological_mother_of_child_living_with_hiv",
            key: "is_biological_mother_of_child_living_with_hiv",
            render: renderText,
        },
        {
            title: "Service",
            dataIndex: "service",
            key: "service",
            render: renderText,
        },
        {
            title: "Quarter",
            dataIndex: "quarter",
            key: "quarter",
            render: renderText,
        },
    ];

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleFilterChange = (filterType, value) => {
        setFilters((prevFilters) => {
            const newFilters = prevFilters?.map((filter) => {
                if (filter?.key === filterType) {
                    return { ...filter, value };
                }
                return filter;
            });
            return newFilters;
        });
    };

    const clearFilters = () => {
        setFilters([
            { key: "calhiv", value: "" },
            { key: "facility", value: "" },
            { key: "hei", value: "" },
            { key: "cwlhiv", value: "" },
            { key: "calhiv", value: "" },
            { key: "agyw", value: "" },
            { key: "csv", value: "" },
            { key: "cfsw", value: "" },
            { key: "partner", value: "" },
            { key: "screened", value: "" },
            { key: "MonthlyExpenses", value: "" },
            { key: "marital_status", value: "" },
            { key: "beds", value: "" },
            { key: "school", value: "" },
            { key: "malaria_itns", value: "" },
            { key: "violence_six_months", value: "" },
            { key: "is_on_hiv_treatment", value: "" },
            { key: "caregiver_hiv_status", value: "" },
            { key: "education", value: "" },
            { key: "relation", value: "" },
            { key: "cceptance", value: "" },
            { key: "art_number", value: "" },
            { key: "entry_type", value: "" },
            { key: "case_status", value: "" },
            { key: "vl_check_box", value: "" },
            { key: "art_check_box", value: "" },
            { key: "caregiver_sex", value: "" },
            { key: "client_result", value: "" },
            { key: "approved_family", value: "" },
            { key: "client_screened", value: "" },
            { key: "index_check_box", value: "" },
            { key: "consent_check_box", value: "" },
            { key: "active_on_treatment", value: "" },
            { key: "biological_children", value: "" },
            { key: "tpt_client_initiated", value: "" },
            { key: "tpt_client_eligibility", value: "" },
            { key: "viral_load_results_on_file", value: "" },
            { key: "children_violence_six_months", value: "" },
            { key: "is_the_child_caregiver_an_fsw", value: "" },
            { key: "takes_drugs_to_prevent_other_diseases", value: "" },
            { key: "child_ever_experienced_sexual_violence", value: "" },
            { key: "is_biological_mother_of_child_living_with_hiv", value: "" },
        ]);
    };

    const openHouseholdRowClick = (house) => {
        navigate("/household", { state: { house } });
    };

    // debounce search input
    const debouncedHandleSearch = useCallback(
        debounce((value) => {
            setGlobalSearch(value);
        }, 500),
        []
    );

    useEffect(() => {
        debouncedHandleSearch(searchInput);
    }, [searchInput, debouncedHandleSearch]);

    const districts = [
        "Kabwe",
        "Kapiri Mposhi",
        "Mkushi",
        "Chibombo",
        "Chisamba",
        "Chikankata",
        "Mumbwa",
        "Sinazongwe",
        "Kalomo",
        "Monze",
        "Namwala",
        "Choma",
        "Mazabuka",
        "Livingstone",
        "Serenje",
        "Kazungula",
    ];

    const buildApiRequestUrl = () => {
        const baseUrl = `${process.env.REACT_APP_SERVER}/household/advanced-search/${clients.district}`;
        const queryParams = new URLSearchParams({
            page: currentPage,
            itemsPerPage,
            globalSearch, // Removed encodeURIComponent
        });

        if (Array?.isArray(filters)) {
            filters?.forEach((filter) => {
                if (filter?.value) {
                    queryParams?.set(filter?.key, filter?.value);
                }
            });
        }

        return `${baseUrl}?${queryParams?.toString()}`;
    };

    const fetchFilteredHouseholds = useCallback(async () => {
        setLoading(true);
        const requestUrl = buildApiRequestUrl();

        axios
            .get(requestUrl, options)
            .then((res) => {
                setFetchedHouseholds(res.data.data);
                setIsDataReadyForExport(true);
                setTotalRecords(res.data.totalRecords);
                setItemsPerPage(itemsPerPage);
                setTotalPages(res.data.totalPages);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                const errorMessage =
                    err.response && err.response.data && err.response.data.message;
                setError(errorMessage);
            });
    }, [clients.district, currentPage,
        globalSearch, itemsPerPage, filters, options]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage + 1;
        const endIndex = Math.min(currentPage * itemsPerPage, totalRecords);
        setVisibleRecordsMessage(
            `Currently displaying ${endIndex - startIndex + 1} 
      records out of ${totalRecords} filtered results.`
        );
    }, [clients.district,
        globalSearch, itemsPerPage, filters, options,
        totalRecords, currentPage, itemsPerPage]);

    useEffect(() => {
        if (!firstLoad) {
            fetchFilteredHouseholds();
        } else {
            setFirstLoad(false);
        }
    }, [currentPage, globalSearch, filters, clients.district,
        fetchFilteredHouseholds, firstLoad]);


    const displayHouseholdsData = Array.isArray(fetchedHouseholds)
        ? fetchedHouseholds.map((house) => {
            if (!house) {
                return null;
            }
            const {
                household_id,
                facility,
                date_enrolled,
                phone,
                child_mmd,
                caregiver_name,
                contact_number,
                province,
                emergency_name,
                screening_location,
                homeaddress,
                caseworker_phone,
                caseworker_name,
                fam_source_income,
                biological_children,
                date_edited,
                quarter,
                date_referred,
                caregiver_art_number,
                caregiver_birthdate,
                unique_id,
                date_hiv_known,
                screening_date,
                caregiver_phone,
                date_started_art,
                date_edited_check,
                adolescent_birthdate,
                date_offered_enrollment,
                screening_location_home,
                calhiv,
                hei,
                cwlhiv,
                agyw,
                cfsw,
                csv,
                partner,
                screened,
                monthlyexpenses,
                marital_status,
                beds,
                service,
                school,
                malaria_itns,
                violence_six_months,
                is_on_hiv_treatment,
                caregiver_hiv_status,
                education,
                number_of_pregnant_women,
                relation,
                acceptance,
                art_number,
                entry_type,
                case_status,
                vl_check_box,
                art_check_box,
                caregiver_sex,
                client_result,
                date_screened,
                relationship,
                pregnant_woment,
                approved_family,
                client_screened,
                index_check_box,
                consent_check_box,
                active_on_treatment,
                biolgical_children,
                tpt_client_initiated,
                tpt_client_eligibility,
                viral_load_results_on_file,
                children_violence_six_months,
                is_the_child_caregiver_an_fsw,
                takes_drugs_to_prevent_other_diseases,
                child_ever_experienced_sexual_violence,
                is_biological_mother_of_child_living_with_hiv,
            } = house;

            return {
                key: household_id,
                household_id,
                facility,
                date_enrolled,
                phone,
                caregiver_name,
                caseworker_name,
                contact_number,
                province,
                child_mmd,
                emergency_name,
                screening_location,
                homeaddress,
                caseworker_phone,
                fam_source_income,
                biological_children,
                date_edited,
                quarter,
                date_referred,
                caregiver_art_number,
                caregiver_birthdate,
                unique_id,
                date_hiv_known,
                screening_date,
                caregiver_phone,
                date_started_art,
                date_edited_check,
                adolescent_birthdate,
                date_offered_enrollment,
                screening_location_home,
                calhiv,
                hei,
                cwlhiv,
                agyw,
                cfsw,
                csv,
                partner,
                screened,
                monthlyexpenses,
                marital_status,
                beds,
                service,
                school,
                malaria_itns,
                violence_six_months,
                is_on_hiv_treatment,
                caregiver_hiv_status,
                education,
                relation,
                acceptance,
                art_number,
                entry_type,
                case_status,
                vl_check_box,
                art_check_box,
                caregiver_sex,
                client_result,
                date_screened,
                relationship,
                pregnant_woment,
                approved_family,
                client_screened,
                index_check_box,
                consent_check_box,
                active_on_treatment,
                biolgical_children,
                tpt_client_initiated,
                tpt_client_eligibility,
                viral_load_results_on_file,
                children_violence_six_months,
                is_the_child_caregiver_an_fsw,
                takes_drugs_to_prevent_other_diseases,
                child_ever_experienced_sexual_violence,
                is_biological_mother_of_child_living_with_hiv,
                house, // Add the entire house object and
                // pass its state to the Screening VCA Household Index page
            };
        }).filter((item) => item !== null)
        : [];

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const exportToCSV = () => {
        const headers = columns.map((column) => column.title);
        const csvData = [headers].concat(
            displayHouseholdsData.map((row) =>
                columns.map((column) => (row[column.dataIndex]
                    === null ? "" : row[column.dataIndex]))
            )
        );
        const csvContent = Papa.unparse(csvData);
        const blob = new Blob([csvContent], {
            type:
                "text/csv;charset=utf-8;"
        });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "advanced_search_households_data.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportToPDF = () => {
        const headers = ["Column", "Value"];
        const body = [];

        displayHouseholdsData.forEach((row) => {
            columns.forEach((column, columnIndex) => {
                const rowData = [column.title, row[column.dataIndex] === null ? ""
                    : row[column.dataIndex]];
                body.push(rowData);
            });
        });

        const newHouseholdText = "Starting New Household Information";
        const newHouseholdRow = [newHouseholdText, ""];

        for (let i = 0; i < body.length; i++) {
            if (body[i][0] === "Client Screened") {
                body.splice(i, 0, newHouseholdRow);
                i++; // Skip the inserted row
            }
        }

        const doc = new jsPDF("p", "mm", "a4");
        doc.autoTable({
            head: [headers],
            body: body,
            theme: "striped",
            styles: { fontSize: 8, cellWidth: "wrap" },
            tableWidth: "wrap",
            willDrawCell: (hookData) => {
                if (hookData.cell.raw === "Client Screened") {
                    hookData.doc.setFillColor("lightgreen");
                }
                if (hookData.cell.raw === "Household ID") {
                    hookData.doc.setFillColor("lightblue");
                }
                if (hookData.cell.raw === "Quarter") {
                    hookData.doc.setFillColor("orange");
                }
            },
        });

        doc.save("advanced_search_households_data.pdf");
    };

    const handleExportToCSV = async () => {
        if (fetchedHouseholds?.length === 0) {
            await fetchFilteredHouseholds(currentPage);
        }
        exportToCSV();
    };

    const handleExportToPDF = async () => {
        if (fetchedHouseholds?.length === 0) {
            await fetchFilteredHouseholds(currentPage);
        }
        exportToPDF();
    };

    return (
        <>
            <VersionBanner />
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header-info">
                                    <div className="card-icon">
                                        <i className="material-icons">person</i>
                                    </div>
                                    <h4 className="card-title" style={{ fontWeight: "500" }}>

                                        {districts[clients.district - 1]} Advanced Search</h4>

                                </div>
                                <div className="card-body">
                                    <Breadcrumb>
                                        <Breadcrumb.Item>
                                            <Chip
                                                label="Advanced Search Page"
                                                variant="outlined"
                                                color="primary"
                                                style={{
                                                    backgroundColor: '#e0e0e0',
                                                    color: 'black', fontWeight: "500", marginLeft: "10%"
                                                }}
                                            >
                                            </Chip>
                                            &nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <h1 style={{ fontSize: '17px', color: 'black', fontWeight: "500" }}> Navigate to:</h1>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Link to="/households">
                                                <Chip
                                                    label="Households"
                                                    variant="outlined"
                                                    color="primary"
                                                    clickable
                                                    style={{
                                                        backgroundColor: '#e0e0e0',
                                                        color: 'black', fontWeight: "500"
                                                    }}
                                                >
                                                </Chip>
                                            </Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Link to="/children">
                                                <Chip
                                                    label="VCA`s"
                                                    variant="outlined"
                                                    color="primary"
                                                    clickable
                                                    style={{
                                                        backgroundColor: '#e0e0e0',
                                                        color: 'black', fontWeight: "500"
                                                    }}
                                                >
                                                </Chip>
                                            </Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Link to="/mothers">
                                                <Chip
                                                    label="Mothers"
                                                    variant="outlined"
                                                    color="primary"
                                                    clickable
                                                    style={{
                                                        backgroundColor: '#e0e0e0',
                                                        color: 'black', fontWeight: "500"
                                                    }}
                                                >
                                                </Chip>
                                            </Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Tooltip title="Export to CSV">
                                                <Button
                                                    icon={<FileExcelFilled twoToneColor="#28A745" />}
                                                    disabled={!isDataReadyForExport}
                                                    size="large"
                                                    style={{
                                                        marginRight: 8,
                                                        background: 'linear-gradient(45deg, #28A745 30%, #FF8E53 90%)',
                                                        color: 'white',
                                                        boxShadow: '0 3px 5px 2px rgba(40, 167, 69, 0.3)',
                                                    }}
                                                    onClick={handleExportToCSV}
                                                />
                                            </Tooltip>
                                        </Breadcrumb.Item>
                                        <br /><br />
                                        <Breadcrumb.Item>
                                            <Tooltip title="Export to PDF">
                                                <Button
                                                    size="large"
                                                    icon={<FilePdfFilled />}
                                                    disabled={!isDataReadyForExport}
                                                    style={{
                                                        background: '#DC3545',
                                                        color: 'white',
                                                        boxShadow: '0 3px 5px 2px rgba(220, 53, 69, 0.3)',
                                                    }}
                                                    onClick={handleExportToPDF}
                                                />
                                            </Tooltip>
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col s12 m12 l4" style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                                {searchInput && (
                                                    <>
                                                        <span style={{ color: 'black', marginRight: 8, marginLeft: 20 }}>
                                                            You applied filters by:</span><br />
                                                        <Chip label={searchInput} style={{ color: "black", fontWeight: "500" }} />
                                                    </>
                                                )}
                                                <br />
                                                <Button
                                                    style={{
                                                        width: "120px",
                                                        borderRadius: "30px",
                                                        marginLeft: "12px", flexDirection: 'row', display: 'flex-end',
                                                        justifyContent: 'center', alignItems: 'center', marginLeft: 20,
                                                    }}
                                                    type="primary"
                                                    onClick={clearFilters}
                                                >
                                                    Clear All Filters
                                                </Button>
                                            </div>
                                            <div className="col s12 m12 l8" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <div><br />
                                                    <h4 className="text-left" style={{
                                                        textAlign: 'right', marginRight: "5%", fontWeight: "500"
                                                    }}>
                                                        <b>Available filters in<br /> {districts[clients.district - 1]} District</b>
                                                    </h4>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container" style={{ marginLeft: "1%" }}>
                                        <div className="row">


                                            <div className="col-lg-4"><br />
                                                <h4><b>Filter by:</b></h4>
                                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <div style={{
                                                        marginLeft: "3%",
                                                        overflowY: 'auto',
                                                        position: 'relative',
                                                        overflowX: "40%",
                                                        maxHeight: '200px',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap'
                                                    }}>
                                                        <Breadcrumb>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Screened</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'screened')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('screened', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="true">True</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Service</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'service')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('service', value)}
                                                                        style={{ width: 200 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="Served">Served</Option>
                                                                        <Option value="Not Served">Not Served</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Marital Status</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'marital_status')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('marital_status', value)}
                                                                        style={{ width: 200 }}
                                                                    >
                                                                        <Option value="">All</Option>
                                                                        <Option value="Married">Married</Option>
                                                                        <Option value="Divorced">Divorced</Option>
                                                                        <Option value="Single">Single</Option>
                                                                        <Option value="Widowed">Widowed</Option>
                                                                        <Option value="Not Married, but in a Relationship">In a Relationship</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Case Status</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'case_status')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('case_status', value)}
                                                                        style={{ width: 100 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="1">Active</Option>
                                                                        <Option value="0">Inactive</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Monthly Expenses</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'monthlyexpenses')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('monthlyexpenses', value)}
                                                                        style={{ width: 200 }}
                                                                    >
                                                                        <Option value="">All</Option>
                                                                        <Option value="Less than K500">Less than K500</Option>
                                                                        <Option value="K500 - K999">K500 - K999</Option>
                                                                        <Option value="K1,000 - K1,999">K1,000 - K1,999</Option>
                                                                        <Option value="K2,000 - K4,999">K2,000 - K4,999</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Partner</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'partner')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('partner', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="PCZ">PCZ</Option>
                                                                        <Option value="BISO">BISO</Option>
                                                                        <Option value="YWCA">YWCA</Option>
                                                                        <Option value="ZEC">ZEC</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>School Level</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'school')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('school', value)}
                                                                        style={{ width: 200 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="primary_school">Primary School</Option>
                                                                        <Option value="secondary_school">Secondary School</Option>
                                                                        <Option value="not_in_school">Not In School</Option>
                                                                        <Option value="pre_school">Pre School</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Child Ever Experienced Sexual Violation (Past 6 months)</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'child_ever_experienced_sexual_violence')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('child_ever_experienced_sexual_violence', value)}
                                                                        style={{ width: 150 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Yes</Option>
                                                                        <Option value="no">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Beds</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'beds')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('beds', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="1">0</Option>
                                                                        <Option value="1">1</Option>
                                                                        <Option value="2">2</Option>
                                                                        <Option value="3">3</Option>
                                                                        <Option value="4">4</Option>
                                                                        <Option value="5">5</Option>
                                                                        <Option value="6">6</Option>
                                                                        <Option value="7">7</Option>
                                                                        <Option value="8">8</Option>
                                                                        <Option value="9">9</Option>
                                                                        <Option value="10">10</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Relation</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'relation')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('relation', value)}
                                                                        style={{ width: 110 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="parent">Parent</Option>
                                                                        <Option value="guardian">Guardian</Option>
                                                                        <Option value="no">Not Related</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Education</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'education')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('education', value)}
                                                                        style={{ width: 200 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="highest_grade">High School</Option>
                                                                        <Option value="college_university">College / University</Option>
                                                                        <Option value="none">Never Schooled</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                        </Breadcrumb>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-lg-4"><br />
                                                <h4><b>Other filters:</b></h4>
                                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <div style={{
                                                        marginLeft: "3%",
                                                        overflowY: 'auto',
                                                        position: 'relative',
                                                        overflowX: "40%",
                                                        maxHeight: '200px',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap'
                                                    }}>
                                                        <Breadcrumb>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Children Violence (Past 6 Months)</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'children_violence_six_months')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('children_violence_six_months', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Yes</Option>
                                                                        <Option value="no">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Is the Child Caregiver an (FSW)</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'is_the_child_caregiver_an_fsw')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('is_the_child_caregiver_an_fsw', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Yes</Option>
                                                                        <Option value="no">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>

                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Acceptance</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'acceptance')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('acceptance', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Yes</Option>
                                                                        <Option value="null">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Violence (Past 6 Months)</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'violence_six_months')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('violence_six_months', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Yes</Option>
                                                                        <Option value="no">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Is on HIV Treatment</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'is_on_hiv_treatment')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('is_on_hiv_treatment', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Yes</Option>
                                                                        <Option value="no">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Caregiver HIV Status</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'caregiver_hiv_status')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('caregiver_hiv_status', value)}
                                                                        style={{ width: 110 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="positive">Positive</Option>
                                                                        <Option value="negative">Negative</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Malaria (Itns)</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'malaria_itns')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('malaria_itns', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="0">0</Option>
                                                                        <Option value="1">1</Option>
                                                                        <Option value="2">2</Option>
                                                                        <Option value="3">3</Option>
                                                                        <Option value="4">4</Option>
                                                                        <Option value="5">5</Option>
                                                                        <Option value="6">6</Option>
                                                                        <Option value="7">7</Option>
                                                                        <Option value="8">8</Option>
                                                                        <Option value="9">9</Option>
                                                                        <Option value="10">10</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Entry Type</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'entry_type')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('entry_type', value)}
                                                                        style={{ width: 210 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="referral_from_facility">Referral from Facility</Option>
                                                                        <Option value="Transfer from Zamfam">Transfer from Zamfam</Option>
                                                                        <Option value="other">Other</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                        </Breadcrumb>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-lg-4"><br />
                                                <h4><b>Other filters:</b></h4>
                                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <div style={{
                                                        marginLeft: "3%",
                                                        overflowY: 'auto',
                                                        position: 'relative',
                                                        overflowX: "40%",
                                                        maxHeight: '200px',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap'
                                                    }}>
                                                        <Breadcrumb>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>(VL) Check Box</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'vl_check_box')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('vl_check_box', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Yes</Option>
                                                                        <Option value="no">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>(ART) Check Box</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'art_check_box')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('art_check_box', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Yes</Option>
                                                                        <Option value="no">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Caregiver Sex</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'caregiver_sex')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('caregiver_sex', value)}
                                                                        style={{ width: 100 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="male">Male</Option>
                                                                        <Option value="female">Female</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Client Result</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'client_result')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('client_result', value)}
                                                                        style={{ width: 100 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="Positive">Positive</Option>
                                                                        <Option value="Negative">Negative</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Approved Family</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'approved_family')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('approved_family', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="Yes">Yes</Option>
                                                                        <Option value="No">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Index Check Box</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'index_check_box')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('index_check_box', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="1">True</Option>
                                                                        <Option value="0">False</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Active on Treatment</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'active_on_treatment')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('active_on_treatment', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Yes</Option>
                                                                        <Option value="no">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Biological Children</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'biological_children')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('biological_children', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Yes</Option>
                                                                        <Option value="no">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>(TPT) Client Initiated</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'tpt_client_initiated')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('tpt_client_initiated', value)}
                                                                        style={{ width: 100 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Initiated</Option>
                                                                        <Option value="null">N/A</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>(TPT) Client Eligibility</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'tpt_client_eligibility')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('tpt_client_eligibility', value)}
                                                                        style={{ width: 100 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Eligible</Option>
                                                                        <Option value="null">N/A</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Viral Load Results on File</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'viral_load_results_on_file')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('viral_load_results_on_file', value)}
                                                                        style={{ width: 100 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Positive</Option>
                                                                        <Option value="no">Negative</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>Is the Biological mother of child living with (HIV)</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'is_biological_mother_of_child_living_with_hiv')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('is_biological_mother_of_child_living_with_hiv', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="yes">Yes</Option>
                                                                        <Option value="no">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                        </Breadcrumb>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Please note that the following sub population filters are not a 
                                            requirement for now on the advanced search since we have them on the registers
                                             <div className="col-lg-3.5"><br />
                                                <Alert
                                                    banner
                                                    message={
                                                        <h4>
                                                            <b style={{ fontSize: '15px', color: 'black' }}>
                                                                <InfoCircleOutlined /> Filter by Sub Populations
                                                            </b>
                                                        </h4>
                                                    }
                                                />
                                                <br />
                                                <br />
                                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <div style={{
                                                        marginLeft: "3%",
                                                        overflowY: 'auto',
                                                        position: 'relative',
                                                        overflowX: "40%",
                                                        maxHeight: '200px',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap'
                                                    }}>
                                                        <Breadcrumb>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>HEI</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'hei')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('hei', value)}
                                                                        style={{ width: 200 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="true">HEI</Option>
                                                                        <Option value="0">Non HEI</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>CALHIV</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'calhiv')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('calhiv', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="true">Yes</Option>
                                                                        <Option value="0">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>CWLHIV</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'cwlhiv')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('cwlhiv', value)}
                                                                        style={{ width: 100 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="true">Yes</Option>
                                                                        <Option value="0">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>AGYW</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'agyw')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('agyw', value)}
                                                                        style={{ width: 100 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="true">Yes</Option>
                                                                        <Option value="0">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>CSV</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'csv')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('csv', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="true">Yes</Option>
                                                                        <Option value="0">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                            <Breadcrumb.Item>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <h4 style={{ fontSize: '12px', color: 'black' }}>CSFW</h4>
                                                                    <Select
                                                                        value={
                                                                            Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'csfw')?.value || ''
                                                                        }
                                                                        onChange={(value) => handleFilterChange('csfw', value)}
                                                                        style={{ width: 80 }}>
                                                                        <Option value="">All</Option>
                                                                        <Option value="true">Yes</Option>
                                                                        <Option value="0">No</Option>
                                                                    </Select>
                                                                </div>
                                                            </Breadcrumb.Item>
                                                        </Breadcrumb>
                                                    </div>
                                                </div>
                                            </div> */}

                                        </div>
                                    </div>
                                    <br />
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h4
                                                    className="text-left"
                                                    style={{
                                                        textAlign: "left",
                                                        textTransform: "capitalize",
                                                        marginRight: "5%",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    <b>{districts[clients.district - 1]} District Households Screened</b>
                                                </h4>
                                                <div
                                                    className="toolbar"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "flex-end",
                                                        alignItems: windowWidth <= 991 ? "flex-start" : "flex-end",
                                                    }}
                                                >

                                                    <h4><b>You can globally search here:</b></h4>
                                                    <Input
                                                        placeholder="Search"
                                                        value={searchInput}
                                                        onChange={handleSearchChange}
                                                        prefix={<span><SearchOutlined style={{ color: "#11B8CC" }} /></span>}
                                                        style={{
                                                            marginLeft: windowWidth <= 991 ? 0 : 150,
                                                            marginTop: windowWidth <= 991 ? 20 : 0,
                                                            width: windowWidth <= 991 ? "100%" : 300,
                                                        }}
                                                        size="large"
                                                    />
                                                </div>
                                                <br />
                                                <div className="table-responsive" style={{
                                                    maxWidth: '200%'
                                                }}>

                                                    {loading ? (
                                                        <div style={{
                                                            display: 'flex', justifyContent: 'center',
                                                            alignItems: 'center', flexDirection: 'column', height:
                                                                '100%',
                                                        }}>
                                                            <Spin />
                                                            <p><b style={{ fontWeight: "500" }}>Please wait while we process your request...</b></p>
                                                        </div>
                                                    ) : error ? (
                                                        <Alert message={error} type="error" />
                                                    ) : (
                                                        <Table
                                                            columns={columns}
                                                            dataSource={displayHouseholdsData}
                                                            pageSizeOptions={[10, 20, 50, 100]}
                                                            pagination={{
                                                                pageSize: itemsPerPage,
                                                                showQuickJumper: true,
                                                                showTotal: (total, range) => {
                                                                    const message = `${range[0]}-${range[1]} of ${totalRecords} Total Households Screened`;
                                                                    return (
                                                                        <>
                                                                            <div style={{ fontWeight: "500" }}>
                                                                                <b>{visibleRecordsMessage}</b>
                                                                            </div>
                                                                            <div>
                                                                                <b>{message}</b>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                },

                                                                onShowSizeChange: (current, size) => setItemsPerPage(size),
                                                                total: totalPages * itemsPerPage,
                                                                current: currentPage,
                                                                onChange: (page) => setCurrentPage(page),
                                                            }}
                                                            onRow={(record) => ({
                                                                onClick: () => openHouseholdRowClick(record),
                                                                onMouseEnter: (event) => {
                                                                    event.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
                                                                    event.currentTarget.style.cursor = "pointer";
                                                                },
                                                                onMouseLeave: (event) => {
                                                                    event.currentTarget.style.backgroundColor = "";
                                                                    event.currentTarget.style.cursor = "";
                                                                },
                                                            })}
                                                        />)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        </>
    );
};

export default React.memo(AdvancedSearch);
