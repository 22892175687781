import React, { useMemo } from "react";
import Vcascreening from "../components/vcaForms/vcascreening";
import { useLocation, useNavigate } from "react-router-dom";
import Vcavisits from "../components/vcaForms/vcavisits";
import Vcacaseplans from "../components/vcaForms/vcacaseplans";
import HivAssessmentBelowFithteen from "../components/vcaForms/hivassessmentsBelow15";
import HivAssessmentAboveFithteen from "../components/vcaForms/hivassessmentsAbove15";
import Vassessment from "../components/vcaForms/vassessment";
import Safetyplans from "../components/vcaForms/safetyplans";
import AllVServiceReports from "../components/vcaForms/allVServiceReports";
import AllVReferredServices from "../components/vcaForms/allVReferredServices";
import VersionBanner from "./versionBanner";

const Child = ({ history }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { child } = location?.state;

  //Display active household_id and VCA full names on top of the page
  const { firstname = "", lastname = "" } = child || {};
  const household_id = location?.state?.child?.household_id || "";
  const case_status = location?.state?.child?.case_status || "";
  const reason = location?.state?.child?.reason || "";
  const de_registration_date = location?.state?.child?.de_registration_date || "";



  const uid = location?.state?.child?.uid || "";

  const token = localStorage.getItem("token");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );


  const handleClick = () => {
    navigate(-1);
  };

  const handleFlagButtonClick = (e) => {
    e.preventDefault();
    navigate("/addcomment", {
      state: {
        valuesObject: {
          formname: "",
          hhid: child?.household_id ? child?.household_id : "Not Available",
          vcaid: child?.json?.attributes?.unique_id
            ? child?.json?.attributes?.unique_id
            : "Not Available",
          casephone: child?.phone ? child?.phone : "Not Available",
          casename: child?.caseworker_name
            ? child?.caseworker_name
            : "Not Available",
          facility: child?.facility ? child?.facility : "Not Available",
        },
      },
    });
  };

  return (
    <>
    <VersionBanner />
    <div className="col-lg col-md">
      <div className="row">

        <div className="col-3">
          <div className="card" style={{ backgroundColor: "#E6F7FF", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
            <div className="card-header">
              <div className="card-text">
                <h5 style={{ fontWeight: "bold" }} className="card-title">
                  {firstname + " " + lastname}
                </h5>
                <h6 className="card-category" style={{ color: "black" }}>Household ID : {household_id}</h6>
                <h6 className="card-category" style={{ color: "black" }}>VCA ID : {uid}</h6>
                <h6 className="card-category" style={{ color: "black" }}>Age : {child?.age} years old</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card" style={{ backgroundColor: "#A9DFBF", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
            <div className="card-header">
              <div className="card-text">
                <h5 style={{ fontWeight: "bold" }} className="card-title">
                  Graduation Information
                </h5>
                <h6 className="card-category" style={{ color: "black" }}>Case status: {case_status || "N/A"}</h6>
                <h6 className="card-category" style={{ color: "black" }}>Reason: {reason || "N/A"}</h6>
                <h6 className="card-category" style={{ color: "black" }}>De-registration Date: {de_registration_date || "N/A"}</h6>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div className="card">
        <div className="card-header card-header-tabs card-header-info">
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <span className="nav-tabs-title">Forms:</span>
              <ul className="nav nav-tabs" data-tabs="tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    href="#vcascreening"
                    data-toggle="tab"
                  >
                    <i className="material-icons">people</i> VCA Screening
                    <div className="ripple-container" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#visits" data-toggle="tab">
                    <i className="material-icons">arrow_right</i> Visits
                    <div className="ripple-container" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#caseplans" data-toggle="tab">
                    <i className="material-icons">article</i> Case Plans
                    <div className="ripple-container" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#vassessment" data-toggle="tab">
                    <i className="material-icons">sick</i> Vulnerability
                    Assessment <div className="ripple-container" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#hivassessmentAbove15" data-toggle="tab">
                    <i className="material-icons">healing</i> HIV Risk Assessment (Above 15)
                    <div className="ripple-container" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#hivassessmentBelow15" data-toggle="tab">
                    <i className="material-icons">healing</i> HIV Risk Assessment (Below 15)
                    <div className="ripple-container" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#safetyplans" data-toggle="tab">
                    <i className="material-icons">article</i>
                    Child Safety Plans
                    <div className="ripple-container" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#services" data-toggle="tab">
                    <i className="material-icons">local_hospital</i>All Services
                    <div className="ripple-container" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#referred_services" data-toggle="tab">
                    <i className="material-icons">local_hospital</i>Referred Services
                    <div className="ripple-container" />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#flag"
                    onClick={handleFlagButtonClick}
                  >
                    <i className="material-icons">flag</i> Flag this record
                    <div className="ripple-container" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="tab-content">
            <div className="tab-pane active" id="vcascreening">
              <Vcascreening person={child} />
            </div>
            <HivAssessmentAboveFithteen />
            <HivAssessmentBelowFithteen />
            <Vassessment />
            <Vcavisits />
            <Vcacaseplans />
            <Safetyplans />
            <AllVServiceReports />
            <AllVReferredServices />
          </div>

          <button onClick={handleClick} className="btn btn-info pull-right">
            Back
          </button>
        </div>
      </div>
    </div>
    </>
  );
};

export default Child;
