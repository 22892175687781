import BounceLoader from "react-spinners/BounceLoader";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import VersionBanner from "../pages/versionBanner";

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    setLoading(1);
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_SERVER}/user/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("isAdmin", res.data.isAdmin);
        localStorage.setItem(
          "username",
          res.data.firstname + " " + res.data.lastname
        );
        localStorage.setItem("email", res.data.email);
        localStorage.setItem("phone", res.data.phone);

        localStorage.setItem("roles", res.data.roles);
        navigate("/districts");
      })
      .catch((err) => {
        setLoading(0);
        if (err.response) {
          if (err.response.status === 401) {
            message.error("Incorrect email or password.");
          } else if (err.response.status === 404) {
            message.error("No user found with the entered details.");
          } else {
            // Check if err.response.data is defined before accessing it
            if (err.response.data && err.response.data.message) {
              message.error(err.response.data.message);
            } else {
              message.error("An unexpected error occurred. Please try again.");
            }
          }
        } else if (err.request) {
          message.error("Network error. Please check your internet connection.");
        } else {
          message.error("An unexpected error occurred. Please try again.");
        }
      });      
    };
    
  return (
    <>
      <VersionBanner />
      <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top text-white">
        <div className="container">
          <div className="navbar-wrapper">
            <a href="/#" className="navbar-brand">
              ECAP II DQA Dashboard
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            aria-controls="navigation-index"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="navbar-toggler-icon icon-bar" />
            <span className="navbar-toggler-icon icon-bar" />
            <span className="navbar-toggler-icon icon-bar" />
          </button>
          <div className="collapse navbar-collapse justify-content-end">
            <ul className="navbar-nav">
              <li className="nav-item  active ">
                <a href="/#" className="nav-link">
                  <i className="material-icons">fingerprint</i>
                  Login
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* End Navbar */}
      <div className="wrapper wrapper-full-page">
        <div
          className="page-header login-page header-filter"
          filter-color="black"
          style={{
            backgroundImage:
              "url(https://app-express.net/images/background.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
                <form className="form" onSubmit={handleSubmit}>
                  <div className="card card-login">
                    <div className="card-header card-header-rose text-center">
                      <h4 className="card-title">Login</h4>
                    </div>
                    <div className="card-body ">
                      {/* Display the error message */}
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
                      <p className="card-description text-center">
                        ECAP II Login
                      </p>

                      <span className="bmd-form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="material-icons">email</i>
                            </span>
                          </div>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email..."
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </div>
                      </span>
                      <span className="bmd-form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="material-icons">lock_outline</i>
                            </span>
                          </div>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password..."
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                          />
                        </div>
                      </span>
                    </div>

                    <div className="card-footer justify-content-center">
                      <button
                        className="btn btn-rose btn-link btn-lg"
                        type="submit"
                      >
                        Lets Go
                      </button>
                      {loading ? (
                        <div style={{ float: "right" }}>
                          <BounceLoader
                            size={35}
                            color={"#808080"}
                            loading={true}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;