const Details = ({ formName, data, modal }) => {
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className="col">
      <div
        className="modal fade"
        id={modal}
        role="modal"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header card-header card-header-success">
              <h4 className="modal-title">{formName}</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                <i className="material-icons">clear</i>
              </button>
            </div>
            <div className="modal-body">
              <table className="table">
                <tbody>
                  {Object.keys(data).map((key, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span
                            className="pull-left"
                            style={{ fontWeight: "bold" }}
                          >
                            {capitalizeFirst(key)}{" "}
                          </span>{" "}
                          <span className="float-right">__</span>
                        </td>
                        <td>
                          <span className="float-right">
                            {capitalizeFirst(data[key])}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger btn-link"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
