import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { Table, Spin, Alert, Input, Button, Tooltip, Breadcrumb, Select, Tag, Collapse, Space, Badge } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../context/appContext";
import { Chip, debounce } from "@mui/material";
import {
  FileExcelFilled,
  FilePdfFilled, SearchOutlined,
  PlusOutlined, MinusOutlined, InfoCircleOutlined
} from "@ant-design/icons";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';
import VersionBanner from "./versionBanner";
import { de } from "date-fns/locale";

const Vcas = () => {
  const token = localStorage.getItem("token");
  const clients = useContext(AppContext);
  const navigate = useNavigate();

  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [vcaChildren, setVcaChildren] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [genderFilter, setGenderFilter] = useState("");
  const [vcaAgeFilter, setVcaAgeFilter] = useState(null);
  const [lastServiceDateFilter, setLastServiceDateFilter] = useState('');
  const [lastServiceDateFilterSelectedValue, setLastServiceDateFilterSelectedValue] = useState(null);

  const [filters, setFilters] = useState([
    { key: "agyw", value: "" },
    { key: "hei", value: "" },
    { key: "csv", value: "" },
    { key: "cfsw", value: "" },
    { key: "calhiv", value: "" },
    { key: "cwlhiv", value: "" },
    { key: "vl_suppressed", value: "" },
    { key: "last_service_date", value: "" }
  ])

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  // If the field on a record is empty (for example, last visit date), render
  // 'Not Provided' instead of an empty field
  const renderText = (text) => {
    return text == null || text === '' ? 'Not Provided' : text;
  };


  const { Panel } = Collapse;

  const columns = [
    {
      title: "Screened",
      dataIndex: "screened",
      key: "screened",
      width: 50,
      render: (value) => {
        if (value === null || value === undefined) {
          <i className="material-icons">
            {value === true ? "check" : "close"}
          </i>;
        }
        return (
          <a className="btn btn-link btn-success btn-just-icon like">
            <i className="material-icons">
              {value === true ? "close" : "check"}
            </i>
          </a>
        );
      },
    },
    {
      title: "VCA Id",
      dataIndex: "uid",
      key: "uid",
      width: 200,
    },
    {
      title: "Facility",
      dataIndex: "facility",
      key: "facility",
      width: 200,
    },
    {
      title: "Birth Date",
      dataIndex: "birthdate",
      key: "birthdate",
      width: 200,
    },
    {
      title: "Gender",
      dataIndex: "vca_gender",
      width: 200,
    },
    {
      title: (
        <span>
          <br />
          <Tag color="cyan"></Tag>
          Last Service Date{' '}
        </span>
      ),
      dataIndex: "last_service_date",
      key: "last_service_date",
      width: 200,
      render: (value, record) => {
        const dueDateBadge = calculateDueDateBadge(record.last_service_date);
        let badgeStyle = {};

        if (dueDateBadge) {
          if (dueDateBadge.includes('90+')) {
            badgeStyle.backgroundColor = 'red';
          } else if (dueDateBadge.includes('60+')) {
            badgeStyle.backgroundColor = 'red';
          } else if (dueDateBadge.includes('30+')) {
            badgeStyle.backgroundColor = 'orange';
          } else if (dueDateBadge.includes('7+')) {
            badgeStyle.backgroundColor = '#007F80';
          }
        }

        return (
          <div>
            <span>{value}</span>
            {dueDateBadge && (
              <Badge
                count={dueDateBadge}
                style={{ ...badgeStyle, marginLeft: '8px' }}
              />
            )}
          </div>
        );
      },
    },
    {
      title: (
        <span>
          Virally Suppressed{' '}
        </span>
      ),
      dataIndex: "vl_suppressed",
      key: "vl_suppressed",
      width: 200,
      render: renderText,
    },
    {
      title: "Last edited",
      dataIndex: "date_edited",
      render: renderText,
      width: 200,
    },
    {
      title: "Actions",
      dataIndex: "child",
      width: 200,
      render: (child) => {
        return (
          <button
            className="btn btn-link btn-info btn-just-icon like"
            onClick={() => handleClick(child)}
          >
            <i className="material-icons">visibility</i>
          </button>
        );
      },
    },
  ];

  // Function to calculate the due date badge
  const calculateDueDateBadge = (lastServiceDate) => {
    if (!lastServiceDate) return null;

    const today = new Date();
    const serviceDate = new Date(lastServiceDate);

    const daysDifference = Math.floor((today - serviceDate) / (1000 * 60 * 60 * 24));

    if (daysDifference >= 90) {
      return '90+ Days Due';
    } else if (daysDifference >= 60) {
      return '60+ Days Due';
    } else if (daysDifference >= 30) {
      return '30+ Days Due';
    } else if (daysDifference >= 7) {
      return '7+ Days Due';
    }

    return null;
  };

  const handleClick = (child) => {
    navigate("/child", { state: { child } });
  };


  const handleAdvancedSearchClick = () => {
    navigate("/advanced-search");
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  //enable filter based on the gender of a VCA 
  const { Option } = Select;

  const handleGenderFilterChange = (value) => {
    setGenderFilter(value);
  };

  const handleFilterChange = (filterType, value) => {
    // Directly use the value provided, e.g., '7days'
    setFilters(prevFilters => {
      const existingFilterIndex = prevFilters.findIndex(filter => filter.key === filterType);
      if (existingFilterIndex > -1) {
        const newFilters = [...prevFilters];
        newFilters[existingFilterIndex].value = value;
        return newFilters;
      }
      return [...prevFilters, { key: filterType, value }];
    });
  };

  const handleFilterReset = () => {

    setLastServiceDateFilter('');
    setLastServiceDateFilterSelectedValue(null);

    // Reset other filters including sub-pop filters
    setFilters([
      { key: "agyw", value: "" },
      { key: "hei", value: "" },
      { key: "csv", value: "" },
      { key: "cfsw", value: "" },
      { key: "calhiv", value: "" },
      { key: "cwlhiv", value: "" },
      { key: "last_service_date", value: "" },
      { key: "vca_gender", value: "" },
      { key: "vl_suppressed", value: "" },
      { key: "age", value: "" }
    ]);

    setVcaAgeFilter(null);
    setGenderFilter('');
  };

  const handleSubPopFilterChange = (filterType, value) => {
    setFilters(prevFilters => {
      return prevFilters.map(filter => {
        if (filter.key === filterType) {
          return { ...filter, value: value };
        }
        return filter;
      });
    });
  };

  const handleBirthdateFilterChange = (value) => {

    const ageValue = parseInt(value, 10);

    if (!isNaN(ageValue)) {
      setVcaAgeFilter(ageValue);
    } else {
      setVcaAgeFilter(null);
    }
  };


  const debouncedHandleSearch = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedHandleSearch(searchInput);
  }, [searchInput, debouncedHandleSearch]);

  const districts = [
    "Kabwe",
    "Kapiri Mposhi",
    "Mkushi",
    "Chibombo",
    "Chisamba",
    "Chikankata",
    "Mumbwa",
    "Sinazongwe",
    "Kalomo",
    "Monze",
    "Namwala",
    "Choma",
    "Mazabuka",
    "Livingstone",
    "Serenje",
    "Kazungula",
  ];

  const buildApiRequestUrl = () => {
    const baseUrl = `${process.env.REACT_APP_SERVER}/child/district/${clients.district}`;
    const queryParams = new URLSearchParams({
      page: currentPage,
      itemsPerPage,
      globalSearch: encodeURIComponent(globalSearch),
      vca_gender: encodeURIComponent(genderFilter),
      case_status: 'Active',
      de_registration_date: 'null', // Define de_registration_date
      de_registration_reason: 'null' // Define de_registration_reason
    });

    // Reset the filters
    if (lastServiceDateFilter) {
      queryParams.set('last_service_date', lastServiceDateFilter);
    }

    // The filters array now contains strings like '7days', '30days', etc.,
    // for the last_service_date which the backend expects.
    if (filters && Array.isArray(filters)) {
      filters.forEach(filter => {
        if (filter?.value) {
          queryParams.set(filter.key, filter.value);
        }
      });
    }

    if (vcaAgeFilter !== null) {
      queryParams.set('age', vcaAgeFilter);
    }

    return `${baseUrl}?${queryParams.toString()}`;
  };

  const fetchVcasByDistrict = useCallback(async () => {
    setLoading(true);
    const requestUrl = buildApiRequestUrl();

    try {

      const response = await axios.get(requestUrl, options);

      setVcaChildren(response.data.data);
      setItemsPerPage(itemsPerPage);
      setTotalPages(response.data.totalPages);

      // Filtering logic corrected
      const filteredData = response.data.data.filter(record => record.case_status === 'Active');

      setVcaChildren(filteredData);

      setVcaChildren(filteredData.sort((a, b) => moment(b.last_service_date).diff(moment(a.last_service_date))));
      setIsDataReadyForExport(true);

    } catch (err) {
      const errorMessage = err.response?.data?.message;
      setError(errorMessage);
    }

    setLoading(false);

  }, [clients.district, currentPage, globalSearch, itemsPerPage, filters, options, genderFilter, vcaAgeFilter]);



  useEffect(() => {
    fetchVcasByDistrict();
  }, [currentPage, itemsPerPage, filters, fetchVcasByDistrict]);

  const displayVcasData = Array.isArray(vcaChildren)
    ? vcaChildren

      .map((child) => {
        if (!child) {
          return null;
        }
        const {
          firstname,
          lastname,
          screened,
          facility,
          uid,
          birthdate,
          vca_gender,
          date_edited,
          last_service_date,
          visit_date,
          vl_suppressed,
          hei,
          calhiv,
          cwlhiv,
          agyw,
          csv,
          csfw,
          household_id,
          relation,
          caregiver_name,
          caseworker_name,
          homeaddress,
          age_group,
          is_hiv_positive,
          caseworker_phone,
          caregiver_phone,
          member_type,
          case_status
        } = child;

        return {
          screened,
          fullName: `${firstname} ${lastname}`,
          facility,
          uid,
          birthdate: moment(birthdate && birthdate)?.format('DD-MM-YYYY'),
          vca_gender,
          date_edited,
          last_service_date,
          visit_date,
          vl_suppressed,
          hei,
          calhiv,
          cwlhiv,
          agyw,
          csv,
          csfw,
          household_id,
          relation,
          caregiver_name,
          caseworker_name,
          homeaddress,
          age_group,
          is_hiv_positive,
          caseworker_phone,
          caregiver_phone,
          member_type,
          case_status,
          child,// Add the entire child object and
          // pass its state to the Screening VCA Index page
        };
      })
      .filter((item) => item !== null)
    : [];


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const exportToCSV = () => {
    const headers = columns
      .filter(column => column.dataIndex !== "reason") 
      .map(column => column.title);
    const csvData = [headers].concat(
      vcaChildren.map(house => {
        // Filter out the reason attribute from each house
        const filteredHouse = Object.fromEntries(
          Object.entries(house).filter(([key]) => key !== "reason")
        );
        return Object.values(filteredHouse);
      })
    );

    const csvContent = Papa.unparse(csvData);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "vcas_data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //Export to PDF
  const exportToPDF = () => {
    const headers = columns
      .filter(column => column.dataIndex !== "reason") 
      .map(column => column.title);
    const body = displayVcasData.map(row =>
      columns
        .filter(column => column.dataIndex !== "reason") 
        .map(column => (row[column.dataIndex] === null ? "" : row[column.dataIndex]))
    );

    const doc = new jsPDF("l", "mm", "a4");
    doc.autoTable({
      head: [headers],
      body: body,
      theme: "striped",
      styles: { fontSize: 8 },
    });
    doc.save("vcas_data.pdf");
  };

  const handleExportToCSV = async () => {
    if (vcaChildren?.length === 0) {
      await fetchVcasByDistrict(currentPage);
    }
    exportToCSV();
  };

  const handleExportToPDF = async () => {
    if (vcaChildren?.length === 0) {
      await fetchVcasByDistrict(currentPage);
    }
    exportToPDF();
  };



  const cellStyle = {
    border: '1px solid black',
    padding: '10px',
    textAlign: 'left',
  };

  const renderSubPopulationsText = (text) => {
    if (text === "0") {
      return "No";
    } else if (text === "true") {
      return "Yes";
    } else {
      return text;
    }
  };

  const renderSubPopulationsTable = (record) => {
    return (
      <div style={{ display: 'flex', width: '40%' }}>

        <div style={{ flex: '0 0 50%' }}>
          <p style={{ margin: 0, fontSize: "17px" }}>
            <strong>Caregiver Phone:</strong> {renderSubPopulationsText(record.caregiver_phone)}<br />
            <strong>Age Group:</strong> {renderSubPopulationsText(record.age_group)}<br />
            <strong>HIV Status:</strong> {renderSubPopulationsText(record.is_hiv_positive)}<br />
            <strong>Caregiver Phone:</strong> {renderSubPopulationsText(record.caregiver_phone)}<br />
            <strong>Caseworker Phone:</strong> {renderSubPopulationsText(record.caseworker_phone)}<br />
            <strong>Member Type:</strong> {renderSubPopulationsText(record.member_type)}<br />
          </p>
        </div>

        <div style={{ flex: '0 0 50%', marginRight: '50px' }}>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tbody>
              <tr>
                <td style={cellStyle}><strong>HEI:</strong> {renderSubPopulationsText(record.hei)}</td>
                <td style={cellStyle}><strong>CALHIV:</strong> {renderSubPopulationsText(record.calhiv)}</td>
                <td style={cellStyle}><strong>CWLHIV:</strong> {renderSubPopulationsText(record.cwlhiv)}</td>
              </tr>
              <tr>
                <td style={cellStyle}><strong>AGYW:</strong> {renderSubPopulationsText(record.agyw)}</td>
                <td style={cellStyle}><strong>CSV:</strong> {renderSubPopulationsText(record.csv)}</td>
                <td style={cellStyle}><strong>CSFW:</strong> {renderSubPopulationsText(record.csfw)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ flex: '0 0 50%', marginRight: '20px' }}>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tbody>
              <td>
                <th>Virally Suppressed:</th>

                {record.vl_suppressed ? (
                  record.vl_suppressed === 'Suppressed' ? (
                    <Tag color="cyan" style={{ fontWeight: "500" }}>
                      {record.vl_suppressed}
                    </Tag>
                  ) : (
                    <Tag color="red" style={{ fontWeight: "500" }}>
                      {record.vl_suppressed}
                    </Tag>

                  )
                ) : (
                  <Tag color="gray" style={{ fontWeight: "500" }}>
                    Unknown
                  </Tag>
                )}
              </td>
            </tbody>
          </table>
        </div>

      </div>
    );
  };

  return (
    <>
      <VersionBanner />
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header-info">
                  <div className="card-icon">
                    <i className="material-icons">person</i>
                  </div>
                  <h4 className="card-title" style={{ fontWeight: "500" }}>
                    {districts[clients.district - 1]} District VCA`s Screened
                  </h4>
                </div>
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: windowWidth <= 991 ? "column" : "row",
                      alignItems: "center",
                    }}>
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <Chip
                          label="Households Page"
                          variant="outlined"
                          color="primary"
                          style={{
                            backgroundColor: "#e0e0e0",
                            color: "black",
                            fontWeight: "500",
                            marginLeft: "1%",
                          }}
                        ></Chip>
                        &nbsp;&nbsp;&nbsp;
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <h1
                          style={{
                            fontSize: "17px",
                            color: "black",
                            fontWeight: "500",
                          }}
                        >
                          Navigate to:
                        </h1>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/children">
                          <Chip
                            label="VCA`s"
                            variant="outlined"
                            color="primary"
                            clickable
                            style={{
                              backgroundColor: "#e0e0e0",
                              color: "black",
                              fontWeight: "500",
                            }}
                          ></Chip>
                        </Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Link to="/mothers">
                          <Chip
                            label="Mothers"
                            variant="outlined"
                            color="primary"
                            clickable
                            style={{
                              backgroundColor: "#e0e0e0",
                              color: "black",
                              fontWeight: "500",
                            }}
                          ></Chip>
                        </Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Tooltip title="Export to CSV">
                          <Button
                            icon={<FileExcelFilled twoToneColor="#28A745" />}
                            disabled={!isDataReadyForExport}
                            size="large"
                            style={{
                              marginRight: 8,

                              background:
                                "linear-gradient(45deg, #28A745 30%, #FF8E53 90%)",
                              color: "white",
                              boxShadow: "0 3px 5px 2px rgba(40, 167, 69, 0.3)",
                            }}
                            onClick={handleExportToCSV}
                          />
                        </Tooltip>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Tooltip title="Export to PDF">
                          <Button
                            size="large"
                            icon={<FilePdfFilled />}
                            disabled={!isDataReadyForExport}
                            style={{
                              background: "#DC3545",
                              color: "white",
                              boxShadow: "0 3px 5px 2px rgba(220, 53, 69, 0.3)",
                            }}
                            onClick={handleExportToPDF}
                          />
                        </Tooltip>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <Button
                          onClick={handleAdvancedSearchClick}
                          size="large"
                          style={{
                            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                            color: 'white',
                            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, 0.3)',
                            display: 'block',
                            fontWeight: "500",
                            flexDirection: windowWidth <= 991 ? "column" : "row",
                            float: "left"
                          }}
                        >
                          Advanced Search
                        </Button>
                      </Breadcrumb.Item>
                      <div
                        className="toolbar"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <div className="col-lg-3.1"><br />
                          <Alert
                            banner
                            message={<h4><b style={{ fontSize: '15px', color: 'black' }}><InfoCircleOutlined /> Filter
                              <strong> {districts[clients.district - 1]} District</strong> Households by Sub Populations</b></h4>}
                          />
                          <br />
                          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <div >
                              <Breadcrumb>
                                <Breadcrumb.Item>
                                  <div style={{ marginBottom: 10 }}>
                                    <h4 style={{ fontSize: '12px', color: 'black' }}>HEI</h4>
                                    <Select
                                      value={
                                        Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'hei')?.value || ''
                                      }
                                      onChange={(value) => handleSubPopFilterChange('hei', value)}
                                      style={{ width: 90 }}>
                                      <Option value="">All</Option>
                                      <Option value="true">HEI</Option>
                                      <Option value="0">Non HEI</Option>
                                    </Select>
                                  </div>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                  <div style={{ marginBottom: 10 }}>
                                    <h4 style={{ fontSize: '12px', color: 'black' }}>CALHIV</h4>
                                    <Select
                                      value={
                                        Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'calhiv')?.value || ''
                                      }
                                      onChange={(value) => handleSubPopFilterChange('calhiv', value)}
                                      style={{ width: 70 }}>
                                      <Option value="">All</Option>
                                      <Option value="true">Yes</Option>
                                      <Option value="0">No</Option>
                                    </Select>
                                  </div>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                  <div style={{ marginBottom: 10 }}>
                                    <h4 style={{ fontSize: '12px', color: 'black' }}>CWLHIV</h4>
                                    <Select
                                      value={
                                        Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'cwlhiv')?.value || ''
                                      }
                                      onChange={(value) => handleSubPopFilterChange('cwlhiv', value)}
                                      style={{ width: 70 }}>
                                      <Option value="">All</Option>
                                      <Option value="true">Yes</Option>
                                      <Option value="0">No</Option>
                                    </Select>
                                  </div>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                  <div style={{ marginBottom: 10 }}>
                                    <h4 style={{ fontSize: '12px', color: 'black' }}>AGYW</h4>
                                    <Select
                                      value={
                                        Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'agyw')?.value || ''
                                      }
                                      onChange={(value) => handleSubPopFilterChange('agyw', value)}
                                      style={{ width: 70 }}>
                                      <Option value="">All</Option>
                                      <Option value="true">Yes</Option>
                                      <Option value="0">No</Option>
                                    </Select>
                                  </div>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                  <div style={{ marginBottom: 10 }}>
                                    <h4 style={{ fontSize: '12px', color: 'black' }}>CSV</h4>
                                    <Select
                                      value={
                                        Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'csv')?.value || ''
                                      }
                                      onChange={(value) => handleSubPopFilterChange('csv', value)}
                                      style={{ width: 70 }}>
                                      <Option value="">All</Option>
                                      <Option value="true">Yes</Option>
                                      <Option value="0">No</Option>
                                    </Select>
                                  </div>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                  <div style={{ marginBottom: 10 }}>
                                    <h4 style={{ fontSize: '12px', color: 'black' }}>CSFW</h4>
                                    <Select
                                      value={
                                        Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'csfw')?.value || ''
                                      }
                                      onChange={(value) => handleSubPopFilterChange('csfw', value)}
                                      style={{ width: 70 }}>
                                      <Option value="">All</Option>
                                      <Option value="true">Yes</Option>
                                      <Option value="0">No</Option>
                                    </Select>
                                  </div>
                                </Breadcrumb.Item>
                              </Breadcrumb>
                            </div>
                          </div>
                        </div>

                      </div>
                    </Breadcrumb>
                    <div
                      className="toolbar"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: windowWidth <= 991 ? "column" : "row",
                      }}
                    >
                      <Input
                        placeholder="You can search here..."
                        value={searchInput}
                        onChange={handleSearchChange}
                        prefix={
                          <span>
                            <SearchOutlined style={{ color: "#11B8CC" }} />
                          </span>
                        }
                        style={{
                          marginLeft: windowWidth <= 991 ? 0 : 150,
                          marginTop: windowWidth <= 991 ? 20 : 0,
                          width: windowWidth <= 991 ? "100%" : 300,
                        }}
                        size="large"
                      />
                    </div>
                  </div>
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <br />
                      <h1 style={{ fontSize: "17px", color: "black", fontWeight: "500", }}>
                        Reset All Filters Applied:
                      </h1>
                      <Button
                        type="default"
                        onClick={handleFilterReset}
                        style={{ marginLeft: 16 }}
                      >
                        Reset FIlters
                      </Button>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <div className="card-body">
                  <div
                    className="toolbar"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >

                    <div className="toolbar">

                      <Breadcrumb className="breadcrumb-toolbar">
                        <Breadcrumb.Item>
                          <span>
                            <h1 style={{ fontSize: "17px", color: "black", fontWeight: "500" }}>
                              Filter Last Service Date By:
                            </h1>
                            <Select
                              style={{ width: 200, marginLeft: 16 }}
                              onChange={(value) => {
                                setLastServiceDateFilterSelectedValue(value);
                                handleFilterChange('last_service_date', value);
                              }}
                              value={lastServiceDateFilterSelectedValue}
                              placeholder={<span style={{ fontWeight: 'bold', color: 'black' }}>Select date option</span>}
                            >
                              <Select.Option value="7days">Last 7 days</Select.Option>
                              <Select.Option value="30days">Last 30 days</Select.Option>
                              <Select.Option value="60days">Last 60 days</Select.Option>
                              <Select.Option value="90days">Last 90 days</Select.Option>
                            </Select>
                          </span>
                        </Breadcrumb.Item>
                        <br /><br /><br /><br />
                        <Breadcrumb.Item>
                          <h1 style={{ fontSize: "17px", color: "black", fontWeight: "500" }}>
                            Filter by Gender:{' '}
                          </h1>
                          <Select
                            value={genderFilter}
                            onChange={handleGenderFilterChange}
                            style={{ width: 200 }}
                          >
                            <Option value="">All</Option>
                            <Option value="male">Male</Option>
                            <Option value="female">Female</Option>
                          </Select>

                        </Breadcrumb.Item>
                        <Breadcrumb.Item style={{ flex: "1 1 25%", marginBottom: 10 }}>
                          <h1 style={{ fontSize: "17px", color: "black", fontWeight: "500" }}>
                            Virally Suppressed:{' '}
                          </h1>
                          <Select
                            value={
                              Array?.isArray(filters) && filters?.find((filter) => filter?.key === 'vl_suppressed')?.value || ''
                            }
                            onChange={(value) => handleSubPopFilterChange('vl_suppressed', value)}
                            style={{ width: 200 }}>
                            <Option value="">All</Option>
                            <Option value="Suppressed">Suppressed</Option>
                            <Option value="Not Suppressed">Not Suppressed</Option>
                          </Select>
                        </Breadcrumb.Item>
                        <br /><br /><br /><br />
                        <Breadcrumb.Item>
                          <h1 style={{ fontSize: "17px", color: "black", fontWeight: "500" }}>
                            Filter by age:{' '}
                            {vcaAgeFilter !== null ? (
                              <>
                                <Tag color="cyan" padding="400">{vcaAgeFilter} years</Tag>
                                <Button type="link" onClick={() => setVcaAgeFilter(null)}>
                                  Clear
                                </Button>
                              </>
                            ) : (
                              <Tag color="gray">Not filtered</Tag>
                            )}
                          </h1>
                          <Input
                            placeholder="Enter age in years e.g 2"
                            style={{ width: 200 }}
                            value={vcaAgeFilter === null ? '' : String(vcaAgeFilter)}
                            onChange={(e) => handleBirthdateFilterChange(e.target.value)}
                          />
                        </Breadcrumb.Item>

                      </Breadcrumb>
                    </div>
                  </div>

                  <div className="toolbar">
                    <div className="toolbar">
                      {searchInput && (
                        <><br />
                          <span style={{ color: "black", marginRight: 8, fontWeight: "500" }}>
                            <b>You Searched For:</b>
                          </span>
                          <Chip label={searchInput} style={{ color: "black", fontWeight: "500" }} />
                        </>
                      )}
                    </div>
                    <br />
                    <div
                      className="toolbar"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    ></div>
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <Spin />
                        <p>
                          <b style={{ fontWeight: "500" }}>
                            Please wait while we process your request...
                          </b>
                        </p>
                      </div>
                    ) : error ? (
                      <Alert message={error} type="error" />
                    ) : (
                      <Table
                        columns={columns}
                        dataSource={displayVcasData}
                        rowKey={(record, index) => index}
                        scroll={{ x: true }}
                        pageSizeOptions={[10, 20, 50, 100]}
                        pagination={{
                          pageSize: itemsPerPage,
                          showQuickJumper: true,
                          showSizeChanger: true,
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} Total VCA's Screened`,
                          onShowSizeChange: (current, size) =>
                            setItemsPerPage(size),
                          total: totalPages * itemsPerPage,
                          current: currentPage,
                          onChange: (page) => setCurrentPage(page),
                        }}
                        expandable={{
                          expandedRowRender: renderSubPopulationsTable,
                          rowExpandable: (record) => record.last_service_date !== null,
                          defaultExpandAllRows: true,
                          expandIcon: ({ expanded, onExpand, record, index }) => (
                            <Space>
                              {expanded ? (
                                <Tooltip title="Collapse">
                                  <MinusOutlined onClick={(e) => onExpand(record, e)} />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Expand" placement="right" open={index === 0}>
                                  <PlusOutlined onClick={(e) => onExpand(record, e)} />
                                </Tooltip>
                              )}
                            </Space>
                          ),
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Vcas);
