import React from 'react';
import { Descriptions, Card } from 'antd';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import VersionBanner from '../../pages/versionBanner';
import Title from 'antd/es/typography/Title';

const Vcascreening = () => {

  const location = useLocation();
  const child = location.state?.child;

  const { firstname = "", lastname = "" } = child || {};

  const capitalizeFirst = (str) => {
    if (typeof str !== 'string' || str.length === 0) {
      return str;
    }
    const firstLetter = str.charAt(0).toUpperCase();
    const restOfString = str.slice(1);
    return firstLetter + restOfString;
  };

  const displayBoolean = (value) => {
    if (value === 0 || value === false || value === 'false' || value === '0') {
      return 'False';
    } else if (value === 1 || value === true || value === 'true' || value === '1') {
      return 'True';
    }
    return '';
  };

  const renderValue = (value) => {
    if (value === null || value === undefined) {
      return 'Not Provided';
    }
    return value;
  };

  const vcaBaiscInfo = [
    {
      label: 'VCA ID',
      value: renderValue(capitalizeFirst(child?.uid)),
    },
    {
      label: 'Child Name',
      value: `${capitalizeFirst(firstname)} ${capitalizeFirst(lastname)}`,
    },
    {
      label: 'Date of Birth',
      value: renderValue(moment(child?.birthdate).format('DD/MM/YYYY')),
    },
    {
      label: 'VCA Gender',
      value: renderValue(capitalizeFirst(child?.vca_gender)),
    },
    {
      label: 'VCA Age',
      value: renderValue(child?.age),
    },
    {
      label: 'School',
      value: renderValue(capitalizeFirst(child?.school)),
    },
    {
      label: 'Home Address',
      value: renderValue(child?.home_address),
    },
    {
      label: 'Contact Number',
      value: renderValue(child?.contact_number),
    },
  ];

  const subPopulation = [
    {
      label: 'HEI',
      value: renderValue(displayBoolean(child?.hei)),
    },
    {
      label: 'CALHIV',
      value: renderValue(displayBoolean(child?.calhiv)),
    },
    {
      label: 'CWLHIV',
      value: renderValue(displayBoolean(child?.cwlhiv)),
    },
    {
      label: 'AGYW',
      value: (displayBoolean(child?.agyw)),
    },
    {
      label: 'CSV',
      value: renderValue(displayBoolean(child?.csv)),
    },
    {
      label: 'CSFW',
      value: renderValue(displayBoolean(child?.csfw)),
    }
  ];

  const vcaOtherInfo = [
    {
      label: 'Household Id',
      value: renderValue(child?.household_id),
    },
    {
      label: 'Caregiver Relationship',
      value: renderValue(capitalizeFirst(child?.caregiver_relationship)),
    },
    {
      label: 'Screening Location',
      value: renderValue(capitalizeFirst(child?.screening_location)),
    },
    {
      label: 'Is HIV Positive?',
      value: renderValue((child?.hiv_positive)),
    },
    {
      label: 'ART Number',
      value: renderValue(child?.art_number),
    },
    {
      label: 'Is Child`s caregiver an FSW?',
      value: renderValue((child?.is_the_child_caregiver_an_fsw)),
    },
    {
      label: 'Is the mother virally suppressed?',
      value: renderValue((child?.is_mother_virally_suppressed_wlhiv)),
    },
    {
      label: 'Is the mother adhering to treatment?',
      value: renderValue((child?.is_mother_adhering_to_treatment_wlhiv)),
    },
    {
      label: 'Has the child ever experienced sexual Violence?',
      value: renderValue((child?.child_ever_experienced_sexual_violence)),
    },
    {
      label: ' Is the biological child of mother living with HIV?',
      value: renderValue((child?.is_biological_child_of_mother_living_with_hiv)),
    }
  ];

  return (
    <>
      <VersionBanner />
      <div style={{ padding: '20px' }}>
        <Title level={3}>Child Primary Information</Title>
        <br />

        <Card
          bordered={true}
          title="VCA Details"
          style={{
            marginBottom: '20px',
            background: '#E6F7FF',
            border: '1px solid #91D5FF',
          }}
        >
          <Descriptions>
            {vcaBaiscInfo &&
              vcaBaiscInfo.map((item) => (
                <Descriptions.Item
                  label={item.label}
                  key={item.label}
                  style={{ color: '#1890FF' }}
                >
                  {item.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>

        <Card
          bordered={true}
          title="Other Details"
          style={{
            marginBottom: '20px',
            background: '#FFFBE6',
            border: '1px solid #FFE58F',
          }}
        >
          <Descriptions>
            {vcaOtherInfo &&
              vcaOtherInfo.map((item) => (
                <Descriptions.Item
                  label={item.label}
                  key={item.label}
                  style={{ color: '#FFC53D' }}
                >
                  {item.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>

        <Card
          bordered={true}
          title="Sub Population Details"
          style={{
            marginBottom: '20px',
            background: '#F6FFED',
            border: '1px solid #B7EB8F',
          }}
        >
          <Descriptions>
            {subPopulation &&
              subPopulation.map((item) => (
                <Descriptions.Item
                  label={item.label}
                  key={item.label}
                  style={{ color: '#52C41A' }}
                >
                  {item.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Card>
      </div>
    </>
  );
};

export default Vcascreening;
