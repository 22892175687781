import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Chip } from "@mui/material";
import "jspdf-autotable";
import {
  Table,
  Spin,
  Button,
  Tooltip,
  Breadcrumb,
} from "antd";
import {
  FileExcelFilled,
} from "@ant-design/icons";
import VersionBanner from "../../pages/versionBanner";

const HivAssessmentAboveFithteen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchVcaHivAssessment, setfetchedVcaHivAssessment] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);

  const location = useLocation();
  // const { uid } = location?.state;

  const { child } = location?.state;

  //Display active household_id and VCA full names on top of the page
  const { firstname = "", lastname = "" } = child || {};
  const uid = location?.state?.child?.uid || "";

  // const uid = location?.state?.child?.uid || "";

  const token = localStorage.getItem("token");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

   //If a field on a record is empty, render
  // 'Not Provided' instead of empty string
  const renderText = (text, dataIndex) => {
    if (text === null || text === undefined || (typeof text === 'string' && text.trim() === '')) {
      if (dataIndex === 'service_referred') {
        return 'Not Referred';
      } else if (dataIndex === 'institution') {
        return 'Not Specified';
      } else {
        return 'Not Provided';
      }
    } else {
      return text;
    }
  };

  const columns = [
    {
      title: 'Date Edited',
      dataIndex: 'date_edited',
      key: 'date_edited',
      width: 200,
      render: (text) => renderText(text, 'date_edited'),
    },
    {
      title: 'Caseworker Name',
      dataIndex: 'caseworker_name',
      key: 'caseworker_name',
      width: 200,
      render: (text) => renderText(text, 'caseworker_name'),
    },
    {
      title: 'Informed Consent',
      dataIndex: 'informed_consent',
      key: 'informed_consent',
      width: 200,
      render: (text) => renderText(text, 'informed_consent'),
    },
    {
      title: 'HIV Test',
      dataIndex: 'hiv_test',
      key: 'hiv_test',
      width: 200,
      render: (text) => renderText(text, 'hiv_test'),
    },
    {
      title: 'HIV Status',
      dataIndex: 'hiv_status',
      key: 'hiv_status',
      width: 200,
      render: (text) => renderText(text, 'hiv_status'),
    },
    {
      title: 'On ART',
      dataIndex: 'on_art',
      key: 'on_art',
      width: 200,
      render: (text) => renderText(text, 'on_art'),
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      width: 200,
      render: (text) => renderText(text, 'start_date'),
    },
    {
      title: 'ART Number',
      dataIndex: 'art_number',
      key: 'art_number',
      width: 200,
      render: (text) => renderText(text, 'art_number'),
    },
    {
      title: 'Symptoms',
      dataIndex: 'symptoms',
      key: 'symptoms',
      width: 200,
        render: (text) => renderText(text, 'symptoms'),
    },
    {
      title: 'Private Parts',
      dataIndex: 'private_parts',
      key: 'private_parts',
      width: 200,
      render: (text) => renderText(text, 'private_parts'),
    },
    {
      title: 'Exposed to HIV',
      dataIndex: 'exposed_to_HIV',
      key: 'exposed_to_HIV',
      width: 200,
      render: (text) => renderText(text, 'exposed_to_HIV'),
    },
    {
      title: 'Unprotected Sex',
      dataIndex: 'unprotected_sex',
      key: 'unprotected_sex',
      width: 200,
      render: (text) => renderText(text, 'unprotected_sex'),
    },
    {
      title: 'Pregnant/Breastfeeding',
      dataIndex: 'pregnant_breastfeeding',
      key: 'pregnant_breastfeeding',
      width: 200,
      render: (text) => renderText(text, 'pregnant_breastfeeding'),
    },
    {
      title: 'HIV/TB/STI',
      dataIndex: 'hiv_tb_sti',
      key: 'hiv_tb_sti',
      width: 200,
      render: (text) => renderText(text, 'hiv_tb_sti'),
    },
    {
      title: 'HIV Test Result',
      dataIndex: 'hiv_test_result',
      key: 'hiv_test_result',
      width: 200,
      render: (text) => renderText(text, 'hiv_test_result'),
    },
    {
      title: 'Date of HIV Test',
      dataIndex: 'date_of_hiv_test',
      key: 'date_of_hiv_test',
      width: 200,
      render: (text) => renderText(text, 'date_of_hiv_test'),
    },
  ];


  // Fetch the list of household services by householdId
  const fetchAllVCAHivAssessment = () => {
    const uid = child.uid;
    setIsLoading(true);
    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_SERVER}/child/vcaassessmentsabove/${uid}`,
          options
        )
        .then((response) => {
          // Sort the services by service date in descending order
          const SortedVcaHivAssessment = response.data.sort((a, b) => new Date(b.assessment_date) - new Date(a.assessment_date));
          setfetchedVcaHivAssessment(SortedVcaHivAssessment);
          setIsDataReadyForExport(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("axios error: " + error);
          setIsLoading(false);
        });
    }, 3000);
  };


  useEffect(() => {
    fetchAllVCAHivAssessment();
  }, [token, uid.unique_id]);

  const displayFetchedVcaHivAssessment = Array.isArray(fetchVcaHivAssessment)
    ? fetchVcaHivAssessment
      .map((hivassessment) => {
        if (!hivassessment) {
          return null;
        }
        const {
          unique_id,
          date_edited,
          caseworker_name,
          phone,
          informed_consent,
          hiv_test,
          on_art,
          start_date,
          health_facility,
          art_number,
          biological_mother,
          deceased_parents,
          tb_symptoms,
          child_been_sick,
          frequent_rashes,
          child_had_pus,
          hiv_risk,
          hiv_tb,
          hiv_test_result,
          date_of_hiv_test
        } = hivassessment;


        return {
          unique_id,
          date_edited,
          caseworker_name,
          phone,
          informed_consent,
          hiv_test,
          on_art,
          start_date,
          health_facility,
          art_number,
          biological_mother,
          deceased_parents,
          tb_symptoms,
          child_been_sick,
          frequent_rashes,
          child_had_pus,
          hiv_risk,
          hiv_tb,
          hiv_test_result,
          date_of_hiv_test,
          hivassessment, // Add the entire hivassessment object
          // and pass its state to the Screening Household Index page
        };
      })
      .filter((item) => item !== null)
    : [];


  // Sort the assessments based on the "Assessment Date" in descending order
  fetchVcaHivAssessment.sort((a, b) => {
    const dateA = a?.assessment_date?.split("-").reverse().join("-");
    const dateB = b?.assessment_date?.split("-").reverse().join("-");
    return new Date(dateB) - new Date(dateA);
  });


  const navigate = useNavigate();

  const handleAdvancedSearchClick = () => {
    navigate("/advanced-search");
  };

  // Export to CSV
  const exportToCSV = () => {
    const headers = columns.map((column) => column.title);

    // Sort fetchVcaHivAssessment by assessment_date in descending order
    const sortedAssessments = [...fetchVcaHivAssessment].sort(
      (a, b) => new Date(b.assessment_date) - new Date(a.assessment_date)
    );

    const csvData = [headers].concat(
      sortedAssessments.map((child) => {
        const row = Object.values(child);
        row.unshift(child.uid); // Add childhold ID at the beginning
        return row;
      })
    );

    const csvContent = Papa.unparse(csvData);

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    // Create the filename including child ID and caregiver name
    const fileName = `hiv_assessments_above_15_years_${child.uid}_${child.firstname} ${child.lastname}.csv`;
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // export to PDF
  const exportToPDF = () => {
    const headers = columns.map((column) => column.title);

    // Sort fetchVcaHivAssessment by assessment_date in descending order
    const sortedAssessments = [...fetchVcaHivAssessment].sort(
      (a, b) => new Date(b.assessment_date) - new Date(a.assessment_date)
    );

    const body = sortedAssessments.map((row) =>
      columns.map((column) =>
        row[column.dataIndex] === null ? "" : row[column.dataIndex]
      )
    );

    const doc = new jsPDF("l", "mm", "a4");
    doc.autoTable({
      head: [headers],
      body: body,
      theme: "striped",
      styles: { fontSize: 8 },
    });

    // Create the filename including child ID and caregiver name
    const fileName = `hiv_assessments_above_15_years_${child.uid}_${child.firstname} ${child.lastname}.pdf`;
    doc.save(fileName);
  };

  const handleExportToCSV = async () => {
    if (fetchVcaHivAssessment?.length === 0) {
      await fetchAllVCAHivAssessment(currentPage);
    }
    exportToCSV();
  };

  const handleExportToPDF = async () => {
    if (fetchVcaHivAssessment?.length === 0) {
      await fetchAllVCAHivAssessment(currentPage);
    }
    exportToPDF();
  };


  return (
    <>
    <VersionBanner />
      <div className="tab-pane" id="hivassessmentAbove15">
        <React.Fragment>
          <div className="card-body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: windowWidth <= 991 ? "column" : "row",
                alignItems: "center",
              }}
            >
              {/*breadcrumb navigation*/}
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Chip
                    label="VCA HIV Risk Assessment Above 15 Years"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      color: "white",
                      fontWeight: "500",
                      marginLeft: "1%",
                    }}
                  ></Chip>
                  &nbsp;&nbsp;&nbsp;
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <h1
                    style={{
                      fontSize: "17px",
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Navigate to:
                  </h1>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/households">
                    <Chip
                      label="Households"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/children">
                    <Chip
                      label="VCA`s"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/mothers">
                    <Chip
                      label="Mothers"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <Tooltip title="Export to CSV">
                    <Button
                      icon={<FileExcelFilled twoToneColor="#28A745" />}
                      disabled={!isDataReadyForExport}
                      size="large"
                      style={{
                        marginRight: 8,

                        background:
                          "linear-gradient(45deg, #28A745 30%, #FF8E53 90%)",
                        color: "white",
                        boxShadow: "0 3px 5px 2px rgba(40, 167, 69, 0.3)",
                      }}
                      onClick={handleExportToCSV}
                    />
                  </Tooltip>
                </Breadcrumb.Item>
                <br />
                <br />
                {/* <Breadcrumb.Item>
                  <Tooltip title="Export to PDF">
                    <Button
                      size="large"
                      icon={<FilePdfFilled />}
                      disabled={!isDataReadyForExport}
                      style={{
                        background: "#DC3545",
                        color: "white",
                        boxShadow: "0 3px 5px 2px rgba(220, 53, 69, 0.3)",
                      }}
                      onClick={handleExportToPDF}
                    />
                  </Tooltip>
                </Breadcrumb.Item> */}
                <Breadcrumb.Item>
                  <Button
                    onClick={handleAdvancedSearchClick}
                    size="large"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      display: "block",
                      margin: "0 auto",
                      fontWeight: "500",
                    }}
                  >
                    Advanced Search
                  </Button>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div
                className="toolbar"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: windowWidth <= 991 ? "column" : "row",
                }}
              >
              </div>
            </div>
          </div>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "10vh",
              }}
            >
              <Spin size={35} color={"#808080"} loading={true} />
              <p>
                <b style={{ fontWeight: "500", textAlign: "center" }}>
                  Please wait while we process your request...
                </b>
              </p>
            </div>
          ) : (
            <Table
              dataSource={displayFetchedVcaHivAssessment}
              columns={columns}
              scroll={{ y: 500 }}
              pagination={false}
              className="ant-table"
              locale={{
                emptyText: (
                  <b style={{ color: "black" }}>
                    There are currently no HIV Risk Assessments saved for this VCA. Kindly revisit in the future...
                  </b>
                ),
              }}
            />
          )}
        </React.Fragment>
      </div>
    </>
  );
};

export default HivAssessmentAboveFithteen;