import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Table, Button, Spin, Alert } from "antd";
import VersionBanner from "./versionBanner";

const Comments = () => {
  const navigate = useNavigate();

  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  useEffect(() => {
    setLoading(true);
  
    axios
      .get(`${process.env.REACT_APP_SERVER}/user/get-comments/${email}`, options)
      .then((response) => {
        setComments(response.data);
        setLoading(false); 
      })
      .catch((error) => {
        console.error("axios error: " + error);
        setError("An error occurred while fetching comments."); 
        setLoading(false); 
      });
  }, [options, email]);  

  const getChild = async (uid) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER}/child/single/${uid}`, options)
      .then((response) => {
        if (response.data.status) {
          return response.data.data;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error("axios error: " + error);
        return null;
      });
  };

  const handleClick = (vcaid) => {
    getChild(vcaid).then((childData) => {
      if (childData) {
        navigate("/child", { state: { child: childData[0] } });
      } else {
      }
    });
  };

  const columns = [
    {
      title: "Form Name",
      dataIndex: "formname",
      key: "formname",
      width: 300,
    },
    {
      title: "Caseworker",
      dataIndex: "casename",
      key: "casename",
      width: 300,
    },
    {
      title: "Caseworker Phone",
      dataIndex: "casephone",
      key: "casephone",
      width: 300,
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: 300,
    },
    {
      title: "Facility",
      dataIndex: "facility",
      key: "facility",
      width: 300,
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      width: 300,
    },
    {
      title: "VCA",
      dataIndex: "vcaid",
      key: "vcaid",
      width: 100,
      render: (vcaid) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleClick(vcaid);
          }}
          className="btn btn-link btn-info btn-just-icon like"
        >
          <i className="material-icons">visibility</i>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: () => (
        <Button className="btn btn-link btn-danger btn-just-icon like">
                        <i className="material-icons">flag</i>
        </Button>
      ),
    },
  ];

  return (
    <>
    <VersionBanner />
    <div className="card">
      <div className="card-header card-header-info">
        <div className="card-icon">
          <i className="material-icons">flag</i>
        </div>
        <h4 className="card-title" style={{ fontWeight: "500" }}>
          Flagged Forms
        </h4>
      </div>
      <div className="card-body">
        <div className="toolbar"></div>
        <div className="material-datatables">
        {loading ? (
                      <div style={{
                        display: 'flex', justifyContent: 'center',
                        alignItems: 'center', flexDirection: 'column', height:
                          '100%'
                      }}>
                        <br /><br /><br />
                        <Spin />
                        <p><b style={{fontWeight:"500"}}>Please wait while we process your request...</b></p>
                      </div>
                    ) : error ? (
                      <Alert message={error} type="error" />
                    ) : (
          <Table
            dataSource={comments}
            columns={columns}
            rowKey={() => Math.random()}
            pagination={false}
            scroll={{ x: 400 }}
          />
                    )}

        </div>
      </div>
    </div>
    </>
  );
};

export default Comments;
