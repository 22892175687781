import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Papa from "papaparse";
import jsPDF from "jspdf";
import { Chip } from "@mui/material";
import "jspdf-autotable";
import {
  Table,
  Spin,
  Button,
  Tooltip,
  Breadcrumb,
} from "antd";
import {
  FileExcelFilled,
} from "@ant-design/icons";
import VersionBanner from "../../pages/versionBanner";

const Caregiverassessment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchAllHouseholdAssessments, setfetchedHouseholdAssessment] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const location = useLocation();
  const { house } = location?.state;

  const token = localStorage.getItem("token");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  const previousPage = () => {
    navigate(-1);
  };


  //If a field on a record is empty, render
  // 'Not Provided' instead of empty string
  const renderText = (text, dataIndex) => {
    if (text === null || text === undefined || (typeof text === 'string' && text.trim() === '')) {
      if (dataIndex === 'service_referred') {
        return 'Not Referred';
      } else if (dataIndex === 'institution') {
        return 'Not Specified';
      } else {
        return 'Not Provided';
      }
    } else {
      return text;
    }
  };

  const columns = [
    {
      title: "Household Type",
      dataIndex: "household_type",
      key: "household_type",
      width: 200,
      render: (text) => renderText(text, 'household_type'),
    },
    {
      title: "Monthly Expenses",
      dataIndex: "monthly_expenses",
      key: "monthly_expenses",
      width: 200,
      render: (text) => renderText(text, 'monthly_expenses'),
    },
    {
      title: "Caregiver HIV Status",
      dataIndex: "caregiver_hiv_status",
      key: "caregiver_hiv_status",
      width: 200,
      render: (text) => renderText(text, 'caregiver_hiv_status'),
    },
    {
      title: "Tested in Last 6 Months",
      dataIndex: "test_6months",
      key: "test_6months",
      width: 200,
      render: (text) => renderText(text, 'test_6months'),
    },
    {
      title: "Symptoms",
      dataIndex: "symptoms",
      key: "symptoms",
      width: 200,
      render: (text) => renderText(text, 'symptoms'),
    },
    {
      title: "Private Parts",
      dataIndex: "private_parts",
      key: "private_parts",
      width: 200,
      render: (text) => renderText(text, 'private_parts'),
    },
    {
      title: "Exposed",
      dataIndex: "exposed",
      key: "exposed",
      width: 200,
      render: (text) => renderText(text, 'exposed'),
    },
    {
      title: "Unprotected",
      dataIndex: "unprotected",
      key: "unprotected",
      width: 200,
      render: (text) => renderText(text, 'unprotected'),
    },
    {
      title: "Breastfeeding",
      dataIndex: "breastfeeding",
      key: "breastfeeding",
      width: 200,
      render: (text) => renderText(text, 'breastfeeding'),
    },
    {
      title: "Last Year",
      dataIndex: "last_year",
      key: "last_year",
      width: 200,
      render: (text) => renderText(text, 'last_year'),
    },
    {
      title: "Relation",
      dataIndex: "relation",
      key: "relation",
      width: 200,
      render: (text) => renderText(text, 'relation'),
    },
    {
      title: "Partner Caregiver",
      dataIndex: "partner_caregiver",
      key: "partner_caregiver",
      width: 200,
      render: (text) => renderText(text, 'partner_caregiver'),
    },
    {
      title: "Active on Treatment",
      dataIndex: "active_on_treatment",
      key: "active_on_treatment",
      width: 200,
      render: (text) => renderText(text, 'active_on_treatment'),
    },
    {
      title: "Caregiver ART Number",
      dataIndex: "caregiver_art_number",
      key: "caregiver_art_number",
      width: 200,
      render: (text) => renderText(text, 'caregiver_art_number'),
    },
    {
      title: "Appointments",
      dataIndex: "appointments",
      key: "appointments",
      width: 200,
      render: (text) => renderText(text, 'appointments'),
    },
    {
      title: "ART Regularly",
      dataIndex: "art_regularly",
      key: "art_regularly",
      width: 200,
      render: (text) => renderText(text, 'art_regularly'),
    },
    {
      title: "HIV Adherence",
      dataIndex: "hiv_adherence",
      key: "hiv_adherence",
      width: 200,
      render: (text) => renderText(text, 'hiv_adherence'),
    },
    {
      title: "Months on Medication",
      dataIndex: "months_medication",
      key: "months_medication",
      width: 200,
      render: (text) => renderText(text, 'months_medication'),
    },
    {
      title: "Viral Load in Last 12 Months",
      dataIndex: "viral_load_12months",
      key: "viral_load_12months",
      width: 200,
      render: (text) => renderText(text, 'viral_load_12months'),
    },
    {
      title: "Viral Load Results",
      dataIndex: "viral_load_results",
      key: "viral_load_results",
      width: 200,
      render: (text) => renderText(text, 'viral_load_results'),
    },
    {
      title: "Date of Last Viral Load",
      dataIndex: "date_of_last_viral_load",
      key: "date_of_last_viral_load",
      width: 200,
      render: (text) => renderText(text, 'date_of_last_viral_load'),
    },
    {
      title: "Documented in Last 12 Months",
      dataIndex: "documented_12months",
      key: "documented_12months",
      width: 200,
      render: (text) => renderText(text, 'documented_12months'),
    },
    {
      title: "PMTCT Enrolled",
      dataIndex: "pmtct_enrolled",
      key: "pmtct_enrolled",
      width: 200,
      render: (text) => renderText(text, 'pmtct_enrolled'),
    },
    {
      title: "Cancer Screened",
      dataIndex: "cancer_screened",
      key: "cancer_screened",
      width: 200,
      render: (text) => renderText(text, 'cancer_screened'),
    },
    {
      title: "Malnutrition Screened",
      dataIndex: "malnutrition_screened",
      key: "malnutrition_screened",
      width: 200,
      render: (text) => renderText(text, 'malnutrition_screened'),
    },
    {
      title: "Household Number",
      dataIndex: "household_number",
      key: "household_number",
      width: 200,
      render: (text) => renderText(text, 'household_number'),
    },
    {
      title: "Caregiver Education",
      dataIndex: "caregiver_education",
      key: "caregiver_education",
      width: 200,
      render: (text) => renderText(text, 'caregiver_education'),
    },
    {
      title: "Household Member",
      dataIndex: "household_member",
      key: "household_member",
      width: 200,
      render: (text) => renderText(text, 'household_member'),
    },
    {
      title: "Source of Income",
      dataIndex: "source_income",
      key: "source_income",
      width: 200,
      render: (text) => renderText(text, 'source_income'),
    },
    {
      title: "Other Main Source of Income",
      dataIndex: "other_source_main",
      key: "other_source_main",
      width: 200,
      render: (text) => renderText(text, 'other_source_main'),
    },
    {
      title: "Source Earner",
      dataIndex: "source_earner",
      key: "source_earner",
      width: 200,
      render: (text) => renderText(text, 'source_earner'),
    },
    {
      title: "Other Earner",
      dataIndex: "other_earner_other",
      key: "other_earner_other",
      width: 200,
      render: (text) => renderText(text, 'other_earner_other'),
    },
    {
      title: "Last Time",
      dataIndex: "last_time",
      key: "last_time",
      width: 200,
      render: (text) => renderText(text, 'last_time'),
    },
    {
      title: "HH Head Spouse",
      dataIndex: "hh_head_spouse",
      key: "hh_head_spouse",
      width: 200,
      render: (text) => renderText(text, 'hh_head_spouse'),
    },
    {
      title: "Any Adult",
      dataIndex: "any_adult",
      key: "any_adult",
      width: 200,
      render: (text) => renderText(text, 'any_adult'),
    },
    {
      title: "Household Stable",
      dataIndex: "household_stable",
      key: "household_stable",
      width: 200,
      render: (text) => renderText(text, 'household_stable'),
    },
    {
      title: "Every Member",
      dataIndex: "every_member",
      key: "every_member",
      width: 200,
      render: (text) => renderText(text, 'every_member'),
    },
    {
      title: "Material Construction",
      dataIndex: "material_construction",
      key: "material_construction",
      width: 200,
      render: (text) => renderText(text, 'material_construction'),
    },
    {
      title: "Material Construction Roof",
      dataIndex: "material_construction_roof",
      key: "material_construction_roof",
      width: 200,
      render: (text) => renderText(text, 'material_construction_roof'),
    },
    {
      title: "Early Childhood",
      dataIndex: "early_childhood",
      key: "early_childhood",
      width: 200,
      render: (text) => renderText(text, 'early_childhood'),
    },
    {
      title: "Children Adolescent A",
      dataIndex: "children_adolescent_a",
      key: "children_adolescent_a",
      width: 200,
      render: (text) => renderText(text, 'children_adolescent_a'),
    },
    {
      title: "Children Adolescent B",
      dataIndex: "children_adolescent_b",
      key: "children_adolescent_b",
      width: 200,
      render: (text) => renderText(text, 'children_adolescent_b'),
    },
    {
      title: "Drinking Water",
      dataIndex: "drinking_water",
      key: "drinking_water",
      width: 200,
      render: (text) => renderText(text, 'drinking_water'),
    },
    {
      title: "Other Water Source",
      dataIndex: "other_water_other",
      key: "other_water_other",
      width: 200,
      render: (text) => renderText(text, 'other_water_other'),
    },
    {
      title: "Toilet Facility",
      dataIndex: "toilet_facility",
      key: "toilet_facility",
      width: 200,
      render: (text) => renderText(text, 'toilet_facility'),
    },
    {
      title: "Sanitary Products",
      dataIndex: "sanitary_products",
      key: "sanitary_products",
      width: 200,
      render: (text) => renderText(text, 'sanitary_products'),
    },
    {
      title: "Other Sanitary Product",
      dataIndex: "other_sanitary_product_other",
      key: "other_sanitary_product_other",
      width: 200,
      render: (text) => renderText(text, 'other_sanitary_product_other'),
    },
    {
      title: "Material Construction Food",
      dataIndex: "material_construction_food",
      key: "material_construction_food",
      width: 200,
      render: (text) => renderText(text, 'material_construction_food'),
    },
    {
      title: "Other Food Source",
      dataIndex: "other_food_source_other",
      key: "other_food_source_other",
      width: 200,
      render: (text) => renderText(text, 'other_food_source_other'),
    },
    {
      title: "Household Eaten",
      dataIndex: "household_eaten",
      key: "household_eaten",
      width: 200,
      render: (text) => renderText(text, 'household_eaten'),
    },
    {
      title: "Household Eaten in Month",
      dataIndex: "household_eaten_month",
      key: "household_eaten_month",
      width: 200,
      render: (text) => renderText(text, 'household_eaten_month'),
    },
    {
      title: "Child",
      dataIndex: "child",
      key: "child",
      width: 200,
      render: (text) => renderText(text, 'child'),
    },
    {
      title: "Pregnant Caregiver",
      dataIndex: "pregnant_caregiver",
      key: "pregnant_caregiver",
      width: 200,
      render: (text) => renderText(text, 'pregnant_caregiver'),
    },
    {
      title: "Lack Resources",
      dataIndex: "lack_resources",
      key: "lack_resources",
      width: 200,
      render: (text) => renderText(text, 'lack_resources'),
    },
    {
      title: "Limited Variety",
      dataIndex: "limited_variety",
      key: "limited_variety",
      width: 200,
      render: (text) => renderText(text, 'limited_variety'),
    },
    {
      title: "Night Hungry",
      dataIndex: "night_hungry",
      key: "night_hungry",
      width: 200,
      render: (text) => renderText(text, 'night_hungry'),
    },
    {
      title: "Child Household",
      dataIndex: "child_household",
      key: "child_household",
      width: 200,
      render: (text) => renderText(text, 'child_household'),
    },
    {
      title: "Other Obvious Issues",
      dataIndex: "other_obvious_issues",
      key: "other_obvious_issues",
      width: 200,
      render: (text) => renderText(text, 'other_obvious_issues'),
    },
    {
      title: "Not Registered",
      dataIndex: "not_registered",
      key: "not_registered",
      width: 200,
      render: (text) => renderText(text, 'not_registered'),
    },
    {
      title: "Questions",
      dataIndex: "questions",
      key: "questions",
      width: 200,
      render: (text) => renderText(text, 'questions'),
    },
    {
      title: "Caregiver Question",
      dataIndex: "caregiver_question",
      key: "caregiver_question",
      width: 200,
      render: (text) => renderText(text, 'caregiver_question'),
    },
  ];


  // Fetch the list of household services by householdId
  const fetchAllHouseholdAssessment = () => {
    const household_id = house.household_id;
    setIsLoading(true);
    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_SERVER}/household/hhassessments/${household_id}`,
          options
        )
        .then((response) => {
          // Sort the assessments by service date in descending order
          const sortedAssessments = response.data.sort((a, b) => {
            // Convert dates to a format that Javascript can understand
            const dateA = new Date(a.service_date.split('-').reverse().join('-'));
            const dateB = new Date(b.service_date.split('-').reverse().join('-'));
            return dateB - dateA;
          });
          setfetchedHouseholdAssessment(sortedAssessments);
          setIsDataReadyForExport(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("axios error: " + error);
          setIsLoading(false);
        });
    }, 3000);
  };


  useEffect(() => {
    fetchAllHouseholdAssessment();
  }, [token, house.household_id]);

  const displayfetchedHouseholdAssesments = Array.isArray(fetchAllHouseholdAssessments)
    ? fetchAllHouseholdAssessments
      .map((assessments) => {
        if (!assessments) {
          return null;
        }
        const {
          household_id,
          household_type,
          monthly_expenses,
          caregiver_hiv_status,
          test_6months,
          symptoms,
          private_parts,
          exposed,
          unprotected,
          breastfeeding,
          last_year,
          relation,
          partner_caregiver,
          active_on_treatment,
          caregiver_art_number,
          appointments,
          art_regularly,
          hiv_adherence,
          months_medication,
          viral_load_12months,
          viral_load_results,
          date_of_last_viral_load,
          documented_12months,
          pmtct_enrolled,
          cancer_screened,
          malnutrition_screened,
          household_number,
          caregiver_education,
          household_member,
          source_income,
          other_source_main,
          source_earner,
          other_earner_other,
          last_time, hh_head_spouse,
          any_adult, household_stable,
          every_member,
          material_construction,
          material_construction_roof,
          early_childhood,
          children_adolescent_a,
          children_adolescent_b,
          drinking_water,
          other_water_other,
          toilet_facility,
          sanitary_products,
          other_sanitary_product_other,
          material_construction_food,
          other_food_source_other,
          household_eaten,
          household_eaten_month,
          child, pregnant_caregiver,
          lack_resources, limited_variety,
          night_hungry, child_household,
          other_obvious_issues, not_registered,
          questions, caregiver_question
        } = assessments;

        return {
          household_id,
          household_type,
          monthly_expenses,
          caregiver_hiv_status,
          test_6months,
          symptoms,
          private_parts,
          exposed,
          unprotected,
          breastfeeding,
          last_year,
          relation,
          partner_caregiver,
          active_on_treatment,
          caregiver_art_number,
          appointments,
          art_regularly,
          hiv_adherence,
          months_medication,
          viral_load_12months,
          viral_load_results,
          date_of_last_viral_load,
          documented_12months,
          pmtct_enrolled,
          cancer_screened,
          malnutrition_screened,
          household_number,
          caregiver_education,
          household_member,
          source_income,
          other_source_main,
          source_earner,
          other_earner_other,
          last_time, hh_head_spouse,
          any_adult, household_stable,
          every_member,
          material_construction,
          material_construction_roof,
          early_childhood,
          children_adolescent_a,
          children_adolescent_b,
          drinking_water,
          other_water_other,
          toilet_facility,
          sanitary_products,
          other_sanitary_product_other,
          material_construction_food,
          other_food_source_other,
          household_eaten,
          household_eaten_month,
          child, pregnant_caregiver,
          lack_resources, limited_variety,
          night_hungry, child_household,
          other_obvious_issues, not_registered,
          questions, caregiver_question
          // Add the entire assessment object
          // and pass its state to the Screening Household Index page
        };
      })
      .filter((item) => item !== null)
    : [];


  // Sort the services based on the "Service Date" in descending order
  fetchAllHouseholdAssessments.sort((a, b) => {
    const dateA = a?.service_date?.split("-").reverse().join("-");
    const dateB = b?.service_date?.split("-").reverse().join("-");
    return new Date(dateB) - new Date(dateA);
  });


  const navigate = useNavigate();

  const handleAdvancedSearchClick = () => {
    navigate("/advanced-search");
  };

  // Export to CSV
  const exportToCSV = () => {
    const headers = columns.map((column) => column.title);

    // Sort fetchedAssessments by service_date in descending order
    const sortedAssessments = [...fetchAllHouseholdAssessments].sort(
      (a, b) => new Date(b.service_date) - new Date(a.service_date)
    );

    const csvData = [headers].concat(
      sortedAssessments.map((house) => {
        const row = Object.values(house);
        row.unshift(house.household_id); // Add household ID at the beginning
        return row;
      })
    );

    const csvContent = Papa.unparse(csvData);

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    // Create the filename including household ID and caregiver name
    const fileName = `caregiver_assessments_data_${house.household_id}_${house.caregiver_name}.csv`;
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // export to PDF
  const exportToPDF = () => {
    const headers = columns.map((column) => column.title);

    // Sort fetchedServices by service_date in descending order
    const sortedAssessments = [...fetchAllHouseholdAssessments].sort(
      (a, b) => new Date(b.service_date) - new Date(a.service_date)
    );

    const body = sortedAssessments.map((row) =>
      columns.map((column) =>
        row[column.dataIndex] === null ? "" : row[column.dataIndex]
      )
    );

    const doc = new jsPDF("l", "mm", "a1");
    doc.autoTable({
      head: [headers],
      body: body,
      theme: "striped",
      styles: { fontSize: 8 },
    });

    // Create the filename including household ID and caregiver name
    const fileName = `caregiver_assessments_data_${house.household_id}_${house.caregiver_name}.pdf`;
    doc.save(fileName);
  };

  const handleExportToCSV = async () => {
    if (fetchAllHouseholdAssessments?.length === 0) {
      await fetchAllHouseholdAssessment(currentPage);
    }
    exportToCSV();
  };

  const handleExportToPDF = async () => {
    if (fetchAllHouseholdAssessments?.length === 0) {
      await fetchAllHouseholdAssessment(currentPage);
    }
    exportToPDF();
  };

  return (
    <>
      <VersionBanner />
      <div className="tab-pane" id="hivassessment">
        <React.Fragment>
          <div className="card-body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: windowWidth <= 991 ? "column" : "row",
                alignItems: "center",
              }}
            >
              {/*breadcrumb navigation*/}
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Chip
                    label="Caregiver Risk Assessments"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      color: "white",
                      fontWeight: "500",
                      marginLeft: "1%",
                    }}
                  ></Chip>
                  &nbsp;&nbsp;&nbsp;
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <h1
                    style={{
                      fontSize: "17px",
                      color: "black",
                      fontWeight: "200",
                    }}
                  >
                    {" "}
                    Navigate to:
                  </h1>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/household">
                    <Chip
                      label="Households"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/children">
                    <Chip
                      label="VCA`s"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/mothers">
                    <Chip
                      label="Mothers"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <Tooltip title="Export to CSV">
                    <Button
                      icon={<FileExcelFilled twoToneColor="#28A745" />}
                      disabled={!isDataReadyForExport}
                      size="large"
                      style={{
                        marginRight: 8,
                        background:
                          "linear-gradient(45deg, #28A745 30%, #FF8E53 90%)",
                        color: "white",
                        boxShadow: "0 3px 5px 2px rgba(40, 167, 69, 0.3)",
                      }}
                      onClick={handleExportToCSV}
                    />
                  </Tooltip>
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <Button
                    onClick={handleAdvancedSearchClick}
                    size="large"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      display: "block",
                      margin: "0 auto",
                      fontWeight: "500",
                    }}
                  >
                    Advanced Search
                  </Button>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div
                className="toolbar"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: windowWidth <= 991 ? "column" : "row",
                }}
              >
              </div>
            </div>
          </div>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "10vh",
              }}
            >
              <Spin size={35} color={"#808080"} loading={true} />
              <p>
                <b style={{ fontWeight: "500", textAlign: "center" }}>
                  Please wait while we process your request...
                </b>
              </p>
            </div>
          ) : (
            <Table
              dataSource={displayfetchedHouseholdAssesments}
              columns={columns}
              scroll={{ y: 200 }}
              pagination={false}
              className="ant-table"
              locale={{
                emptyText: (
                  <b style={{ color: "black" }}>
                    There are currently no HIV Caregiver Risk Assessments saved for this Household. Kindly revisit in future...
                  </b>
                ),
              }}
            />
          )}
        </React.Fragment>
      </div>
    </>
  );
};

export default Caregiverassessment;
