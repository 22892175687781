import AppContext from "../context/appContext";
import React, { useContext, useEffect, useState, useMemo } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Card, Table, Button } from "antd";
import VersionBanner from "./versionBanner";

const { Header } = Layout;

const Districts = () => {

  const navigate = useNavigate();
  const clients = useContext(AppContext);
  const token = localStorage.getItem("token");
  const [totalHouseholdsCount, setTotalHouseholdsCount] = useState(null);
  const [totalMothersCount, setTotalMotherCount] = useState(null);
  const [totalVcasCount, setTotalVcasCount] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const isAdmin =
    userRoles.includes(1) || userRoles.includes(2) || userRoles.includes(0);

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token, navigate]);

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  // Entry path to DQA dashboard
  const fetchData = (district) => {
    clients.setDistrict(district);
    navigate("/");
  };

  const roles = localStorage.getItem("roles");
  const arr = roles ? roles?.split(",")?.map(Number) : [];

  const districts = [
    "Kabwe",
    "Kapiri Mposhi",
    "Mkushi",
    "Chibombo",
    "Chisamba",
    "Chikankata",
    "Mumbwa",
    "Sinazongwe",
    "Kalomo",
    "Monze",
    "Namwala",
    "Choma",
    "Mazabuka",
    "Livingstone",
    "Serenje",
    "Kazungula",
  ];

  const fetchAllRegistersTotalCount = async () => {
    // Get the user's districts based on their roles
    const userDistricts =
      isAdmin === "1" || isAdmin === "2" || isAdmin === "0"
        ? districts
        : districts.filter((_, index) => arr.includes(index + 1));

    // Create a query parameter with the user's districts
    const districtsQueryParam =
      userDistricts.length > 0
        ? `?districts=${encodeURIComponent(userDistricts.join(","))}`
        : "";

    try {
      // Fetch the counts using the query parameter
      const getTotalHouseholdCount = await fetch(
        `${process.env.REACT_APP_SERVER}/household/hh/total/count${districtsQueryParam}`,
        options
      );
      const getTotalVcasCount = await fetch(
        `${process.env.REACT_APP_SERVER}/child/total/vcas/count${districtsQueryParam}`,
        options
      );
      const getAllIndexMothersCount = await fetch(
        `${process.env.REACT_APP_SERVER}/household/hh/total/count${districtsQueryParam}`,
        options
      );

      const fetcher = await getTotalHouseholdCount.json();
      const fetcher_1 = await getTotalVcasCount.json();
      const fetcher_2 = await getAllIndexMothersCount.json();
      setTotalHouseholdsCount(fetcher.allHouseholdCount);
      setTotalVcasCount(fetcher_1.totalVcaCount);
      setTotalMotherCount(fetcher_2.allMothersCount);
    } catch (error) {
      // Log the error in the browser console
      console.error("Error fetching data:", error);
      // Handle the error as needed (e.g., display a user-friendly message)
    }
  };

  useEffect(() => {
    const roles = localStorage.getItem("roles");
    const arr = roles ? roles.split(",").map(Number) : [];
    setUserRoles(arr);
  }, []);

  useEffect(() => {
    fetchAllRegistersTotalCount();
  }, [isAdmin, options, userRoles]);

  useEffect(() => {
    const handleResize = () => {
      setCollapsed(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = [
    {
      title: "District No.",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <Button type="primary" onClick={() => fetchData(record.role)}>
          Open
        </Button>
      ),
    },
  ];

  const dataSource = districts?.map((district, index) => ({
    key: index + 1,
    role: index + 1,
    district: district,
  }));


  return (
    <div>
       <VersionBanner />
      <Header
        style={{
          backgroundColor: "white",
          paddingLeft: 0,
          position: "fixed",
          width: "100%",
          zIndex: 1,
        }}
      >
        <Menu
          mode="horizontal"
          theme="light"
          style={{ lineHeight: "64px", justifyContent: "flex-start" }}
          inlineCollapsed={collapsed}
        >
          <Menu.Item
            style={{ color: "black", paddingLeft: "40px", paddingRight: 0 }}
          >
            <span
              style={{
                whiteSpace: "nowrap",
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
             &nbsp;&nbsp;&nbsp; <img src="https://monalogy.sirv.com/ecap-II-logo.png" width="451" height="30" alt=""></img>
            </span>
          </Menu.Item>
          <Menu.Item
            key="4"
            onClick={() => {
              localStorage.removeItem("token");
              localStorage.removeItem("roles");
              window.location.href = "/";
            }}
            style={{ marginLeft: "auto", fontSize: "15px" }}
          >
            <p>
              <i className="material-icons">person</i>
              Log out
            </p>
          </Menu.Item>
        </Menu>
      </Header>

      <div
        className="content"
        style={{ paddingTop: "100px" }}
        key={`${totalHouseholdsCount?.count}-${totalVcasCount?.count}-${totalMothersCount?.count}`}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="card-body">
                <div className="row" style={{ justifyContent: "center" }}>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header-info">
                        <div className="card-icon" data-header-animation="true">
                          <i className="material-icons">house</i>
                        </div>
                        <h4 className="card-title" style={{ fontWeight: "500" }}>
                          Households Screened
                        </h4>
                        <h3 className="card-title">
                          {totalHouseholdsCount !== null ? (
                            <div>{totalHouseholdsCount?.count}</div>
                          ) : (
                            <div style={{ float: "right" }}>
                              <BounceLoader
                                size={35}
                                color={"#808080"}
                                loading={true}
                              />
                            </div>
                          )}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          <i className="material-icons">date_range</i> Last 24
                          Hours
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header-info">
                        <div className="card-icon" data-header-animation="true">
                          <i className="material-icons">people</i>
                        </div>
                        <h4 className="card-title" style={{ fontWeight: "500" }}>
                          Total VCAs Screened
                        </h4>
                        <h3 className="card-title">
                          {totalVcasCount !== null ? (
                            <div>{totalVcasCount}</div>
                          ) : (
                            <div style={{ float: "right" }}>
                              <BounceLoader
                                size={35}
                                color={"#808080"}
                                loading={true}
                              />
                            </div>
                          )}
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          <i className="material-icons">date_range</i> Last 24
                          Hours
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="card card-stats">
                      <div className="card-header-info">
                        <div className="card-icon" data-header-animation="true">
                          <i className="material-icons">people</i>
                        </div>
                        <h4 className="card-title" style={{ fontWeight: "500" }}>
                          Total Index Mothers Register
                        </h4>
                        <h3 className="card-title">
                          
                            <div>27957</div>
                       
                         
                        
                        </h3>
                      </div>
                      <div className="card-footer">
                        <div className="stats">
                          <i className="material-icons">date_range</i> Last 24
                          Hours
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-9">
              <Card>
                <Table dataSource={dataSource} columns={columns} bordered />

              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Districts;