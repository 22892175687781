import { Tag } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

const VersionBanner = () => {

  const version = "v2.1.0";
  const lastUpdatedTables = "18-03-2024"
  const time = "07:02"
  const [lastUpdated, setLastUpdated] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_API_URL}/household/updated-tables`
        );

        const updatedTablesDate = response.data.data[0];
        if (updatedTablesDate.length > 0) {
          setLastUpdated(updatedTablesDate[0].last_updated);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ position: "fixed", right: 0, bottom: 0, margin: 16 }}>
      <Tag color="geekblue" style={{ fontSize: '15px', padding: '5px' }}>
        Version {version}<br />
        Date: {lastUpdatedTables}<br />
        Time: {time}
      </Tag><br />
    </div>
  );
};

export default VersionBanner;
