const AddUserModal = ({

  resetForm,
  isAdmin,
  Modal,
  hideModal,
  formData,
  onChange,
  handleChange,
  handleSubmit,
  MultiSelect,
  districtsRoles,
  selected,
  setSelected,
  value,
  Radio,
  BounceLoader,
  loading,
  open,

}) => {
  return (
    <Modal open={open} onCancel={hideModal} footer={null}>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <form onSubmit={handleSubmit}>
                <div className="card">
                  <div className=" card-header-info">
                    <div className="card-icon">
                      <p>New User Form</p>
                    </div>
                  </div>
                  <div className="card-body ">
                    <div className="form-group">
                      <label>User ID</label>

                      <input
                        className="form-control"
                        type="text"
                        name="id"
                        value={formData.id}
                        onChange={handleChange}
                        required="true"
                      />
                    </div>
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handleChange}
                        required="true"
                      />
                    </div>
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="lastname"
                        value={formData.lastname}
                        onChange={handleChange}
                        required="true"
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required="true"
                      />
                    </div>
                    <div class="form-group">
                      <label>Enter Password</label>
                      <input
                        className="form-control"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        type="password"
                      />
                    </div>
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input
                        className="form-control"
                        type="password"
                        name="password_confirm"
                        value={formData.password_confirm}
                        onChange={handleChange}
                        required="true"
                      />
                    </div>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        className="form-control"
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required="true"
                      />
                    </div>
                    <div className="form-group">
                      <label>Select Districts</label>
                      <MultiSelect
                        options={districtsRoles}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Selected"
                      />
                    </div>
                    <div className="form-group">
                      <label>Select New User Role</label>
                      <br />
                      <Radio.Group onChange={onChange} value={value}>
                        {isAdmin === 1 && (
                          <Radio value={1}>
                            <p style={{ color: "gray" }}>Super Administrator</p>
                          </Radio>
                        )}
                        <Radio value={2}>
                          <p style={{ color: "gray" }}>Administrator</p>
                        </Radio>
                        <Radio value={0}>
                          <p style={{ color: "gray" }}>District Users</p>
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="card-footer text-right">
                    <div className="form-check mr-auto"></div>
                    {loading ? (
                      <div style={{ float: "right" }}>
                        <BounceLoader
                          size={35}
                          color={"#808080"}
                          loading={true}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-info"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddUserModal;
