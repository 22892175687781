import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Chip, debounce } from "@mui/material";
import "jspdf-autotable";
import {
  Table,
  Spin,
  Alert,
  Input,
  Button,
  Tooltip,
  Breadcrumb,
  Popover,
} from "antd";
import {
  QuestionCircleFilled,
  FileExcelFilled,
  FilePdfFilled,
  SearchOutlined,
} from "@ant-design/icons";
import VersionBanner from "../../pages/versionBanner";

const Vassessment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchVcaAssessments, setfetchedVcaAssessments] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);

  const location = useLocation();
  // const { uid } = location?.state;
  
  const { child } = location?.state;

  //Display active household_id and VCA full names on top of the page
  const { firstname = "", lastname = "" } = child || {};
  const uid = location?.state?.child?.uid || "";

  // const uid = location?.state?.child?.uid || "";

  const token = localStorage.getItem("token");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  
  //If a field on a record is empty, render
  // 'Not Provided' instead of empty string
  const renderText = (text, dataIndex) => {
    if (text === null || text === undefined || (typeof text === 'string' && text.trim() === '')) {
      if (dataIndex === 'service_referred') {
        return 'Not Referred';
      } else if (dataIndex === 'institution') {
        return 'Not Specified';
      } else {
        return 'Not Provided';
      }
    } else {
      return text;
    }
  };

  const columns = [
    {
      title: "Date Started ART",
      dataIndex: "date_started_art",
      key: "date_started_art",
      width: 300,
      render: (text) => renderText(text, 'date_started_art'),
    },
    {
      title: "Date Edited",
      dataIndex: "date_edited",
      key: "date_edited",
      width: 300,
      render: (text) => renderText(text, 'date_edited'),
    },
    {
      title: "Is on HIV Treatment",
      dataIndex: "is_on_hiv_treatment",
      key: "is_on_hiv_treatment",
      width: 300,
      render: (text) => renderText(text, 'is_on_hiv_treatment'),
    },
    {
      title: "Caseworker Name",
      dataIndex: "caseworker_name",
      key: "caseworker_name",
      width: 300,
      render: (text) => renderText(text, 'caseworker_name'),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 300,
      render: (text) => renderText(text, 'phone'),
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      width: 300,
       render: (text) => renderText(text, 'age'),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: 300,
       render: (text) => renderText(text, 'gender'),
    },
    {
      title: "Home Address",
      dataIndex: "homeaddress",
      key: "homeaddress",
      width: 300,
       render: (text) => renderText(text, 'homeaddress'),
    },
    {
      title: "Screening Location",
      dataIndex: "screening_location",
      key: "screening_location",
      width: 300,
       render: (text) => renderText(text, 'screening_location'),
    },
    {
      title: "Adolescent Phone",
      dataIndex: "adolescent_phone",
      key: "adolescent_phone",
      width: 300,
       render: (text) => renderText(text, 'adolescent_phone'),
    },
    {
      title: "House Number",
      dataIndex: "house_number",
      key: "house_number",
      width: 300,
       render: (text) => renderText(text, 'house_number'),
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: 300,
       render: (text) => renderText(text, 'district'),
    },
    {
      title: "Ward",
      dataIndex: "ward",
      key: "ward",
      width: 300,
       render: (text) => renderText(text, 'ward'),
    },
    {
      title: "Township",
      dataIndex: "township",
      key: "township",
      width: 300,
       render: (text) => renderText(text, 'township'),
    },
    {
      title: "Is HIV Positive",
      dataIndex: "is_hiv_positive",
      key: "is_hiv_positive",
      width: 300,
       render: (text) => renderText(text, 'is_hiv_positive'),
    },
    {
      title: "VCA ART",
      dataIndex: "vca_art",
      key: "vca_art",
      width: 300,
       render: (text) => renderText(text, 'vca_art'),
    },
    {
      title: "Date ART",
      dataIndex: "date_art",
      key: "date_art",
      width: 300,
       render: (text) => renderText(text, 'date_art'),
    },
    {
      title: "Appointment",
      dataIndex: "appointment",
      key: "appointment",
      width: 300,
       render: (text) => renderText(text, 'appointment'),
    },
    {
      title: "Taking ART",
      dataIndex: "taking_art",
      key: "taking_art",
      width: 300,
       render: (text) => renderText(text, 'taking_art'),
    },
    {
      title: "Months Medication",
      dataIndex: "months_medication",
      key: "months_medication",
      width: 300,
       render: (text) => renderText(text, 'months_medication'),
    },
    {
      title: "Viral Load",
      dataIndex: "viral_load",
      key: "viral_load",
      width: 300,
       render: (text) => renderText(text, 'viral_load'),
    },
    {
      title: "Documented",
      dataIndex: "documented",
      key: "documented",
      width: 300,
       render: (text) => renderText(text, 'documented'),
    },
    {
      title: "ART Health Facility",
      dataIndex: "art_health_facility",
      key: "art_health_facility",
      width: 300,
       render: (text) => renderText(text, 'art_health_facility'),
    },
    {
      title: "ART Facility",
      dataIndex: "art_facility",
      key: "art_facility",
      width: 300,
       render: (text) => renderText(text, 'art_facility'),
    },
    {
      title: "ART Number",
      dataIndex: "art_number",
      key: "art_number",
      width: 300,
       render: (text) => renderText(text, 'art_number'),
    },
    {
      title: "HIV Test",
      dataIndex: "hiv_test",
      key: "hiv_test",
      width: 300,
       render: (text) => renderText(text, 'hiv_test'),
    },
    {
      title: "VCA Exposed",
      dataIndex: "vca_exposed",
      key: "vca_exposed",
      width: 300,
       render: (text) => renderText(text, 'vca_exposed'),
    },
    {
      title: "Biological Mother",
      dataIndex: "biological_mother",
      key: "biological_mother",
      width: 300,
       render: (text) => renderText(text, 'biological_mother'),
    },
    {
      title: "Parents Deceased",
      dataIndex: "parents_deceased",
      key: "parents_deceased",
      width: 300,
       render: (text) => renderText(text, 'parents_deceased'),
    },
    {
      title: "Siblings Deceased",
      dataIndex: "siblings_deceased",
      key: "siblings_deceased",
      width: 300,
       render: (text) => renderText(text, 'siblings_deceased'),
    },
    {
      title: "TB Symptoms",
      dataIndex: "tb_symptoms",
      key: "tb_symptoms",
      width: 300,
       render: (text) => renderText(text, 'tb_symptoms'),
    },
    {
      title: "Child Sick",
      dataIndex: "child_sick",
      key: "child_sick",
      width: 300,
       render: (text) => renderText(text, 'child_sick'),
    },
    {
      title: "Rashes",
      dataIndex: "rashes",
      key: "rashes",
      width: 300,
       render: (text) => renderText(text, 'rashes'),
    },
    {
      title: "Discharge",
      dataIndex: "discharge",
      key: "discharge",
      width: 300,
       render: (text) => renderText(text, 'discharge'),
    },
    {
      title: "Female Sex Worker",
      dataIndex: "female_sex_worker",
      key: "female_sex_worker",
      width: 300,
       render: (text) => renderText(text, 'female_sex_worker'),
    },
    {
      title: "Sexually Active",
      dataIndex: "sexually_active",
      key: "sexually_active",
      width: 300,
       render: (text) => renderText(text, 'sexaully_active'),
    },
    {
      title: "Sex Positive",
      dataIndex: "sex_positive",
      key: "sex_positive",
      width: 300,
       render: (text) => renderText(text, 'sex_positive'),
    },
    {
      title: "Sexual Partner",
      dataIndex: "sexual_partner",
      key: "sexual_partner",
      width: 300,
       render: (text) => renderText(text, 'sexual_partner'),
    },
    {
      title: "Private Parts",
      dataIndex: "private_parts",
      key: "private_parts",
      width: 300,
       render: (text) => renderText(text, 'private_parts'),
    },
    {
      title: "Transactional Sex",
      dataIndex: "transactional_sex",
      key: "transactional_sex",
      width: 300,
       render: (text) => renderText(text, 'transactional_sex'),
    },
    {
      title: "Sexually Abused",
      dataIndex: "sexually_abused",
      key: "sexually_abused",
      width: 300,
       render: (text) => renderText(text, 'sexually_abused'),
    },
    {
      title: "Past Year",
      dataIndex: "past_year",
      key: "past_year",
      width: 300,
       render: (text) => renderText(text, 'past_year'),
    },
    {
      title: "Substance Abuse",
      dataIndex: "substance_abuse",
      key: "substance_abuse",
      width: 300,
       render: (text) => renderText(text, 'substance_abuse'),
    },
    {
      title: "Been Married",
      dataIndex: "been_married",
      key: "been_married",
      width: 300,
       render: (text) => renderText(text, 'been_married'),
    },
    {
      title: "Given Birth",
      dataIndex: "given_birth",
      key: "given_birth",
      width: 300,
       render: (text) => renderText(text, 'given_birth'),
    },
    {
      title: "Currently Pregnant",
      dataIndex: "currently_pregnant",
      key: "currently_pregnant",
      width: 300,
       render: (text) => renderText(text, 'currently_pregnant'),
    },
    {
      title: "VCA Response",
      dataIndex: "vca_response",
      key: "vca_response",
      width: 300,
       render: (text) => renderText(text, 'vca_response'),
    },
    {
      title: "Sub Population",
      dataIndex: "sub_population",
      key: "sub_population",
      width: 300,
       render: (text) => renderText(text, 'sub_population'),
    },
  ];  

  // Fetch the list of VCA assessments by householdId
  const fetchAllVcaAssessments = () => {
    const uid = child.uid;
    setIsLoading(true);
    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_SERVER}/child/vcaassessments/${uid}`,
          options
        )
        .then((response) => {
          // Sort the assessment by service date in descending order
          const sortedAssessments = response.data.sort((a, b) => new Date(b.assessment_date) - new Date(a.assessment_date));
          setfetchedVcaAssessments(sortedAssessments);
          setIsDataReadyForExport(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("axios error: " + error);
          setIsLoading(false);
        });
    }, 3000);
  };
  

  useEffect(() => {
    fetchAllVcaAssessments();
  }, [token, uid.unique_id]);

  const displayFetchedVcaAssessments = Array.isArray(fetchVcaAssessments)
    ? fetchVcaAssessments
        .map((assessments) => {
          if (!assessments) {
            return null;
          }
          const {
            unique_id, 
            date_started_art, 
            date_edited, 
            is_on_hiv_treatment, 
            caseworker_name, 
            age, 
            phone, 
            first_name, 
            last_name, 
            gender, 
            caregiver_firstname, 
            homeaddress, 
            screening_location, 
            caregiver_lastname, 
            adolescent_phone, 
            house_number, 
            province, 
            district, 
            ward, 
            township, 
            facility, 
            is_hiv_positive, 
            vca_art, date_art, 
            appointment, 
            taking_art, 
            months_medication, 
            viral_load, 
            documented, 
            vl_last_result, 
            art_health_facility, 
            art_facility, 
            art_number, 
            hiv_test, 
            vca_exposed, 
            biological_mother, 
            parents_deceased, 
            siblings_deceased, 
            tb_symptoms, 
            child_sick, 
            rashes, 
            discharge, 
            female_sex_worker, 
            sexually_active, 
            sex_positive, 
            sexual_partner, 
            private_parts, 
            transactional_sex, 
            sexually_abused, 
            past_year, 
            substance_abuse, 
            been_married, 
            given_birth, 
            currently_pregnant, 
            vca_response, 
            sub_population, 
            caseworker_nrc, date,
          } = assessments;

          return {
            unique_id, 
            date_started_art, 
            date_edited, 
            is_on_hiv_treatment, 
            caseworker_name, 
            gender, 
            phone, 
            first_name, 
            last_name, 
            age, 
            caregiver_firstname, 
            homeaddress, 
            screening_location, 
            caregiver_lastname, 
            adolescent_phone, 
            house_number, 
            province, 
            district, 
            ward, 
            township, 
            facility, 
            is_hiv_positive, 
            vca_art, date_art, 
            appointment, 
            taking_art, 
            months_medication, 
            viral_load, 
            documented, 
            vl_last_result, 
            art_health_facility, 
            art_facility, 
            art_number, 
            hiv_test, 
            vca_exposed, 
            biological_mother, 
            parents_deceased, 
            siblings_deceased, 
            tb_symptoms, 
            child_sick, 
            rashes, 
            discharge, 
            female_sex_worker, 
            sexually_active, 
            sex_positive, 
            sexual_partner, 
            private_parts, 
            transactional_sex, 
            sexually_abused, 
            past_year, 
            substance_abuse, 
            been_married, 
            given_birth, 
            currently_pregnant, 
            vca_response, 
            sub_population, 
            caseworker_nrc, date,
            assessments, // Add the entire assessments object
            // and pass its state to the Screening Household Index page
          };
        })
        .filter((item) => item !== null)
    : [];


 // Sort by "Assessment Date" in descending order
 fetchVcaAssessments.sort((a, b) => {
  const dateA = a?.assessment_date?.split("-").reverse().join("-");
  const dateB = b?.assessment_date?.split("-").reverse().join("-");
  return new Date(dateB) - new Date(dateA);
});


  const navigate = useNavigate();

  const handleAdvancedSearchClick = () => {
    navigate("/advanced-search");
  };

  // Export to CSV
  const exportToCSV = () => {
    const headers = columns.map((column) => column.title);

    // Sort  by assessment_date in descending order
    const sortedAssessments = [...fetchVcaAssessments].sort(
      (a, b) => new Date(b.assessment_date) - new Date(a.assessment_date)
    );

    const csvData = [headers].concat(
      sortedAssessments.map((child) => {
        const row = Object.values(child);
        row.unshift(child.uid); // Add childhold ID at the beginning
        return row;
      })
    );

    const csvContent = Papa.unparse(csvData);

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    // Create the filename including child full names
    const fileName = `child_assessments_data_${child.uid}_${child.firstname} ${child.lastname}.csv`;
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // export to PDF
  const exportToPDF = () => {
    const headers = columns.map((column) => column.title);

    // Sort  by assessment_date in descending order
    const sortedAssessments = [...fetchVcaAssessments].sort(
      (a, b) => new Date(b.assessment_date) - new Date(a.assessment_date)
    );

    const body = sortedAssessments.map((row) =>
      columns.map((column) =>
        row[column.dataIndex] === null ? "" : row[column.dataIndex]
      )
    );

    const doc = new jsPDF("l", "mm", "a4");
    doc.autoTable({
      head: [headers],
      body: body,
      theme: "striped",
      styles: { fontSize: 8 },
    });

    // Create the filename including child full names
    const fileName = `child_assessments_data_${child.uid}_${child.firstname} ${child.lastname}.pdf`;
    doc.save(fileName);
  };

  const handleExportToCSV = async () => {
    if (fetchVcaAssessments?.length === 0) {
      await fetchAllVcaAssessments(currentPage);
    }
    exportToCSV();
  };

  const handleExportToPDF = async () => {
    if (fetchVcaAssessments?.length === 0) {
      await fetchAllVcaAssessments(currentPage);
    }
    exportToPDF();
  };


  return (
    <>
    <VersionBanner />
          <div className="tab-pane" id="vassessment">
            <React.Fragment>
            <div className="card-body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: windowWidth <= 991 ? "column" : "row",
                alignItems: "center",
              }}
            >
              {/*breadcrumb navigation*/}
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Chip
                    label="VCA Vulnerabilities Assessments"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      color: "white",
                      fontWeight: "500",
                      marginLeft: "1%",
                    }}
                  ></Chip>
                  &nbsp;&nbsp;&nbsp;
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <h1
                    style={{
                      fontSize: "17px",
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Navigate to:
                  </h1>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/households">
                    <Chip
                      label="Households"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/children">
                    <Chip
                      label="VCA`s"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/mothers">
                    <Chip
                      label="Mothers"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <Tooltip title="Export to CSV">
                    <Button
                      icon={<FileExcelFilled twoToneColor="#28A745" />}
                      disabled={!isDataReadyForExport}
                      size="large"
                      style={{
                        marginRight: 8,

                        background:
                          "linear-gradient(45deg, #28A745 30%, #FF8E53 90%)",
                        color: "white",
                        boxShadow: "0 3px 5px 2px rgba(40, 167, 69, 0.3)",
                      }}
                      onClick={handleExportToCSV}
                    />
                  </Tooltip>
                </Breadcrumb.Item>
                <br />
                <br />
                {/* <Breadcrumb.Item>
                  <Tooltip title="Export to PDF">
                    <Button
                      size="large"
                      icon={<FilePdfFilled />}
                      disabled={!isDataReadyForExport}
                      style={{
                        background: "#DC3545",
                        color: "white",
                        boxShadow: "0 3px 5px 2px rgba(220, 53, 69, 0.3)",
                      }}
                      onClick={handleExportToPDF}
                    />
                  </Tooltip>
                </Breadcrumb.Item> */}
                <Breadcrumb.Item>
                  <Button
                    onClick={handleAdvancedSearchClick}
                    size="large"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      display: "block",
                      margin: "0 auto",
                      fontWeight: "500",
                    }}
                  >
                    Advanced Search
                  </Button>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div
                className="toolbar"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: windowWidth <= 991 ? "column" : "row",
                }}
              >
              </div>
            </div>
          </div>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "10vh",
                  }}
                >
                  <Spin size={35} color={"#808080"} loading={true} />
                  <p>
                    <b style={{ fontWeight: "500", textAlign: "center" }}>
                      Please wait while we process your request...
                    </b>
                  </p>
                </div>
              ) : (
                <Table
                  dataSource={displayFetchedVcaAssessments}
                  columns={columns}
                  scroll={{ y: 500 }}
                  pagination={false}
                  className="ant-table"
                  locale={{
                    emptyText: (
                      <b style={{ color: "black" }}>
                          There are currently no VCA Vulnerabilities Assessments saved for this VCA. Kindly revisit in future...
                      </b>
                    ),
                  }}
                />
              )}
            </React.Fragment>
          </div>
      </>
  );
};

export default Vassessment;
