import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Chip, debounce } from "@mui/material";
import "jspdf-autotable";
import {
  Table,
  Spin,
  Button,
  Tooltip,
  Breadcrumb,
} from "antd";
import {
  FileExcelFilled,
} from "@ant-design/icons";
import VersionBanner from "../../pages/versionBanner";

const AllVReferredServices = ({ referred_services }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedServices, setfetchedServices] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);

  const location = useLocation();
  const { child } = location.state;

  const token = localStorage.getItem("token");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  const navigate = useNavigate();

  const handleAdvancedSearchClick = () => {
    navigate("/advanced-search");
  };

     //If a field on a record is empty, render
  // 'Not Provided' instead of empty string
  const renderText = (text, dataIndex) => {
    if (text === null || text === undefined || (typeof text === 'string' && text.trim() === '')) {
      if (dataIndex === 'service_referred') {
        return 'Not Referred';
      } else if (dataIndex === 'institution') {
        return 'Not Specified';
      } else {
        return 'Not Provided';
      }
    } else {
      return text;
    }
  };

  const columns = [
    {
      title: "Recieving Organization",
      dataIndex: "receiving_organization",
      key: "receiving_organization",
      render: (text) => renderText(text, 'receiving_organization'),
    },
    {
      title: "Referred Date",
      dataIndex: "referred_date",
      key: "referred_date",
      render: (text) => renderText(text, 'referred_date'),
    },
    {
      title: "Service Referred",
      dataIndex: "services_referred",
      key: "services_referred",
      render: (text) => renderText(text, 'services_referred'),    
    },
  ];

  const fetchVCAReferredServiceList = () => {
    const unique_id = child?.uid;
    setIsLoading(true);
    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_SERVER}/child/referredservices/${unique_id}`,
          options
        )
        .then((response) => {
          // Sort the case plans by case plan date in descending order
          const sortedServices = response.data.sort((a, b) => {
            // Convert dates to a format that Javascript can understand
            const dateA = new Date(a.service_date?.split('-').reverse().join('-'));
            const dateB = new Date(b.service_date?.split('-').reverse().join('-'));
            return dateB - dateA;
          });
          setfetchedServices(sortedServices);
          setIsDataReadyForExport(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("axios error: " + error);
          setIsLoading(false);
        });
    }, 3000);
  };
  
  useEffect(() => {
    fetchVCAReferredServiceList();
  }, [token, child.vcaid]);
  

  const displayFetchedReferredServices = Array.isArray(fetchedServices)
    ? fetchedServices
      .map((services) => {
        if (!services) {
          return null;
        }

        const {
          receiving_organization,
          referred_date,
          services_referred,
        } = services;

        return {
          receiving_organization,
          referred_date,
          services_referred,
          services, // Add the entire VCA services object
          // and pass its state to the Screening VCA Index page
        };
      })
      .filter((item) => item !== null)
    : [];

  // Compare the dates of the service forms
  //and sort them from the most recent to the oldest
  displayFetchedReferredServices.sort((a, b) => {
    const dateA = a?.date?.split("-").reverse().join("-");
    const dateB = b?.date?.split("-").reverse().join("-");
    return new Date(dateB) - new Date(dateA);
  });

  // Export to CSV
  const exportToCSV = () => {
    const headers = columns.map((column) => column.title);

    // Sort fetchedServices by service_date in descending order
    const sortedServices = [...fetchedServices].sort(
      (a, b) => new Date(b.service_date) - new Date(a.service_date)
    );

    const csvData = [headers].concat(
      sortedServices.map((child) => {
        const row = Object.values(child);
        row.unshift(child.uid); // Add childhold ID at the beginning
        return row;
      })
    );

    const csvContent = Papa.unparse(csvData);

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    // Create the filename including child ID and caregiver name
    const fileName = `child_referred_services_data_${child.uid}_${child.firstname} ${child.lastname}.csv`;
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // export to PDF
  const exportToPDF = () => {
    const headers = columns.map((column) => column.title);

    // Sort fetchedServices by service_date in descending order
    const sortedServices = [...fetchedServices].sort(
      (a, b) => new Date(b.service_date) - new Date(a.service_date)
    );

    const body = sortedServices.map((row) =>
      columns.map((column) =>
        row[column.dataIndex] === null ? "" : row[column.dataIndex]
      )
    );

    const doc = new jsPDF("l", "mm", "a4");
    doc.autoTable({
      head: [headers],
      body: body,
      theme: "striped",
      styles: { fontSize: 8 },
    });

    // Create the filename including child ID and caregiver name
    const fileName = `child_referred_services_data_${child.uid}_${child.firstname} ${child.lastname}.pdf`;
    doc.save(fileName);
  };

  const handleExportToCSV = async () => {
    if (fetchedServices?.length === 0) {
      await fetchVCAReferredServiceList(currentPage);
    }
    exportToCSV();
  };

  const handleExportToPDF = async () => {
    if (fetchedServices?.length === 0) {
      await fetchVCAReferredServiceList(currentPage);
    }
    exportToPDF();
  };

  return (
    <>
      <VersionBanner />
    <div className="tab-pane" id="referred_services">
      <div className="card-body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: windowWidth <= 991 ? "column" : "row",
                alignItems: "center",
              }}
            >
              {/*breadcrumb navigation*/}
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Chip
                    label="VCA Referred Services"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      color: "white",
                      fontWeight: "500",
                      marginLeft: "1%",
                    }}
                  ></Chip>
                  &nbsp;&nbsp;&nbsp;
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <h1
                    style={{
                      fontSize: "17px",
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Navigate to:
                  </h1>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/households">
                    <Chip
                      label="Households"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/children">
                    <Chip
                      label="VCA`s"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/mothers">
                    <Chip
                      label="Mothers"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <Tooltip title="Export to CSV">
                    <Button
                      icon={<FileExcelFilled twoToneColor="#28A745" />}
                      disabled={!isDataReadyForExport}
                      size="large"
                      style={{
                        marginRight: 8,

                        background:
                          "linear-gradient(45deg, #28A745 30%, #FF8E53 90%)",
                        color: "white",
                        boxShadow: "0 3px 5px 2px rgba(40, 167, 69, 0.3)",
                      }}
                      onClick={handleExportToCSV}
                    />
                  </Tooltip>
                </Breadcrumb.Item>
                <br />
                <br />
                {/* <Breadcrumb.Item>
                  <Tooltip title="Export to PDF">
                    <Button
                      size="large"
                      icon={<FilePdfFilled />}
                      disabled={!isDataReadyForExport}
                      style={{
                        background: "#DC3545",
                        color: "white",
                        boxShadow: "0 3px 5px 2px rgba(220, 53, 69, 0.3)",
                      }}
                      onClick={handleExportToPDF}
                    />
                  </Tooltip>
                </Breadcrumb.Item> */}
                <Breadcrumb.Item>
                  <Button
                    onClick={handleAdvancedSearchClick}
                    size="large"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      display: "block",
                      margin: "0 auto",
                      fontWeight: "500",
                    }}
                  >
                    Advanced Search
                  </Button>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div
                className="toolbar"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: windowWidth <= 991 ? "column" : "row",
                }}
              >
              </div>
            </div>
          </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "10vh",
            }}
          >
            <Spin size={35} color={"#808080"} loading={true} />
            <p>
              <b style={{ fontWeight: "500", textAlign: "center" }}>
                Please wait while we process your request...
              </b>
            </p>
          </div>
        ) : (
          <Table
            dataSource={displayFetchedReferredServices}
            columns={columns}
            scroll={{ y: 500 }}
            pagination={false}
            className="ant-table"
            locale={{
              emptyText: (
                <b style={{ color: "black" }}>
                  There are currently no VCA Referred Services saved for this VCA. Kindly
                  revist in future...
                </b>
              ),
            }}
          />
        )}
    </div>
    </>
  );
};

export default AllVReferredServices;