import Screening from "../components/householdForms/screening";
import { useParams } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import AppContext from "../context/appContext";
import Members from "../components/householdForms/members";
import axios from "axios";
import HouseholdVisits from "../components/householdForms/householdVisits";
import HouseholdCasePlans from "../components/householdForms/householdCasePlans";
import Caregiverassessment from "../components/householdForms/caregiverAssessment";
import VersionBanner from "./versionBanner";

const Mother = () => {
  const { hhid } = useParams();

  const clients = useContext(AppContext);
  const households = clients?.data;

  const house = households.filter(
    (h) => h?.json?.attributes?.household_id === hhid
  );
  const [forms, setForms] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/household/hhForms/${hhid}`)
      .then((response) => {
        setForms(response?.data[0]);
      })
      .catch((error) => console.error("axios error: " + error));
  }, [hhid]);

  const hhVisits = forms?.filter(
    (f) => f?.form === "ec_household_visitation_for_caregiver"
  );
  const hhCasePlans = forms?.filter((f) => f?.form === "ec_caregiver_case_plan");
  const cAssessments = forms?.filter(
    (f) => f?.form === "ec_caregiver_hiv_assessment"
  );

  return (
    <>
      <VersionBanner />
      <div className="col-lg col-md">
        <div className="card">
          <div className="card-header card-header-tabs card-header-info">
            <div className="nav-tabs-navigation">
              <div className="nav-tabs-wrapper">
                <span className="nav-tabs-title">Forms:</span>
                <ul className="nav nav-tabs" data-tabs="tabs">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      href="#screening"
                      data-toggle="tab"
                    >
                      <i className="material-icons">houses</i> Household Screening
                      <div className="ripple-container" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#members" data-toggle="tab">
                      <i className="material-icons">people</i> Family Members
                      <div className="ripple-container" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#visits" data-toggle="tab">
                      <i className="material-icons">arrow_right</i> Visits
                      <div className="ripple-container" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#caseplans" data-toggle="tab">
                      <i className="material-icons">article</i> Case Plans
                      <div className="ripple-container" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#hivassessment"
                      data-toggle="tab"
                    >
                      <i className="material-icons">healing</i> HIV Risk
                      Assessment
                      <div className="ripple-container" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#settings" data-toggle="tab">
                      <i className="material-icons">school</i> Graduation
                      <div className="ripple-container" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="tab-content">
              <div className="tab-pane active" id="screening">
                <Screening house={house} />
              </div>
              <Members household_id={house[0]?.json?.attributes?.household_id} />
              <HouseholdVisits hh_visits={hhVisits} />
              <HouseholdCasePlans hhCasePlans={hhCasePlans} />
              <Caregiverassessment cAssessments={cAssessments} />
              <Caregiverassessment cAssessments={cAssessments} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mother;
