import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Chip, debounce } from "@mui/material";
import "jspdf-autotable";
import {
  Table,
  Spin,
  Button,
  Tooltip,
  Breadcrumb,
} from "antd";
import {
  FileExcelFilled,
} from "@ant-design/icons";
import VersionBanner from "../../pages/versionBanner";

const GraduationInfo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedHouseholdGraduation, setfetchedHouseholdGraduation] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const location = useLocation();
  const { house } = location?.state;

  const token = localStorage.getItem("token");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  //If a field on a record is empty, render
  // 'Not Provided' instead of empty string
  const renderText = (text, dataIndex) => {
    if (text === null || text === undefined || (typeof text === 'string' && text.trim() === '')) {
      if (dataIndex === 'service_referred') {
        return 'Not Referred';
      } else if (dataIndex === 'institution') {
        return 'Not Specified';
      } else {
        return 'Not Provided';
      }
    } else {
      return text;
    }
  };

  const columns = [
    {
      title: "Date Assessment",
      dataIndex: "date_assessment",
      key: "date_assessment",
      width: 300,
      render: (text) => renderText(text, 'date_assessment'),
    },
    {
      title: "Previous Assessment Date",
      dataIndex: "previous_asmt_date",
      key: "previous_asmt_date",
      width: 300,
      render: (text) => renderText(text, 'previous_asmt_date'),
    },
    {
      title: "HIV Status Enrolled",
      dataIndex: "hiv_status_enrolled",
      key: "hiv_status_enrolled",
      width: 300,
      render: (text) => renderText(text, 'hiv_status_enrolled'),
    },
    {
      title: "Caregiver HIV Status Enrolled",
      dataIndex: "caregiver_hiv_status_enrolled",
      key: "caregiver_hiv_status_enrolled",
      width: 300,
      render: (text) => renderText(text, 'caregiver_hiv_status_enrolled'),
    },
    {
      title: "Virally Suppressed",
      dataIndex: "virally_suppressed",
      key: "virally_suppressed",
      width: 300,
      render: (text) => renderText(text, 'virally_suppressed'),
    },
    {
      title: "Suppressed Caregiver",
      dataIndex: "suppressed_caregiver",
      key: "suppressed_caregiver",
      width: 300,
      render: (text) => renderText(text, 'suppressed_caregiver'),
    },
    {
      title: "Prevention",
      dataIndex: "prevention",
      key: "prevention",
      width: 300,
      render: (text) => renderText(text, 'prevention'),
    },
    {
      title: "Undernourished",
      dataIndex: "undernourished",
      key: "undernourished",
      width: 300,
      render: (text) => renderText(text, 'undernourished'),
    },
    {
      title: "School Fees",
      dataIndex: "school_fees",
      key: "school_fees",
      width: 300,
      render: (text) => renderText(text, 'school_fees'),
    },
    {
      title: "Medical Costs",
      dataIndex: "medical_costs",
      key: "medical_costs",
      width: 300,
      render: (text) => renderText(text, 'medical_costs'),
    },
    {
      title: "Record Abuse",
      dataIndex: "record_abuse",
      key: "record_abuse",
      width: 300,
      render: (text) => renderText(text, 'record_abuse'),
    },
    {
      title: "Caregiver Beaten",
      dataIndex: "caregiver_beaten",
      key: "caregiver_beaten",
      width: 300,
      render: (text) => renderText(text, 'caregiver_beaten'),
    },
    {
      title: "Child Beaten",
      dataIndex: "child_beaten",
      key: "child_beaten",
      width: 300,
      render: (text) => renderText(text, 'child_beaten'),
    },
    {
      title: "Aware of Sexual",
      dataIndex: "aware_sexual",
      key: "aware_sexual",
      width: 300,
      render: (text) => renderText(text, 'aware_sexual'),
    },
    {
      title: "Against Will",
      dataIndex: "against_will",
      key: "against_will",
      width: 300,
      render: (text) => renderText(text, 'against_will'),
    },
    {
      title: "Stable Guardian",
      dataIndex: "stable_guardian",
      key: "stable_guardian",
      width: 300,
      render: (text) => renderText(text, 'stable_guardian'),
    },
    {
      title: "Children in School",
      dataIndex: "children_in_school",
      key: "children_in_school",
      width: 300,
      render: (text) => renderText(text, 'children_in_school'),
    },
    {
      title: "In School",
      dataIndex: "in_school",
      key: "in_school",
      width: 300,
      render: (text) => renderText(text, 'in_school'),
    },
    {
      title: "Year School",
      dataIndex: "year_school",
      key: "year_school",
      width: 300,
      render: (text) => renderText(text, 'year_school'),
    },
    {
      title: "Repeat School",
      dataIndex: "repeat_school",
      key: "repeat_school",
      width: 300,
      render: (text) => renderText(text, 'repeat_school'),
    },
    {
      title: "Additional Information",
      dataIndex: "additional_information",
      key: "additional_information",
      width: 300,
      render: (text) => renderText(text, 'additional_information'),
    },
    {
      title: "Graduation Status",
      dataIndex: "graduation_status",
      key: "graduation_status",
      width: 300,
      render: (text) => renderText(text, 'graduation_status'),
    },
  ];

  // Fetch the list of household services by householdId
  const fetchAllHouseholdGraduation = () => {
    const household_id = house.household_id;
    setIsLoading(true);
    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_SERVER}/household/hhgraduation/${household_id}`,
          options
        )
        .then((response) => {
          // Sort the graduations by service date in descending order
          const sortedGraduation = response.data.sort((a, b) => {
            // Convert dates to a format that Javascript can understand
            const dateA = new Date(a.date_assessment?.split('-').reverse().join('-'));
            const dateB = new Date(b.date_assessment?.split('-').reverse().join('-'));
            return dateB - dateA;
          });
          setfetchedHouseholdGraduation(sortedGraduation);
          setIsDataReadyForExport(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("axios error: " + error);
          setIsLoading(false);
        });
    }, 3000);
  };


  useEffect(() => {
    fetchAllHouseholdGraduation();
  }, [token, house.household_id]);

  const displayFetchedHouseholdGraduation = Array.isArray(fetchedHouseholdGraduation)
    ? fetchedHouseholdGraduation
      .map((graduation) => {
        if (!graduation) {
          return null;
        }
        const {
          household_id,
          date_assessment,
          previous_asmt_date,
          hiv_status_enrolled,
          caregiver_hiv_status_enrolled,
          virally_suppressed,
          suppressed_caregiver,
          prevention,
          undernourished,
          school_fees,
          medical_costs,
          record_abuse,
          caregiver_beaten,
          child_beaten,
          aware_sexual,
          against_will,
          stable_guardian,
          children_in_school,
          in_school,
          year_school,
          repeat_school,
          additional_information,
          graduation_status
        } = graduation;

        // const health_services_str =
        //   typeof health_services === "string"
        //     ? health_services.replace(/[\[\]"]+/g, "")
        //     : "";

        return {
          // health_services: health_services_str, // updated
          household_id,
          date_assessment,
          previous_asmt_date,
          hiv_status_enrolled,
          caregiver_hiv_status_enrolled,
          virally_suppressed,
          suppressed_caregiver,
          prevention,
          undernourished,
          school_fees,
          medical_costs,
          record_abuse,
          caregiver_beaten,
          child_beaten,
          aware_sexual,
          against_will,
          stable_guardian,
          children_in_school,
          in_school,
          year_school,
          repeat_school,
          additional_information,
          graduation_status,
          graduation, // Add the entire graduation object
          // and pass its state to the Screening Household Index page
        };
      })
      .filter((item) => item !== null)
    : [];


  // Sort the services based on the "Service Date" in descending order
  fetchedHouseholdGraduation.sort((a, b) => {
    const dateA = a?.service_date?.split("-").reverse().join("-");
    const dateB = b?.service_date?.split("-").reverse().join("-");
    return new Date(dateB) - new Date(dateA);
  });


  const navigate = useNavigate();

  const handleAdvancedSearchClick = () => {
    navigate("/advanced-search");
  };

  // Export to CSV
  const exportToCSV = () => {
    const headers = columns.map((column) => column.title);
    const csvData = [headers].concat(
      fetchedHouseholdGraduation.map((house) => Object.values(house))
    );

    const csvContent = Papa.unparse(csvData);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    // Create the filename including household ID and caregiver name
    const fileName = `household_graduation_data_${house.household_id}_${house.caregiver_name}.csv`;
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // export to PDF
  const exportToPDF = () => {
    const headers = columns.map((column) => column.title);
    const body = fetchedHouseholdGraduation.map((row) =>
      columns.map((column) =>
        row[column.dataIndex] === null ? "" : row[column.dataIndex]
      )
    );

    const doc = new jsPDF("l", "mm", "a4");
    doc.autoTable({
      head: [headers],
      body: body,
      theme: "striped",
      styles: { fontSize: 8 },
    });
    // Create the filename including household ID and caregiver name
    const fileName = `household_graduation_data_${house.household_id}_${house.caregiver_name}.pdf`;
    doc.save(fileName);
  };

  const handleExportToCSV = async () => {
    if (fetchedHouseholdGraduation?.length === 0) {
      await fetchAllHouseholdGraduation(currentPage);
    }
    exportToCSV();
  };

  // TO - DO, figure out how to export to
  // PDF for an entire household record
  const handleExportToPDF = async () => {
    if (fetchedHouseholdGraduation?.length === 0) {
      await fetchAllHouseholdGraduation(currentPage);
    }
    exportToPDF();
  };

  return (
    <>
      <VersionBanner />
      <div className="tab-pane" id="graduation">
        <React.Fragment>
          <div className="card-body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: windowWidth <= 991 ? "column" : "row",
                alignItems: "center",
              }}
            >
              {/*breadcrumb navigation*/}
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Chip
                    label="Household Graduation Information"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      color: "white",
                      fontWeight: "500",
                      marginLeft: "1%",
                    }}
                  ></Chip>
                  &nbsp;&nbsp;&nbsp;
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <h1
                    style={{
                      fontSize: "17px",
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Navigate to:
                  </h1>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/households">
                    <Chip
                      label="Households"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/children">
                    <Chip
                      label="VCA`s"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/mothers">
                    <Chip
                      label="Mothers"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <Tooltip title="Export to CSV">
                    <Button
                      icon={<FileExcelFilled twoToneColor="#28A745" />}
                      disabled={!isDataReadyForExport}
                      size="large"
                      style={{
                        marginRight: 8,
                        background:
                          "linear-gradient(45deg, #28A745 30%, #FF8E53 90%)",
                        color: "white",
                        boxShadow: "0 3px 5px 2px rgba(40, 167, 69, 0.3)",
                      }}
                      onClick={handleExportToCSV}
                    />
                  </Tooltip>
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <Button
                    onClick={handleAdvancedSearchClick}
                    size="large"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      display: "block",
                      margin: "0 auto",
                      fontWeight: "500",
                    }}
                  >
                    Advanced Search
                  </Button>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div
                className="toolbar"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: windowWidth <= 991 ? "column" : "row",
                }}
              >
              </div>
            </div>
          </div>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "10vh",
              }}
            >
              <Spin size={35} color={"#808080"} loading={true} />
              <p>
                <b style={{ fontWeight: "500", textAlign: "center" }}>
                  Please wait while we process your request...
                </b>
              </p>
            </div>
          ) : (
            <Table
              dataSource={displayFetchedHouseholdGraduation}
              columns={columns}
              scroll={{ y: 500 }}
              pagination={false}
              className="ant-table"
              locale={{
                emptyText: (
                  <b style={{ color: "black" }}>
                    There are currently no Graduation details saved for this Household. Kindly revisit in future...
                  </b>
                ),
              }}
            />
          )}
        </React.Fragment>
      </div>
    </>
  );
};

export default GraduationInfo;
