import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Chip } from "@mui/material";
import "jspdf-autotable";
import {
  Table,
  Spin,
  Button,
  Tooltip,
  Breadcrumb
} from "antd";
import {
  FileExcelFilled
} from "@ant-design/icons";
import VersionBanner from "../../pages/versionBanner";

const AllHServiceReports = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedServices, setfetchedServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const location = useLocation();
  const { house } = location?.state;

  const token = localStorage.getItem("token");

  const options = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [token]
  );

  const previousPage = () => {
    navigate(-1);
  };

    //If a field on a record is empty, render
  // 'Not Provided' instead of empty string
 const renderText = (text, dataIndex) => {
  if (text === null || text === undefined || (typeof text === 'string' && text.trim() === '')) {
    if (dataIndex === 'service_referred') {
      return 'Not Referred';
    } else if (dataIndex === 'institution') {
      return 'Not Specified';
    } else {
      return 'Not Provided';
    }
  } else {
    return text;
  }
};

  const columns = [
    {
      title: "Service Date",
      dataIndex: "service_date",
      key: "service_date",
      width: 300,
      render: (text) => renderText(text, 'service_date'),
    },
    {
      title: "Health Services",
      dataIndex: "health_services",
      key: "health_services",
      width: 300,
      render: (text) => renderText(text, 'health_services'),
    },
    {
      title: "Other Health Services",
      dataIndex: "other_health_services",
      key: "other_health_services",
      width: 300,
      render: (text) => renderText(text, 'other_health_services'),
    },
    {
      title: "HH Level Services",
      dataIndex: "hh_level_services",
      key: "hh_level_services",
      width: 300,
      render: (text) => renderText(text, 'hh_level_services'),
    },
    {
      title: "Other HH Level Services",
      dataIndex: "other_hh_level_services",
      key: "other_hh_level_services",
      width: 300,
      render: (text) => renderText(text, 'other_hh_level_services'),
    },
    {
      title: "Schooled Services",
      dataIndex: "schooled_services",
      key: "schooled_services",
      width: 300,
      render: (text) => renderText(text, 'schooled_services'),
    },
    {
      title: "Other Schooled Services",
      dataIndex: "other_schooled_services",
      key: "other_schooled_services",
      width: 300,
      render: (text) => renderText(text, 'other_schooled_services'),
    },
    {
      title: "Safe Services",
      dataIndex: "safe_services",
      key: "safe_services",
      width: 300,
      render: (text) => renderText(text, 'safe_services'),
    },
    {
      title: "Other Safe Services",
      dataIndex: "other_safe_services",
      key: "other_safe_services",
      width: 300,
      render: (text) => renderText(text, 'other_safe_services'),
    },
    {
      title: "Stable Services",
      dataIndex: "stable_services",
      key: "stable_services",
      width: 300,
      render: (text) => renderText(text, 'stable_services'),
    },
    {
      title: "Other Stable Services",
      dataIndex: "other_stable_services",
      key: "other_stable_services",
      width: 300,
      render: (text) => renderText(text, 'other_stable_services'),
    },
  ];

  const fetchAllHouseholdServices = () => {
    const household_id = house.household_id;
    setIsLoading(true);
    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_SERVER}/household/hservices/${household_id}`,
          options
        )
        .then((response) => {
          // Sort the services by service date in descending order
          const sortedServices = response.data.sort((a, b) => {
            // Convert dates to a format that Javascript can understand
            const dateA = new Date(a.service_date.split('-').reverse().join('-'));
            const dateB = new Date(b.service_date.split('-').reverse().join('-'));
            return dateB - dateA;
          });
          setfetchedServices(sortedServices);
          setIsDataReadyForExport(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("axios error: " + error);
          setIsLoading(false);
        });
    }, 3000);
  };

  useEffect(() => {
    fetchAllHouseholdServices();
  }, [token, house.household_id]);

  const displayFetchedServices = Array.isArray(fetchedServices)
    ? fetchedServices
      .map((services) => {
        if (!services) {
          return null;
        }
        const {
          household_id,
          service_date,
          health_services,
          other_health_services,
          hh_level_services,
          other_hh_level_services,
          schooled_services,
          other_schooled_services,
          safe_services,
          other_safe_services,
          stable_services,
          other_stable_services,
        } = services;

        //remove the square brackets from the health services string
        const health_services_str =
          typeof health_services === "string"
            ? health_services.replace(/[\[\]"]+/g, "")
            : "";

        return {
          household_id,
          health_services: health_services_str, // updated
          other_health_services,
          hh_level_services,
          service_date,
          other_hh_level_services,
          schooled_services,
          other_schooled_services,
          safe_services,
          other_safe_services,
          stable_services,
          other_stable_services,
          services, // Add the entire services object
          // and pass its state to the Screening VCA Household Index page
        };
      })
      .filter((item) => item !== null)
    : []; 
    
    // Sort fetchedServices by service_date in descending order
    const sortedServices = [...fetchedServices].sort(
      (a, b) => new Date(b.service_date) - new Date(a.service_date)
    );


  const navigate = useNavigate();

  const handleAdvancedSearchClick = () => {
    navigate("/advanced-search");
  };

  // Export to CSV
  const exportToCSV = () => {
    const headers = columns.map((column) => column.title);

   
    const csvData = [headers].concat(
      sortedServices.map((house) => {
        const row = Object.values(house);
        row.unshift(house.household_id); // Add household ID at the beginning
        return row;
      })
    );

    const csvContent = Papa.unparse(csvData);

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    // Create the filename including household ID and caregiver name
    const fileName = `household_services_data_${house.household_id}_${house.caregiver_name}.csv`;
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // export to PDF
  const exportToPDF = () => {
    const headers = columns.map((column) => column.title);

    // Sort fetchedServices by service_date in descending order
    const sortedServices = [...fetchedServices].sort(
      (a, b) => new Date(b.service_date) - new Date(a.service_date)
    );

    const body = sortedServices.map((row) =>
      columns.map((column) =>
        row[column.dataIndex] === null ? "" : row[column.dataIndex]
      )
    );

    const doc = new jsPDF("l", "mm", "a4");
    doc.autoTable({
      head: [headers],
      body: body,
      theme: "striped",
      styles: { fontSize: 8 },
    });

    // Create the filename including household ID and caregiver name
    const fileName = `household_services_data_${house.household_id}_${house.caregiver_name}.pdf`;
    doc.save(fileName);
  };

  const handleExportToCSV = async () => {
    if (fetchedServices?.length === 0) {
      await fetchAllHouseholdServices(currentPage);
    }
    exportToCSV();
  };

  const handleExportToPDF = async () => {
    if (fetchedServices?.length === 0) {
      await fetchAllHouseholdServices(currentPage);
    }
    exportToPDF();
  };


  return (
    <>
      <VersionBanner />
      <div className="tab-pane" id="services">
        <React.Fragment>
          <div className="card-body">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: windowWidth <= 991 ? "column" : "row",
                alignItems: "center",
              }}
            >
              {/*breadcrumb navigation*/}
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Chip
                    label="Household Services"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      color: "white",
                      fontWeight: "500",
                      marginLeft: "1%",
                    }}
                  ></Chip>
                  &nbsp;&nbsp;&nbsp;
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <h1
                    style={{
                      fontSize: "17px",
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Navigate to:
                  </h1>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/households">
                    <Chip
                      label="Households"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/children">
                    <Chip
                      label="VCA`s"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/mothers">
                    <Chip
                      label="Mothers"
                      variant="outlined"
                      color="primary"
                      clickable
                      style={{
                        backgroundColor: "#e0e0e0",
                        color: "black",
                        fontWeight: "500",
                      }}
                    ></Chip>
                  </Link>
                </Breadcrumb.Item>
                <br />
                <br />
                <Breadcrumb.Item>
                  <Tooltip title="Export to CSV">
                    <Button
                      icon={<FileExcelFilled twoToneColor="#28A745" />}
                      disabled={!isDataReadyForExport}
                      size="large"
                      style={{
                        marginRight: 8,

                        background:
                          "linear-gradient(45deg, #28A745 30%, #FF8E53 90%)",
                        color: "white",
                        boxShadow: "0 3px 5px 2px rgba(40, 167, 69, 0.3)",
                      }}
                      onClick={handleExportToCSV}
                    />
                  </Tooltip>
                </Breadcrumb.Item>
                <br />
                <br />
                {/* <Breadcrumb.Item>
                  <Tooltip title="Export to PDF">
                    <Button
                      size="large"
                      icon={<FilePdfFilled />}
                      disabled={!isDataReadyForExport}
                      style={{
                        background: "#DC3545",
                        color: "white",
                        boxShadow: "0 3px 5px 2px rgba(220, 53, 69, 0.3)",
                      }}
                      onClick={handleExportToPDF}
                    />
                  </Tooltip>
                </Breadcrumb.Item> */}
                <Breadcrumb.Item>
                  <Button
                    onClick={handleAdvancedSearchClick}
                    size="large"
                    style={{
                      background:
                        "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                      color: "white",
                      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, 0.3)",
                      display: "block",
                      margin: "0 auto",
                      fontWeight: "500",
                    }}
                  >
                    Advanced Search
                  </Button>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div
                className="toolbar"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: windowWidth <= 991 ? "column" : "row",
                }}
              >
              </div>
            </div>
          </div>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "10vh",
              }}
            >
              <Spin size={35} color={"#808080"} loading={true} />
              <p>
                <b style={{ fontWeight: "500", textAlign: "center" }}>
                  Please wait while we process your request...
                </b>
              </p>
            </div>
          ) : (
            <Table
              dataSource={displayFetchedServices}
              columns={columns}
              scroll={{ y: 500 }}
              pagination={false}
              className="ant-table"
              locale={{
                emptyText: (
                  <b style={{ color: "black" }}>
                    There are currently no Household Services saved for this
                    household. Kindly revisit in the future...
                  </b>
                ),
              }}
            />
          )}
        </React.Fragment>
        <button onClick={previousPage} className="btn btn-info pull-right">
          Back
        </button>
      </div>
    </>
  );
};

export default AllHServiceReports;
